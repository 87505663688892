import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const themeCircularProgress = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

export default function ConfirmDialog(props) {
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div>
      <ThemeProvider theme={themeCircularProgress}>
        <Dialog
          open={open}
          onClose={props.handleCancel}
          aria-labelledby="confirmation-dialog-title"
        >
          {Boolean(props.title) && <DialogTitle style={{ cursor: 'move' }} id="confirmation-dialog-title">{props.title}</DialogTitle>}
          {Boolean(props.contentText) && <DialogContent><DialogContentText>{props.contentText}</DialogContentText></DialogContent>}
          <DialogActions>
            <Button style={{ marginRight: 10 }} onClick={props.handleCancel} color="secondary">
              {Boolean(props.cancelTextButton) || 'Cancelar'}
            </Button>
            <Button onClick={() => props.handleConfirm(props.item || null)} color="primary">
              {Boolean(props.confirmTextButton) || 'Confirmar'}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
