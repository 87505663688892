import React from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'

import { ProcedureRequestStatusEnum } from '../../../common/enums/'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 30,
  }
}))

export const Summary = ({budget}) => {
  const classes = useStyles()

  const stage = () => {
    switch(budget?.procedureRequest.status) {
      case ProcedureRequestStatusEnum.AWAITING_BUDGET:
        return 'Aguardando o orçamento'
      
      case ProcedureRequestStatusEnum.PENDING_PAYMENT:
        return 'Aguardando Pagamento'
      
      case ProcedureRequestStatusEnum.AWAITING_DATE:
        return 'Agendamento do Procedimento'
      
        default:
        return 'Desconhecido'
    }
  }
  
  return (
    <>
      <div className={classes.title}>Etapa</div>
      <div>{stage()}</div>

      <div className={classes.title}>Solicitado Por</div>
      <div>{budget?.provider.user.name}</div>
      { budget?.provider.doctor &&
        <div>CRM: {budget?.provider.doctor?.crm }</div>
      }
      { budget?.provider.professionalHasProvider &&
        <div> 
          {budget.provider.professionalHasProvider.professionalCouncil.name}: {budget.provider.professionalHasProvider.professionalDocument}
        </div>
      }
      
      <div>Telefone: { budget?.provider.user.phone }</div>

      {budget?.procedureRequest?.dateTime && (
        <>
          <div className={classes.title}>Data do procedimento</div>
          <div>{format(new Date(budget?.procedureRequest?.dateTime), 'dd/MM/yyyy HH:mm')}</div>
        </>
      )}

      <div className={classes.title}>Procedimento</div>
      <div>{budget?.procedureRequest.serviceProvided.description}</div>

      {
        budget?.hospital_has_opme_or_aditional_materials && budget?.hospital_opme &&
        <>
          <div className={classes.title}>OPME:</div>
          <div>{ budget?.hospital_opme }</div>
        </>
      }
      
      {
        budget?.hospital_has_opme_or_aditional_materials && budget?.hospital_aditional_materials &&
        <>
          <div className={classes.title}>Materiais Adicionais:</div>
          <div>{ budget?.hospital_aditional_materials }</div>
        </>
      }

      {
        budget?.hospital_special_equipment &&
        <>
          <div className={classes.title}>Equipamentos Especiais:</div>
          <div>{ budget?.hospital_special_equipment }</div>
        </>
      }

      {
        budget?.hospital_daily_quantities > 0 &&
        <>
          <div className={classes.title}>Diárias de Internação:</div>
          <div>{ budget?.hospital_daily_quantities } Diária(s)</div>
        </>
      }

      {
        budget?.hospital_has_hospitalization &&
        <>
          <div className={classes.title}>Internação em UTI:</div>
          <div>{ budget?.hospital_hospitalization_quantities } Diárias(s)</div>
        </>
      }

      {
        budget?.hospital_has_special_medications && 
        <>
          <div className={classes.title}>Medicamentos Especiais:</div>
          <div>{ budget?.hospital_special_medications }</div>
        </>
      }
      
      {
        budget?.hospital_has_blood_products &&
        <>
          <div className={classes.title}>Hemoderivados:</div>
          <div>{ budget?.hospital_blood_products }</div>
        </>
      }
      
      <div className={classes.title}>Será utilizada a equipe de anestesiologia do hospital?</div>
      { budget?.anesthetist ? 'Sim' : 'Não'}
      
      
    </>
  )
}
