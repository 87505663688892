import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import SnackBar from "../../../common/snackbar";

import StepByStep from "../../../common/stepByStep/stepByStep";

import * as personalDatasActions from "../../../common/store/actions/personalDatas";

import { title } from "./style";
import { checkbox } from "../../../common/styles/style";

export default (props) => {
  const classesTitle = title();
  const classesCheckbox = checkbox();
  const dispatch = useDispatch();

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const typeStablishment = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj
  );
  
  const [stablishment, setStablishment] = useState(
    typeStablishment.typeStablishment
  );

  function handleAdvance() {
    if (stablishment.trim().length) {
      dispatch(personalDatasActions.stablishmentTypePj(stablishment));
      props.nextStep();
    } else {
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Por favor, escolha o tipo da sua empresa.",
      });
    }
  }

  return (
    <div>
      <StepByStep activeStep={props.activeStep} handleAdvance={handleAdvance} />

      <form className="form-layout">
        <Box className={classesTitle.root}>
          <h1>Qual o tipo da sua empresa/estabelecimento?</h1>
        </Box>

        <FormControl className={classesCheckbox.root}>
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            aria-label="type-stablishment"
            value={stablishment}
            onChange={(e) => setStablishment(e.target.value)}
            name="type-account"
            data-test="type-account"
          >
            <FormControlLabel
              value="hospital"
              control={<Radio />}
              label="Hospital"
              name="hospital"
              data-test="hospital"
            />
            <FormControlLabel
              value="clinic"
              control={<Radio />}
              label="Cliníca Médica ou Diagnóstica"
              name="clinic"
              data-test="clinic"
            />
             <FormControlLabel
              value="laboratory"
              control={<Radio />}
              label="Laboratório"
              name="laboratory"
              data-test="laboratory"
            />
            <FormControlLabel
              value="health-operator"
              control={<Radio />}
              label="Operadora de Saúde"
              name="health-operator"
              data-test="health-operator"
            />
          </RadioGroup>
        </FormControl>
      </form>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
