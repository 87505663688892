import React from "react";
import { useSelector } from "react-redux";

import WithoutAddress from "./withoutAddress";
import WithAddress from "./withAddress";
import { Services } from '../register-services/'

export default (props) => {
  const units = useSelector((state) => state.Address.units);
  
  const showRegisterServices = useSelector(
    (state) => state.Address.showRegisterServices
  );

  if (showRegisterServices) {
    return <Services />;
  } else if (units.length) {
    return (
      <WithAddress
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        activeStep={props.activeStep}
      />
    );
  } else {
    return (
      <WithoutAddress
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        activeStep={props.activeStep}
      />
    );
  }
};
