export function registerAddress(payload) {
  return {
    type: "REGISTER_ADDRESS",
    payload,
  };
}

export function alterActiveAddress(index) {
  return {
    type: "ALTER_ACTIVE",
    payload: index,
  };
}

export function toggleRegisterServices() {
  return {
    type: "TOGGLE_SHOW_REGISTER_SERVICES",
  };
}

export function insertServices(services) {
  return {
    type: "INSERT_SERVICES_ADDRESS",
    payload: services,
  };
}

export function updateAddress(payload, index) {
  return {
    type: "UPDATE_ADDRESS",
    payload,
    index,
  };
}

export function deleteAddress(index) {
  return {
    type: "DELETE_ADDRESS",
    payload: index,
  };
}
