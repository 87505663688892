import React from 'react'
import { makeStyles } from '@material-ui/core'

import InputFile from '../../../common/fileInput'
import InputCurrency from '../../../common/inputCurrency/InputCurrencyFormat'
import inputFileValidator, { validationFileType } from '../../../common/validators/fileInput'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 30,
  },
  input: {
    marginBottom: 30,
  },
}))

const typesAcceptedExtension = ['application/pdf']

export const Budget = (props) => {

  const {
    budgetFile, 
    setBudgetFile, 
    budgetError, 
    setBudgetError, 
    price, 
    setPrice, 
    success
  } = props

  const classes = useStyles()

  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      setBudgetError({ 
        error: true, 
        typeError: "O tipo do arquivo selecionado não é aceito para esse campo!" 
      })
      return
    } 
    return onChange(value)
  }

  return (
    <>
      <div className={classes.title}>Orçamento</div>
      <div>
      Defina o valor do orçamento e envie um aquivo detalhado sobre o valor. 
      O documento deve contemplar todas as taxas, materias, medicamentos, OPME's e anestesistas caso solicitado.
      Deve constar a informação de que o orçamento levou em consideração examente a solicitacão médica e não abrange 
      eventuais intercorrências, as quais deverão ser tratadas diretamente entre hospital, 
      médico e paciente sem a interferência do Vida Connect.
      </div>

      <div className={classes.input}>
        <InputFile
          id="budget-edetails"
          width="70%"
          label="Detalhamento do Orçamento"
          subtitle="Formato aceito: pdf. Tamanho máximo 15MB"
          accept=".pdf"
          onChange={(e) =>
            handleChangeInputFile(e.target.files[0], setBudgetFile, typesAcceptedExtension)  
          }
          value={budgetFile.name}
          disabled={success}
          onBlur={() =>
            setBudgetError(inputFileValidator(budgetFile, 15))
          }
          error={budgetError.typeError}
        />
      </div>
      <div className={classes.input}>
        <InputCurrency
          width="40%"
          className={classes.inputer}
          label="Valor total em reais (R$)"
          value={price}
          onChange={setPrice}
          //  error={priceError}
          styles={{fontSize: '12px',
          marginTop: '3px',
          color: "#DA1414"}}
          disabled={success}
          dataTest="modal-cadastrar-exame-input-preco"
        />
      </div>
    </>
  )
}