import React, { useState, useEffect } from "react";
import apiGraphql from "../../../../services/api";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Modal from "@material-ui/core/Modal";
import {Alert} from "@material-ui/lab";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Pagination from "../../../../common/pagination";

import Button from "../../../../common/button";
import RegisterConsultation from "../../../../common/modal/consultation/registerConsultation";
import DeleteConsultation from "../../../../common/modal/consultation/deleteConsultation";
import SnackBar from "../../../../common/snackbar";

import Loading from "../../../../common/circular-progress";

export default (props) => {
  const { unitInformations } = props;
  const [listConsultations, setListConsultations] = useState(
    unitInformations.unitHasSpecialities
  );
  const [listSpecialities, setListSpecialities] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [qtyPages, setQtyPages] = useState(1);
  const [showMinimumNumber, setShowMinimunNumber] = useState(0);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [
    listConsultationsPagination,
    setListConsultationsPagination,
  ] = useState([]);

  const [alertState, setAlertState] = useState(false);

  const [
    openModalRegisterConsultation,
    setOpenModalRegisterConsultation,
  ] = useState(false);

  const [
    OpenModalDeleteConsultation,
    setOpenModalDeleteConsultation,
  ] = useState(false);

  const [unitHasSpecialityId, setUnitHasSpecialityId] = useState("");
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const handleOpenModalRegisterConsultation = () => {
    setOpenModalRegisterConsultation(true);
  };

  const handleCloseModalRegisterConsultation = () => {
    setOpenModalRegisterConsultation(false);
  };

  const handleOpenModalDeleteConsultation = (unitHasSpecialityId) => {
    setUnitHasSpecialityId(unitHasSpecialityId)
    setOpenModalDeleteConsultation(true);
  };

  const handleCloseModalDeleteConsultation = () => {
    setOpenModalDeleteConsultation(false);
  };

  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
          query listSpecialities($input:GetSpecialitiesInput){
            listSpecialities(input:$input){
              rows{
                id,
                name,
                group
              }
            }
          }
        `,
        variables: {
          input: { limit: 100, page: 1 },
        },
      }
    })
      .then((res) => {
        setLoading(false);
        setListSpecialities(res.data.data.listSpecialities.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    
    const listActiveConsultations = listConsultations.filter(x => x.active === true)
    setListConsultationsPagination(
      listActiveConsultations.slice(limit * (page - 1), limit * page)
    );
    setShowMinimunNumber(limit * (page - 1) + 1);
    setShowMaxNumber(limit * page);
    setCount(listActiveConsultations.length);
    setQtyPages(
      listActiveConsultations.length % limit === 0
        ? Math.floor(listActiveConsultations.length / limit)
        : Math.floor(listActiveConsultations.length / limit) + 1
    );
  }, [limit, page, listConsultations])
  
  return (
    <React.Fragment>
      {loading && <Loading size={80} />}
      {!loading && (
        <>
          <div className="header-consultation">
            <div>
              <Button
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                fontSize="1 rem"
                width="100vw"
                maxWidth="91px"
                onClick={() => props.setAlreadyChooseUnit(false)}
              >
                <NavigateBeforeIcon />
                Voltar
              </Button>
              <h1>
                {unitInformations.address}
                {unitInformations.number
                  ? ",  nº" + unitInformations.number
                  : ""}
              </h1>
            </div>
            <Button
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              fontFamily="Montserrat"
              fontSize="0.8 rem"
              width="100vw"
              maxWidth="190px"
              whiteSpace="nowrap"
              onClick={() => handleOpenModalRegisterConsultation()}
            >
              Adicionar Especialidade
            </Button>
          </div>

          <div className="list-consultations">
            {alertState && (<Alert severity="success">Especialidade cadastrada com sucesso!</Alert>)}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Especialidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {listConsultationsPagination.map((consultation, index) => (
                    <TableRow key={index}>
                      <TableCell>{consultation.specialities.name}</TableCell>
                      <TableCell style={{padding: '5px'}}>    
                      <IconButton
                    disableRipple
                    edge="end" aria-label="delete" 
                    onClick={() => handleOpenModalDeleteConsultation(consultation.id)}
                    style= {{ marginLeft: '70%'}}
                    >
          
                    <DeleteIcon />
                  </IconButton>
                  
                  </TableCell>
                </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="footer">
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              showMinimumNumber={showMinimumNumber}
              showMaxNumber={showMaxNumber}
              count={count}
              qtyPages={qtyPages}
            />
          </div>

          <Modal
            open={openModalRegisterConsultation}
            onClose={handleCloseModalRegisterConsultation}
            aria-labelledby="modal-register-consultation-title"
            aria-describedby="modal-register-consultation-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RegisterConsultation
              handleClose={handleCloseModalRegisterConsultation}
              listSpecialities={listSpecialities}
              saveConsultation={true}
              unitId={unitInformations.id}
              listConsultations={listConsultations}
              setListConsultations={setListConsultations}
              showAlert={setAlertState}
              stateSnackBar={stateSnackBar}
              setStateSnackBar={setStateSnackBar}
            />
          </Modal>


          <Modal
            open={OpenModalDeleteConsultation}
            onClose={handleCloseModalDeleteConsultation}
            aria-labelledby="modal-register-consultation-title"
            aria-describedby="modal-register-consultation-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DeleteConsultation
              handleClose={handleCloseModalDeleteConsultation}
              listConsultations={listConsultations}
              setListConsultations={setListConsultations}
              unitHasSpecialityId={unitHasSpecialityId}
              stateSnackBar={stateSnackBar}
              setStateSnackBar={setStateSnackBar}
            />
          </Modal>


          <SnackBar
              stateSnackBar={stateSnackBar}
              setStateSnackBar={setStateSnackBar}
            />

        </>
      )}
    </React.Fragment>
  );
};
