import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";

import Input from "../../../../../common/input";
import empty from "../../../../../common/validators/empty";
export function SpecialtyInput(props){
const {listSpecialities, specialityFromDatabase, specialitiesArray,  setSpecialitiesArray, index, setSpecialityError, specialityError} = props
const { stateSnackBar, setStateSnackBar } = props;

const [currentSpeciality, setCurrentSpeciality] = useState()

async function changeSpecialty(e, newValue, currentSpeciality){
 
    if (specialityAlreadyExists(newValue)) {
        setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: "Essa especialidade já foi escolhida!",
          })
        
      } else {
        inactivePreviousSpeciality(newValue, currentSpeciality)
        deleteSpecialityIfNotActiveAndNotFromDataBase()
        handleNewSpeciality(newValue)
    }    
}

function inactivePreviousSpeciality(newValue){
  
  const currentSpeciality = handleSpecialitySource()
  if(newValue?.specialities.id !== currentSpeciality?.specialities?.id){
  
    if(!!specialitiesArray.find(x => x.specialities.id === currentSpeciality?.specialities?.id)){
    
      const index = specialitiesArray.findIndex(x => x.specialities.id === currentSpeciality?.specialities?.id)
      specialitiesArray[index].specialities.active = false
      setSpecialitiesArray(specialitiesArray)
   

    }
  }
}

function deleteSpecialityIfNotActiveAndNotFromDataBase(){
  const array = specialitiesArray.filter(x => !(x.specialities.currentSpeciality === false && x.specialities.active === false))
  setSpecialitiesArray(array)
}

function specialityAlreadyExists(newValue){
  
  const specialtiesWithoutCurrent = specialitiesArray.filter(x =>x.specialities.id !== specialityFromDatabase?.specialities?.id) || []
  if(newValue  
    && specialtiesWithoutCurrent.find(x => x.specialities.id === newValue.specialities.id
    && x.specialities.active === true))
    return true
  else
  return false
}

function handleNewSpeciality(newValue){
    if(specialityAlreadyExistsButIsInactive(newValue)){
    
    const speciality = setSpecialityActive(newValue)
    setCurrentSpeciality(speciality)
    } else {
        
    const newSpeciality = addNewSpeciality(newValue)
    setCurrentSpeciality(newSpeciality)
    }
}

function specialityAlreadyExistsButIsInactive(newValue){
  
  if(specialitiesArray.find(x => x.specialities?.id === newValue?.specialities?.id))
    return true
  else
  return false
}

function setSpecialityActive(newValue){
  const index = specialitiesArray.findIndex(x => x.specialities.id === newValue.specialities.id)
  specialitiesArray[index].specialities.active = true
setSpecialitiesArray(specialitiesArray)

return specialitiesArray[index]
}

function addNewSpeciality(newValue){
  setSpecialitiesArray(specialitiesArray.filter(x => x.specialities.id !== currentSpeciality?.specialities?.id))
        
  const newSpeciality = {id: newValue?.id || '',
    specialities: {
      id: newValue?.specialities?.id || '',
      name: newValue?.specialities?.name || '',
      active: true,
      currentSpeciality: false
    },
  }
  specialitiesArray.splice(index, 0, newSpeciality);
  setSpecialitiesArray(specialitiesArray)

  return newSpeciality
}

function handleSpecialitySource(){
  
  return !!currentSpeciality === true ? currentSpeciality : specialityFromDatabase
}

    return ( 
        <Autocomplete
        id= {index.toString()}
        options={listSpecialities}
        getOptionLabel={(specialty) => specialty?.specialities?.name || ''}
        getOptionSelected={(specialty) => specialty?.specialities?.name || ''}
        value={handleSpecialitySource()}
        onChange={(e, newValue) => {changeSpecialty(e, newValue, handleSpecialitySource()) }}
        openOnFocus
        renderInput={(params) => (
          <Input
            id={`input-specialty${index}-register-professionals-admin`}
            name={`input-specialty${index}-register-professionals-admin`}
            datatest={`input-specialty${index}-register-professionals-admin`}
            width="90%"
            InputProps={params.InputProps}
            inputProps={params.inputProps}
            label="Especialidade"
            placeholder="Especialidade"
            type="text"
            onBlur={() =>
              setSpecialityError(empty(handleSpecialitySource()?.specialities?.name || ""))
            }
            error={specialityError.typeError}
          />
        )}
      />
       )
}