import { makeStyles } from "@material-ui/core/styles";

const formTopic = makeStyles({
  root: {
    margin: "35px 0 20px 0",
    display: "flex",
    flexDirection: "column",
    "& h1": {
      fontSize: "1.42rem",
      fontWeight: "bold",
      color: "#272833",
      margin: "0 0 4px 0",
      padding: 0,
    },
    "& h2": {
      fontSize: "1.16rem",
      fontWeight: 600,
      color: "#393A4A",
      margin: 0,
      padding: 0,
    },
  },
  buttonDeleteHealthInsurance: {
    display: "inline",
  },
});

export { formTopic };
