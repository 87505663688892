import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: props => props.margin ? props.margin : "12px 0 0 0",
        width: '100%',
        '& .Mui-checked':{
            color: '#F9B233'
        },
    },
    borders: {
        border: '1px solid #E7E7ED',
        borderRadius: '4px',
        margin: '0',
        marginRight: '20px',
        width: props => props.width
    },
    labelBorder: {
        borderLeft: '1px solid #E7E7ED',
        height: '45px',
        lineHeight: '45px',
        width: '100%',
        textAlign: 'center'
    },
    sideBySide: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: props => props.marginTop ? props.marginTop : '3px',
    },
    helperText: {
        color: '#686868',
        fontSize: '14px',
        marginTop: '7px'
    },
})

const RadioButtons = (props) => {
    const classes = useStyles(props);
    return (
        <Grid container className={classes.root}>
            <Grid item>
                <span>
                    <strong className={classes.title}>{props.label}</strong>
                </span>
                <RadioGroup 
                    aria-label="scheduling" 
                    name="examScheduling"
                    className={classes.sideBySide}
                    value={props.value} 
                    onChange={props.onChange}
                >
                    <FormControlLabel 
                        value="Sim" 
                        control={<Radio />} 
                        label="Sim" 
                        disabled={props.disabled}
                        classes={ {
                            root: classes.borders,
                            label: classes.labelBorder
                        }}
                    />
                    <FormControlLabel 
                        value="Não" 
                        control={<Radio />} 
                        label="Não"
                        disabled={props.disabled}
                        classes={ {
                            root: classes.borders,
                            label: classes.labelBorder
                        }}
                    />
                </RadioGroup>
                <div className={classes.helperText}>
                    <span >{props.helperText}</span>
                </div>
            </Grid>
        </Grid>
    );
}

export default RadioButtons;