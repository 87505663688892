import React, { useState } from "react";

import StepByStep from "../../../../common/stepByStep/stepByStep";
import Topic from "../../../../common/topic";
import AddressModal from "../../../../common/modal/address/registterAddress";
import Button from "../../../../common/button";

import Modal from "@material-ui/core/Modal";

import withoutAddressImg from "../../../../assets/without-address-img.png";

import { formTopic } from "../style";

export default (props) => {
  const formTopicClasses = formTopic();
  const formTopicClassesImg = formTopic({ margin: "0px" });

  const [openModalRegisterAddrres, SetOpenModalRegisterAddress] = useState(
    false
  );

  const handleOpenModalRegisterAddress = () => {
    SetOpenModalRegisterAddress(true);
  };

  const handleCloseModalRegisterAddress = () => {
    SetOpenModalRegisterAddress(false);
  };

  const modalRegisterAddressRef = React.createRef(null);

  return (
    <div>
      <StepByStep
        activeStep={props.activeStep}
        disableForward={true}
        handleBack={props.previousStep}
      />
      <div className="form-layout">
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Endereços de Atendimento"
          subtitle="Cadastre aqui seus endereços de atendimento"
          classesNameSubtitle="text-grey"
        />

        <div className="without-address space-above-item-less">
          <div className="without-address-layout">
            <img
              className="without-address-img"
              src={withoutAddressImg}
              alt=""
            />
            <Topic
              classesNameCol={formTopicClassesImg.root}
              title="Nenhum endereço cadastrado"
              subtitle="Adicione um endereço para comerçar."
              classesNameSubtitle="text-grey"
            />
            <Button
              name="button-without-address-add"
              datatest="button-without-address-add"
              margin="22px 0 0 0 "
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              width="22vw"
              maxWidth="134px"
              onClick={handleOpenModalRegisterAddress}
            >
              Adicionar
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={openModalRegisterAddrres}
        onClose={handleCloseModalRegisterAddress}
        aria-labelledby="modal-register-address-title"
        aria-describedby="modal-register-address-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddressModal
          handleClose={handleCloseModalRegisterAddress}
          ref={modalRegisterAddressRef}
        />
      </Modal>
    </div>
  );
};
