const INITIAL_STATE = {
  active: -1,
  showRegisterServices: false,
  units: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "REGISTER_ADDRESS":
      return { ...state, units: [...state.units, action.payload] };

    case "ALTER_ACTIVE":
      return { ...state, active: action.payload };

    case "TOGGLE_SHOW_REGISTER_SERVICES":
      return { ...state, showRegisterServices: !state.showRegisterServices };

    case "INSERT_SERVICES_ADDRESS":
      return {
        ...state,
        units: state.units.map((address, index) =>
          index === state.active
            ? {
                ...address,
                specialities: action.payload.consultations,
                exams: action.payload.exams,
                examsSpreadsheet: action.payload.examsSpreadsheet,
                //proceedings: action.payload.proceedings,
                //otherServices: action.payload.otherServices,
              }
            : address
        ),
      };

    case "UPDATE_ADDRESS":
      return {
        ...state,
        units: state.units.map((address, index) =>
          action.index === index ? (address = action.payload) : address
        ),
      };

    case "DELETE_ADDRESS":
      return {
        ...state,
        units: state.units.filter((address, index) => index !== action.payload),
        active: -1,
      };

    default:
      return state;
  }
}
