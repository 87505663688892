import React, { useState, useEffect, forwardRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import empty from "../../validators/empty";
import Input from "../../input";
import Button from "../../button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "../../circular-progress";

import "../style.css";
import * as consultation from "../../store/actions/registerConsultations";

import currencyMask from "../../mask/currency";
import { priceToNumber } from '../../../utils/price/priceToNumber'
import SnackBar from "../../snackbar";

import * as taxService from '../../../services/taxes'
import * as specialtyService from '../../../services/specialties'
import calcTax from '../../../utils/calcTax'

import { modal } from "../style";

export default forwardRef((props, ref) => {
  const classesModal = modal({maxWidth:"540px"});

  const consultations = useSelector(
    (state) => state.RegisterConsultations.consultations
  );
  const active = useSelector((state) => state.RegisterConsultations.active);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [specialty, setSpecialty] = useState({ id: "", name: "", group: "" });
  const [specialtyError, setSpecialtyError] = useState({
    error: true,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const [price, setPrice] = useState(currencyMask(0));
  const [taxAppointmentFeeProvider, setTaxAppointmentFeeProvider] = useState(0);
  const [taxAppointmentFeePatient, setTaxAppointmentFeePatient] = useState(0);
  const [ listSpecialities, setListSpecialities ] = useState([])

  useEffect(() => {
    if (active >= 0) {
      const { id, name, group, price } = consultations[active];
      setSpecialty({ id, name, group });
      setPrice(currencyMask(price.toFixed(2)));
    }
  }, [active, consultations]);

  useEffect(() => {
    setSpecialtyError({ ...specialtyError, error: empty(specialty).error });
    //eslint-disable-next-line
  }, [specialty]);

  useEffect(() => {
    loadAll()
    // eslint-disable-next-line
  }, []);

  const loadTaxes = async() => {
    const taxes = await taxService.getTaxesByTypes()

    if(taxes.error) {
      setStateSnackBar((state) => ({
        ...state,
        open: true,
        message: taxes.message,
      }))
    } else {
      const { taxAppointmentFeePatient, taxAppointmentFeeProvider } = taxes
      setTaxAppointmentFeePatient(taxAppointmentFeePatient)
      setTaxAppointmentFeeProvider(taxAppointmentFeeProvider)
    }
  }

  const loadSpecialities = async() => {
    const specialties = await specialtyService.listSpecialties()

    if(!specialties.error) {
      setListSpecialities(specialties)
    }
  }

  const loadAll = async() => {
    setLoading(true)
    await loadTaxes()
    await loadSpecialities()
    setLoading(false)
  }

  function handleDelete() {
    dispatch(consultation.deleteConsultation(active));
    props.handleClose();
  }

  function handleUpdate() {
    setSpecialtyError(empty(specialty));

    if (!specialtyError.error) {
      dispatch(
        consultation.updateConsultation(active, {
          ...specialty,
          price: priceToNumber(price),
        })
      );
      props.handleClose();
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <div className={classesModal.root}>
          <div
            className={classesModal.title}
            id="modal-alter-consultation-title"
          >
            <h1>Alterar Consulta</h1>
            <IconButton aria-label="close" onClick={() => props.handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classesModal.body} id="modal-alter-consultation-body">
            <Autocomplete
              id="combo-box-specialty-consultation"
              options={listSpecialities}
              getOptionLabel={(specialty) => specialty.name}
              getOptionSelected={(specialty) => specialty.name}
              value={specialty}
              onChange={(e, newValue) =>
                newValue
                  ? setSpecialty(newValue)
                  : setSpecialty({ id: "", name: "", group: "" })
              }
              renderInput={(params) => (
                <Input
                  name="input-specialty-delete-uptade-consultation"
                  datatest="input-specialty-delete-uptade-consultation"
                  width="90%"
                  InputProps={params.InputProps}
                  inputProps={params.inputProps}
                  label="Especialidade"
                  placeholder="Especialidade"
                  type="text"
                  onBlur={() => setSpecialtyError(empty(specialty.name))}
                  error={specialtyError.typeError}
                />
              )}
            />

            <Input
              name="input-price-delete-uptade-consultation"
              datatest="input-price-delete-uptade-consultation"
              width="90%"
              label="Valor em reais (R$) da Consulta"
              placeholder="Valor da consulta"
              type="text"
              value={currencyMask(price)}
              onChange={(e) => setPrice(currencyMask(e.target.value))}
            />

            <span className={classesModal.span}>
              O valor cobrado do prestador pela plataforma será de:{" "}
              <strong>
                R${" "}
                {
                  calcTax({
                    price: parseFloat(priceToNumber(price)),
                    tax: taxAppointmentFeeProvider,
                    sum: false
                  })
                }
              </strong>
            </span>
            <span className={classesModal.span}>
             O valor final que a plataforma irá cobrar do paciente será de:{" "}
              <strong>
                R${" "}
                {
                  calcTax({
                    price: parseFloat(priceToNumber(price)),
                    tax: taxAppointmentFeePatient,
                    sum: true
                  })
                }
              </strong>
            </span>
          </div>
          <div className={classesModal.footer}>
            <Button
              name="button-delete-consultation"
              datatest="button-delete-consultation"
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              width="22vw"
              maxWidth="120px"
              margin="0 20px 0 0 "
              onClick={() => handleDelete()}
            >
              Deletar
            </Button>
            <Button
              name="button-update-consultation"
              datatest="button-update-consultation"
              backgroundColor="#FFF"
              border="1px solid #DF9000"
              color="#DF9000"
              width="22vw"
              maxWidth="120px"
              margin="0 10% 0 0"
              onClick={() => handleUpdate()}
            >
              Alterar
            </Button>
          </div>
          <SnackBar
            stateSnackBar={stateSnackBar}
            setStateSnackBar={setStateSnackBar}
          />
        </div>
      )}
    </React.Fragment>
  );
});
