const INITIAL_STATE = {
  initialRegister: [
    {
      typeAccount: "",
      cpf: "",
      name: "",
      email: "",
      telephone: "",
      password: "",
    },
  ],

  finalRegister: [
    {
      pj: {
        typeStablishment: "",
        stablishmentDatas: {
          tradingName: "",
          companyName: "",
          cnpj: "",
          companyEmail: "",
          ansRegistry: "",
          technicianResponsibleName: "",
          technicianResponsibleDocument: "",
          legalResponsibleName: "",
          legalResponsibleDocument: "",
          logo: { name: "" },
          bank: "",
          agency: "",
          account: "",
          numberOfInstallments: 1,
          minimumInstallmentAmount: 1,
          healthInsurances: [
            {
              healthInsurances: { plans: [], id: "", fantasyName: "" },
              plans: { name: "" },
            },
          ],
          plans: [{ name: "" }],
          socialContract: { name: "" },
          cnpjAttachment: { name: "" },
          companyResponsibleAttachment: { name: "" },
          technicianResponsibleDocumentAttachment: { name: "" },
          proofResidence: { name: "" },
          careModality: "",
          country: "",
          zipCode: "",
          address: "",
          number: "",
          complement: "",
          neighborhood: "",
          district: "",
          city: "",
          phone: "",
          whatsapp: "",
        },
      },
      pf: {
        personalDatas: {
          cpf: "",
          name: "",
          email: "",
          telephone: "",
          profession: "",
          crm: "",
          specialty1: "",
          rqe1: "",
          specialty1Titles: { name: "" },
          specialty2: "",
          rqe2: "",
          specialty2Titles: { name: "" },
          supportType: "",
          professionalDocument: { name: "" },
          proofOfResidence: { name: "" },
          bank: "",
          agency: "",
          account: "",
          numberPlots: 1,
          minAmount: 0,
          healthInsurances: [{ healthInsurances: "", plan: "" }],
          consentForm: { name: "" },
        },
        curriculum: {
          photo: { name: "" },
          about: "",
          experience: "",
          medicalConditions: "",
          formation: "",
          linkCurriculumLatters: "",
        },
      },
    },
  ],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "INSERT_TYPE_ACCOUNT_INITIAL_REGISTER":
      return {
        ...state,
        initialRegister: [
          ...state.initialRegister,
          (state.initialRegister[0].typeAccount = action.payload),
        ],
      };

    case "UPDATE_INITIAL_REGITER":
      return { ...state, initialRegister: [action.payload] };
    case "INSERT_PASSWORD_INITIAL_REGISTER":
      return {
        ...state,
        initialRegister: [
          ...state.initialRegister,
          (state.initialRegister[0].password = action.payload),
        ],
      };

    case "UPDATE_PJ_REGITER_TYPE":
      return {
        ...state,
        finalRegister: [
          ...state.finalRegister,
          (state.finalRegister[0].pj.typeStablishment = action.payload),
        ],
      };

    case "UPDATE_PJ_REGITER_DATA":
      return {
        ...state,
        finalRegister: [
          ...state.finalRegister,
          (state.finalRegister[0].pj.stablishmentDatas = action.payload),
        ],
      };

    case "UPDATE_PF_REGITER_DATA":
      return {
        ...state,
        finalRegister: [
          ...state.finalRegister,
          (state.finalRegister[0].pf.personalDatas = action.payload),
        ],
      };

    case "UPDATE_PF_REGITER_CURRICULUM":
      return {
        ...state,
        finalRegister: [
          ...state.finalRegister,
          (state.finalRegister[0].pf.curriculum = action.payload),
        ],
      };

    default:
      return state;
  }
}
