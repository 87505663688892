import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const {
    legalResponsibleName,
    setLegalResponsibleName,
    legalResponsibleNameError,
    setLegalResponsibleNameError,
  } = props;

  const {
    legalResponsibleDocument,
    setLegalResponsibleDocument,
    legalResponsibleDocumentError,
    setLegalResponsibleDocumentError,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Responsável Legal"
          subtitle="Quem é seu responsavél legal?"
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9} lg={3}>
          <Input
            name="input-legalResponsibleName-legalManager"
            datatest="input-legalResponsibleName-legalManager"
            label="Nome do diretor/responsável legal"
            placeholder="Nome do Responsavél"
            type="text"
            direction="row"
            value={legalResponsibleName}
            onChange={(e) => setLegalResponsibleName(e.target.value)}
            onBlur={() =>
              setLegalResponsibleNameError(
                empty(legalResponsibleName)
              )
            }
            error={legalResponsibleNameError.typeError}
          />
        </Grid>
        <Grid item xs={8} lg={2}>
          <Input
            name="input-legalResponsibleDocument-legalManager"
            datatest="input-legalResponsibleDocument-legalManager"
            label="Documento pessoal"
            placeholder="Documento"
            direction="row"
            type="text"
            value={legalResponsibleDocument}
            onChange={(e) => setLegalResponsibleDocument(e.target.value)}
            onBlur={() =>
              setLegalResponsibleDocumentError(
                empty(legalResponsibleDocument)
              )
            }
            error={legalResponsibleDocumentError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
