import { makeStyles } from "@material-ui/core/styles";

const modal = makeStyles({
  root: {
    position: "absolute",
    marginBottom: "16px",
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: "10px",
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : "474px"),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    height: "68px",
    alignItems: "center",
    borderBottom: "1px solid #E7E7ED",
    "& h1": {
      fontWeight: 600,
      fontSize: "1.28rem",
      color: "#272833",
      margin: "0 0 0 24px",
      padding: "0",
    },
  },
  body: {
    marginLeft: "24px",
    minHeight: "325px",
    maxHeight: "70vh",
    overflowY: "scroll",
    marginBottom: "30px",
    "& div.select-edit-professionals": {
      margin: "10px 45px 0 0",
    },
  },
  modalActiveOrInactive: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    "& h3": {
      fontWeight: "normal",
    },
  },
  span: {
    marginTop: "16px",
    color: "#686868",
    fontWeight: "normal",
  },
  footer: {
    marginTop: "20px",
    minHeight: "83px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: " 0px 0px 10px 10px;",
    backgroundColor: "#F7F8F9",
  },
  footerModalActiveOrInactive: {
    marginTop: "20px",
    paddingLeft: "60px",
    minHeight: "83px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: " 0px 0px 10px 10px;",
    backgroundColor: "#F7F8F9",
  },
});

export { modal };
