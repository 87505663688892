export const openSlotModal = (payload) => {
  return {
    type: "OPEN_SLOT_MODAL",
    payload,
  };
};

export const addUserStatus = (payload) => {
  return {
    type: "ADD_USER_STATUS",
    payload,
  };
};

export const addUserName = (payload) => {
  return {
    type: "ADD_USER_NAME",
    payload,
  };
};

export const addTimeSpan = (payload) => {
  return {
    type: "ADD_TIME_SPAN",
    payload,
  };
};

export const addDaysWork = (payload) => {
  return {
    type: "ADD_DAYS_WORK",
    payload,
  };
};

export const addDaysOfWeek = (payload) => {
  return {
    type: "ADD_DAYS_OF_WEEK",
    payload,
  };
};

export const toggleSelectedAllDays = (payload) => {
  return {
    type: "TOGGLE_SELECTED_ALL_DAYS",
    payload,
  };
};

export const addDuration = (payload) => {
  return {
    type: "ADD_DURATION",
    payload,
  };
};
