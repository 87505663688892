import React from "react";

import Input from "../../../../../common/input";
import Button from "../../../../../common/button";

import emailValidator from "../../../../../common/validators/email";
import empty from "../../../../../common/validators/empty";
import cpfValidator from "../../../../../common/validators/cpf";
import cpfMask from "../../../../../common/mask/cpf";

export default (props) => {
  const { email, setEmail, emailError, setEmailError } = props;
  const { name, setName, nameError, setNameError } = props;
  const { cpf, setCpf, cpfError, setCpfError } = props;

  const handleNextStep = () => {
    if (
      emailValidator(email).error ||
      empty(name).error ||
      cpfValidator(cpf).error
    ) {
      setEmailError(emailValidator(email));
      setNameError(empty(name));
      setCpfError(cpfValidator(cpf));
    } else {
      props.nextStep();
    }
  };
  return (
    <div>
      <div className={props.classesModal.body}>
        <Input
          width="90%"
          label="Nome"
          placeholder="Nome"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => setNameError(empty(name))}
          error={nameError.typeError}
        />
        <Input
          width="90%"
          label="Email"
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setEmailError(emailValidator(email))}
          error={emailError.typeError}
        />

        <Input
          width="90%"
          label="CPF"
          placeholder="CPF"
          type="text"
          value={cpf}
          onChange={(e) => setCpf(cpfMask(e.target.value))}
          onBlur={() => setCpfError(cpfValidator(cpf))}
          error={cpfError.typeError}
        />
      </div>
      <div className={props.classesModal.footer}>
        <Button
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          Cancelar
        </Button>
        <Button
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => handleNextStep()}
        >
          Proximo
        </Button>
      </div>
    </div>
  );
};
