import React, { useState, useEffect, useCallback } from "react";
import apiGraphql from "../../../../services/api";

import Loading from "../../../../common/circular-progress";
import Button from "../../../../common/button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import Pagination from "../../../../common/pagination";

import SnackBar from "../../../../common/snackbar";

import ModalNewRepresentative from "./modals";

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showMinimumNumber, setShowMinimunNumber] = useState(1);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [qtyPages, setQtyPages] = useState(1);
  const [openModalNewRepresentative, setOpenModalNewRepresentative] = useState(
    false
  );
  const [listAnalysts, setListAnalysts] = useState([]);

  const handleOpenModalNewRepresentative = () => {
    setOpenModalNewRepresentative(true);
  };

  const handleCloseModalNewRepresentative = () => {
    setOpenModalNewRepresentative(false);
  };

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const getListAnalysts = useCallback(() => {
    setLoading(true);
    const healthOperatorId = localStorage.getItem("healthOperatorId");
    apiGraphql({
      data: {
        query: `
          query{
            listHealthOperatorsEmployee(limit:${limit}, page:${page},healthOperatorId:"${healthOperatorId}"){
              count
              rows{
                id
                name
              }
            }
          }
        `,
      }
    })
      .then((res) => {
        setLoading(false);
        setListAnalysts(res.data.data.listHealthOperatorsEmployee.rows);
        setCount(res.data.data.listHealthOperatorsEmployee.count);
        setShowMaxNumber(limit * page);
        setShowMinimunNumber(page * limit - limit + 1);
        setQtyPages(
          count % limit === 0
            ? Math.floor(count / limit)
            : Math.floor(count / limit) + 1
        );
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          message:
            "Houve um erro ao buscar os dados, tente novamente mais tarde! ",
        }));
        console.log(err);
      });
  }, [count, limit, page]);

  useEffect(() => {
    getListAnalysts();
  }, [getListAnalysts]);

  return (
    <div>
      <div className="header-analysts">
        <h1>Analistas de Convênio</h1>
        <Button
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          fontFamily="Montserrat"
          fontSize="1 rem"
          width="100vw"
          maxWidth="195px"
          onClick={() => handleOpenModalNewRepresentative()}
        >
          Novo Representante
        </Button>
      </div>
      <div className="list-analysts">
        {loading && <Loading size={80} />}
        {!loading && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {listAnalysts.map((analyst, index) => (
                  <TableRow key={index}>
                    <TableCell>{analyst.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="footer">
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          showMinimumNumber={showMinimumNumber}
          showMaxNumber={showMaxNumber}
          count={count}
          qtyPages={qtyPages}
        />
      </div>

      <Modal
        open={openModalNewRepresentative}
        onClose={handleCloseModalNewRepresentative}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalNewRepresentative
          handleClose={handleCloseModalNewRepresentative}
          setStateSnackBar={setStateSnackBar}
        />
      </Modal>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
