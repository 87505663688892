import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import apiGraphql from "../services/api";

import Navbar from "../common/navbar";

export default ({ component, ...rest }) => {
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("Token-Vida-Connect")) {
      localStorage.clear();
      history.push("/");
    }

    apiGraphql({
      data: {
        query: `
          query{
            checkSessionTokenEstablishment
          }
        `,
      }
    })
      .then((res) => {
        if (!res.data.data.checkSessionTokenEstablishment) {
          localStorage.clear();
          history.push("/");
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/");
      });
  }, [history]);
  return (
    <React.Fragment>
      <Navbar />
      <Route
        {...rest}
        render={() => React.createElement(component, { ...rest })}
      />
    </React.Fragment>
  );
};
