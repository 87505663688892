import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import StepByStep from "../../../../common/stepByStep/stepByStep";
import Button from "../../../../common/button";
import Box from "@material-ui/core/Box";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import RegisterAddressModal from "../../../../common/modal/address/registterAddress";
import UpdateAddressModal from "../../../../common/modal/address/updateAddress";
import DeleteAddressModal from "../../../../common/modal/address/deleteAddress";

import * as consultationActions from "../../../../common/store/actions/registerConsultations";
import * as examsActions from "../../../../common/store/actions/registerExams";
import * as addressActions from "../../../../common/store/actions/registerAddress";

import Modal from "@material-ui/core/Modal";

import { contentPageRegisterServices, addressRegistered } from "../style";

export default (props) => {
  const classesNameContentPage = contentPageRegisterServices({
    ...contentPageRegisterServices,
    marginTop: "37px",
  });
  const classesAddresRegistered = addressRegistered();

  const units = useSelector((state) => state.Address.units);
  const activeAddress = useSelector((state) => state.Address.active);
  const dispatch = useDispatch();

  const [openDrodown, setOpenDropDown] = useState(false);
  const [openModalRegisterAddress, SetOpenModalRegisterAddress] = useState(
    false
  );
  const [openModalUpdateAddress, setOpenModalUpdateAddress] = useState(false);
  const [openModalDeleteAddress, setOpenModalDeleteAddress] = useState(false);
  const [dropDownActive, setOpenDropDownActive] = useState(0);

  const toggleOpenDropDown = () => {
    setOpenDropDown(!openDrodown);
  };

  const handleOpenModalRegisterAddress = () => {
    SetOpenModalRegisterAddress(true);
  };

  const handleCloseModalRegisterAddress = () => {
    SetOpenModalRegisterAddress(false);
  };

  const handleOpenModalUpdateAddress = () => {
    setOpenModalUpdateAddress(true);
  };

  const handleCloseModalUpdateAddress = () => {
    setOpenModalUpdateAddress(false);
  };

  const handleOpenModalDeleteAddress = () => {
    setOpenModalDeleteAddress(true);
  };

  const handleCloseModalDeleteAddress = () => {
    setOpenModalDeleteAddress(false);
  };

  const modalRegisterAddressRef = React.createRef(null);
  const modalUpdateAddressRef = React.createRef(null);
  const modalDeleteAddressRef = React.createRef(null);

  async function handleUpdateServices() {
    units[activeAddress].specialities.forEach((consultation) => {
      dispatch(
        consultationActions.RegisterConsultation(
          consultation
        )
      );
    });
    units[activeAddress].exams.forEach((exam) => {
      dispatch(
        examsActions.RegisterExam(
          exam
        )
      );
    });
    dispatch(examsActions.RegisterExamSpreadsheet(units[activeAddress].examsSpreadsheet))
    await dispatch(addressActions.toggleRegisterServices());
  }

  return (
    <div>
      <StepByStep
        activeStep={props.activeStep}
        handleAdvance={props.nextStep}
        handleBack={props.previousStep}
      />
      <Box className={classesNameContentPage.root}>
        <Box className={classesNameContentPage.boxMarginSide}>
          <Box className={classesNameContentPage.boxTitleWithButton}>
            <Box component="span" className={classesNameContentPage.boxTitle}>
              <h1>Endereços Cadastrados</h1>
              <h2>Deseja cadastrar outros endereços de atendimento?</h2>
            </Box>
            <Box component="span" className={classesNameContentPage.boxButton}>
              <Button
                name="button-with-address-add"
                datatest="button-with-address-add"
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                width="22vw"
                maxWidth="168px"
                onClick={handleOpenModalRegisterAddress}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
          {units.map((address, index) => (
            <Box key={index} className={classesAddresRegistered.root}>
              <h3>
                {index + 1} - {address.address}
                {address.number && <span>, nº {address.number}</span>}
              </h3>
              <i
                onClick={() => {
                  setOpenDropDownActive(index);
                  dispatch(addressActions.alterActiveAddress(index));
                  toggleOpenDropDown();
                }}
              >
                <MoreVertIcon />
                {openDrodown && dropDownActive === index ? (
                  <div className={classesAddresRegistered.dropdown}>
                    <div
                      name="alter-address"
                      data-test="alter-address"
                      onClick={handleOpenModalUpdateAddress}
                    >
                      Alterar Endereço
                    </div>
                    <div
                      name="alter-services"
                      data-test="alter-services"
                      onClick={() => handleUpdateServices()}
                    >
                      Alterar Serviços
                    </div>
                    <div
                      name="delete-address"
                      data-test="delete-address"
                      onClick={handleOpenModalDeleteAddress}
                    >
                      Excluir Endereço
                    </div>
                  </div>
                ) : null}
              </i>
            </Box>
          ))}
        </Box>
      </Box>

      <Modal
        open={openModalRegisterAddress}
        onClose={handleCloseModalRegisterAddress}
        aria-labelledby="modal-register-address-title"
        aria-describedby="modal-register-address-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RegisterAddressModal
          handleClose={handleCloseModalRegisterAddress}
          ref={modalRegisterAddressRef}
        />
      </Modal>
      <Modal
        open={openModalUpdateAddress}
        onClose={handleCloseModalUpdateAddress}
        aria-labelledby="modal-update-address-title"
        aria-describedby="modal-update-address-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UpdateAddressModal
          handleClose={handleCloseModalUpdateAddress}
          ref={modalUpdateAddressRef}
        />
      </Modal>
      <Modal
        open={openModalDeleteAddress}
        onClose={handleCloseModalDeleteAddress}
        aria-describedby="modal-delete-address-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DeleteAddressModal
          handleClose={handleCloseModalDeleteAddress}
          ref={modalDeleteAddressRef}
        />
      </Modal>
    </div>
  );
};
