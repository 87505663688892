import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from '@material-ui/core/TableFooter';

import Pagination from "../../../../common/pagination";
import Button from "../../../../common/button"
import Loading from '../../../../common/circular-progress'
import InputFileButton from "../../../../common/fileInput/fileInputButton";
import { validationFileType } from '../../../../common/validators/fileInput'
import EditMenu from '../../../../common/editMenu';
import SnackBar from '../../../../common/snackbar';
import DownloadSpreadsheet from '../../../../common/DownloadSpreadsheet';

import * as examsService from '../../../../services/exams'
import apiGraphql from "../../../../services/api";

import AddExam from './modal/addExam';
import EditExam from './modal/editExam';

import * as examsActions from '../../../../common/store/actions/registerExams';

export default ({unitInformations, ...props}) => {
  
  const dispatch = useDispatch();
  
  const establishmentId = window.localStorage.getItem("Establishment-ID-Vida-Connect");
  
  
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [ page, setPage ] = useState(1)
  const firstItem = (page*itemsPerPage)-(itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);
  
  const [ loading, setLoading] = useState(false)
  
  
  const [ qtPages, setQtPages ] = useState(1)
  const [ showMinimumNumber, setShowMinimunNumber ] = useState(0);
  

  const [examFee, setExamFee] = useState(0)
  const examList = useSelector((state) => state.Exams.establishmentExams);
  
  

  const [establishmentExams, setEstablishmentExams] = useState([]);
  
  const [selectedExam, setSelectedExam] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
 

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
    color: ""
  });
  
  const [updateExams, setUpdateExams] = useState(new Date().getTime())

  let editModal = useSelector((state) => state.Exams.openEditModal)
  
  let selectedExamIndex = useSelector((state) => state.Exams.selectedEstablishmentExam.payload)

  
  useEffect( () => {
      setEstablishmentExams(examList);
      //eslint-disable-next-line
  },[examList])
  
  useEffect( () => {
      setOpenEditModal(editModal)
      //eslint-disable-next-line
  },[editModal])
  useEffect( () => {
      setSelectedExam(selectedExamIndex)
      //eslint-disable-next-line
  },[selectedExamIndex])
  
  useEffect( () => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `query Query($establishment: ID!, $unit: ID!) {
          getEstablishmentsHasServicesProvided(establishmentId: $establishment, unitId: $unit) {
            serviceProvided {
              description
              code
              category
            }
            id
            price
            needScheduling
            preparation
            active
          }
        }
        `,
        variables: {
          establishment: establishmentId,
          unit: unitInformations.id
        },
      },
    })
      .then((res) => {
   
       if(res.data){
        setEstablishmentExams(res.data.data.getEstablishmentsHasServicesProvided);
        dispatch(examsActions.setChoosenEstablishmentExams(res.data.data.getEstablishmentsHasServicesProvided));
       }
       setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

      apiGraphql({
        data: {
          query: `
              query Query($getTaxByType: TaxType!) {
                 getTaxByType(type: $getTaxByType)
              }`,
          variables: {
            getTaxByType: "EXAM_FEE_PROVIDER"
          },
        },
      }).then((res) => {
        if(res.data.data.getTaxByType){
        
          const taxExamFee = res.data.data.getTaxByType
          setExamFee(taxExamFee)
        }

      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

  
  //eslint-disable-next-line
    },[establishmentId, unitInformations.id, updateExams])


  useEffect(() => {
   
    setShowMinimunNumber(itemsPerPage * (page - 1) + 1);
    setQtPages(
      establishmentExams.length % itemsPerPage === 0
        ? Math.floor(establishmentExams.length / itemsPerPage)
        : Math.floor(establishmentExams.length / itemsPerPage) + 1
    );
    setEstablishmentExams(establishmentExams)
  }, [itemsPerPage, page, establishmentExams])

  const handleUpdateExam = async(index, row)=> {
    const selectedIndex = index + ((page - 1) * itemsPerPage)
    
    setSelectedExam(selectedIndex);
    dispatch(examsActions.getSelectedEstablishmentExam(selectedIndex, establishmentExams[selectedIndex] ))
    
    
   
    console.log('INDEX', index, 'PAGE', page, 'selectedIndex', selectedIndex)
    console.log('EXAM', establishmentExams[selectedIndex] )
    
    setOpenEditModal(true)
    dispatch(examsActions.openEditModal(true));
  }

  const handleExamCategory = (category) =>{
    switch (category)
  {
      case 'OUTROS':
          return 'Outros'

      
      case 'EXAMES_DE_IMAGEM':
          return 'Exames de imagem'

      
      case 'EXAMES_LABORATORIAIS':
          return 'Exames laboratoriais';

      case 'HEMODERIVADOS':
          return 'Hemoderivados';

      case 'INSUMOS_E_MATERIAIS':
          return 'Insumos e materiais';

      case 'CONSULTA':
          return 'Consulta';

      case 'OUTROS_PROFISSIONAIS':
          return 'Outros profissionais';

      case 'PROCEDIMENTOS_DIAGNOSTICOS':
        return 'Procedimentos diagnósticos'
    
        case 'PROCEDIMENTOS_TERAPEUTICOS':
           return  'Procedimentos terapêuticos'
          
      default:
          return ''
          
  }
  }


  const handleAddExam = async () => {
   dispatch(examsActions.openAddModal(true));
  };

  const handleSave = async  (data) => {
    return examsService.saveExamSpreadsheet(data);
  };

  const handleChangeInputFile = async (value, typesAccepted) => {
    setLoading(true)
    if (!value) {
      
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Erro ao incluir planilha, por favor, tente novamente.",
      });

      return 
    }
    
  
    const typeIsAccepted =  validationFileType(value.type,typesAccepted)
    
    if (!typeIsAccepted) {
    
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "O tipo do arquivo selecionado não é aceito para esse campo!",
      });
    
      return
    } 

    const data = createFormData(value)
    const res = await handleSave(data)
    
    saveSpreadsheetResponse(res)

    setLoading(false)
    return 
  }
  const createFormData = (file) => {
    const data = new FormData();
    data.append("establishmentId", establishmentId);  
    data.append("unitId", unitInformations.id);  
    data.append("file", file);

    return data
  }

  const saveSpreadsheetResponse = (res) => {
    if(res.success){
      setUpdateExams(new Date().getTime())
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        color: "success",
        message: "Planilha incluída com sucesso.",
      });
    }else{

      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: res.message,
      });
    }
  }

  const typesAccepted = ['application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

  return (
    <React.Fragment>
      {loading && <Loading size={80} />}
      {!loading && (
        <>
        
          <div className="header-consultation">
            <div>
              <Button
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                fontSize="1 rem"
                width="100vw"
                maxWidth="91px"
                onClick={() => props.setAlreadyChooseUnit(false)}
              >
                <NavigateBeforeIcon />
                Voltar
              </Button>
              <h1>
                {unitInformations.address}
                {unitInformations.number
                  ? ",  nº" + unitInformations.number
                  : ""}
              </h1>
            </div>           
            <div style={{display: 'flex', flexDirection: 'row'}}>
          
          <DownloadSpreadsheet/>
            
           <InputFileButton
            id="file"
            width="190px"
            accept=".xlsx"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], typesAccepted)
            }
            value={ "Importar planilha"}
            dataTest="input-pf-file"
            fontSize="15px"
          />
                       
           <Button
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              fontFamily="Montserrat"
              fontSize="1 rem"
              width="100vw"
              maxWidth="190px"
              maxHeight="40px"
              margin="0 0 0 5px"
              onClick={() => handleAddExam()}
            >
              Adicionar Exame
            </Button>         
          </div>
        </div>
        
          <div >
          <TableContainer>
              
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell>Cód.</TableCell>
                    <TableCell>Exame</TableCell>
                    <TableCell>Categoria do exame</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Agendamento</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody >
  
                  {establishmentExams && establishmentExams.slice(firstItem, lastItem).map((row, index) => {
                  let background
                  let category = handleExamCategory(row.serviceProvided.category)
                  row.active ?  background = "": background = "#CDCED9"
                 return (              
                    <TableRow key={row.serviceProvided.code}  style={{backgroundColor: background}}>
                      <TableCell>{row.serviceProvided.code}</TableCell>
                      <TableCell>{row.serviceProvided.description}</TableCell>
                      <TableCell>{category}</TableCell>
                      <TableCell>{`R$: ${row.price}`}</TableCell>
                      <TableCell> {row.needScheduling? 'COM AGENDAMENTO': 'SEM AGENDAMENTO' } </TableCell>                    
                      <TableCell>
                      <div >
                          <EditMenu
                                items={[
                                  {name:"Editar exame", handleItem: handleUpdateExam}
                              ]}
                                index={index}
                              />
                              </div>
                      </TableCell>
                    </TableRow>
                    )})}
                
                </TableBody>
        
              <TableFooter>
                <TableRow>
    
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          </div>
          <div>
          <Pagination 
          page={page}
          setPage={setPage}
          limit={itemsPerPage}
          setLimit={setItemsPerPage}
          showMinimumNumber={showMinimumNumber}
          showMaxNumber={lastItem}
          count={establishmentExams.length}
          qtyPages={qtPages}
        />
        </div>
         

          <AddExam examFee= {examFee} unitId={unitInformations.id}/>
          {(openEditModal && establishmentExams[selectedExam]) && 
                  
                    <EditExam 
                        data={establishmentExams[selectedExam]}
                        index={selectedExam}
                        setOpenEditModal={setOpenEditModal}
                        examFee= {examFee}
                    />
                }
          
        </>
        
      )}
        
        <SnackBar
                stateSnackBar={stateSnackBar}
                setStateSnackBar={setStateSnackBar}
        />
    </React.Fragment>
  )
}
