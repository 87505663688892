export function RegisterExam(payload) {
  return {
    type: "REGISTER_EXAM",
    payload: payload,
  };
}
export function GetExam(payload) {
  return {
    type: "GET_EXAM",
    payload: payload,
  };
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_EXAM",
    payload: index,
  };
}

export function deleteExam(index) {
  return {
    type: "DELETE_EXAM",
    payload: index,
  };
}

export function updateExam(index, details) {
  return {
    type: "UPDATE_EXAM",
    payload: index,
    details,
  };
}

export function deleteAllExams() {
  return { type: "DELETE_ALL_EXAMS" };
}


export function updateProviderExams(index, details) {
  return {
  type: "UPDATE_ESTABLISHMENT_EXAMS",
  payload: index,
  details,
  };
  }
  
  export function RegisterProviderExam(payload) {
  return {
  type: "REGISTER_ESTABLISHMENT_EXAM",
  payload: payload,
  };
  }
  
  export function setChoosenEstablishmentExams(payload) {
  return {
  type:"SET_CHOOSEN_ESTABLISHMENT_EXAMS",
  payload
  }
  }
  export function getSelectedEstablishmentExam(index, details) {
  return {
  type:"GET_SELECTED_ESTABLISHMENT_EXAM",
  payload: index,
  details
  }
  }

  export function openAddModal(payload){
    return {
      type: "OPEN_MODAL_ADD_EXAM",
      payload
    }
  }
  export function openEditModal(payload){
    return {
      type: "OPEN_EDIT_MODAL_EXAM",
      payload
    }
  }
  export function openModal(payload) {
    return {
      type: "OPEN_MODAL_EXAM",
      payload
    }
  }
  export function RegisterExamSpreadsheet(payload) {
    return {
      type: "REGISTER_EXAMS_SPREADSHEET",
      payload: payload,
    };
  }

  export function deleteExamsSpreadsheet() {
    return { type: "DELETE_EXAMS_SPREADSHEET" };
  }

  
  