import { makeStyles } from "@material-ui/core/styles";

const title = makeStyles({
  root: {
    margin: "30px 0 8px 0",
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: "1.42rem",
      fontWeight: "bold",
      color: "#272833",
    },
    "& h2": {
      margin: 0,
      padding: 0,
      fontSize: "1.14rem",
      fontWeight: 600,
      color: "#393A4A",
    },
  },
});

const boxBackGroundAcceptTerm = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
});

const boxContentAcceptTerm = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    height: "350px",
    width: "80%",
    overflowY: "auto",
    backgroundColor: "#FFF",
    padding: "8px 36px",
    "& h1": {
      margin: 0,
      paddingTop: "15px",
      minHeight: "60px",
      fontSize: "1.42rem",
      fontWeight: 600,
      color: "#272833",
      borderBottom: "1px solid #E7E7ED !important",
    },
    "& p": {
      fontSize: "1.28rem",
      fontWeight: "normal",
      color: "#272833",
      "& span.accept-terms-box-signatures": {
        marginTop: "100px",
        display: "flex",
        justifyContent: "space-around",
        "& span.accept-terms-signatures": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexWrap: "wrap",
          fontSize: "1.14rem",
          "& span.accept-terms-responsible": {
            margin: "10px 10px 0 0",
            fontWeight: "bold",
            borderTop: "1px solid #000",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            minWidth: "310px",
          },
        },
      },
    },
  },
});

const boxButtons = makeStyles({
  root: {
    width: "85%",
    marginTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

const titleAwaitValidation = makeStyles({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: "2.57rem",
      color: "#000",
      fontWeight: "normal",
    },
    "& h2": {
      margin: "16px 0 0 0",
      padding: 0,
      fontSize: "1.14rem",
      color: "#DF9000",
      fontWeight: "normal",
    },
  },
});

export {
  title,
  boxBackGroundAcceptTerm,
  boxContentAcceptTerm,
  boxButtons,
  titleAwaitValidation,
};
