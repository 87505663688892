import React, { useState, forwardRef } from "react";
import apiGraphql from "../../../../services/api";

import Button from "../../../../common/button";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";

import { modal } from "./style";
import { checkbox } from "../../../../common/styles/style";

import Loading from "../../../../common/circular-progress";

import SnackBar from "../../../../common/snackbar";

import cpfMask from "../../../../common/mask/cpf";
import { handlePatientName } from "../../../../common/handlePatientName";

export default forwardRef((props, ref) => {
  const classesModal = modal({ maxWidth: "452px" });
  const classesCheckbox = checkbox();

  const { infoSolicitation, getListSolicitations } = props;

  const [loading, setLoading] = useState(false);

  const [requestedService, setRequestedService] = useState("");

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const handleConfirm = () => {
    if (!requestedService) {
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message:
          "Para confirmar, é preciso escolher se o serviço foi aceito ou não!",
      });
    } else {
      setLoading(true);
      const approves = requestedService === "APPROVED" 
      const medicalAppointmentId = infoSolicitation.medicalAppointment.id
      apiGraphql({
        data: {
          query: `
            mutation {
              approvesOrRejectHealthOperatorRequest(approves: ${approves}, medicalAppointmentId: "${medicalAppointmentId}")
            }
          `
        }
      })
        .then((res) => {
          setLoading(false);
          if (res.data.errors) {
            const messageError = res.data.errors[0].message;
            setStateSnackBar({
              ...stateSnackBar,
              open: true,
              message: messageError,
            });
          } else {
            getListSolicitations();
            props.handleClose();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message:
              "Desculpe, houve um erro ao tentar se conectar ao servidor. Tente novamente mais tarde!",
          });
        });
    }
  };
  const isPossibleChangeMedicalAppointment = () => {
    const medicalAppointment = infoSolicitation.medicalAppointment;
    if (!medicalAppointment.date) return true
    const yearMedicalAppointment = medicalAppointment.date.split("-")[0];
    const monthMedicalAppointment = medicalAppointment.date.split("-")[1] - 1;
    const dayMedicalAppointment = medicalAppointment.date.split("-")[2];

    const dateMedicalAppointment = new Date(
      yearMedicalAppointment,
      monthMedicalAppointment,
      dayMedicalAppointment,
      23,
      59
    );

    if (
      dateMedicalAppointment >= new Date() &&
      infoSolicitation.status === "PENDING"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classesModal.root}>
      <div
        className={classesModal.title}
        id="modal-register-consultation-title"
      >
        <h1>Solicitação</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classesModal.body}>
        {loading && (
          <Loading size={80} position="absolut" height="40vh" width="30vw" />
        )}
        {!loading && (
          <>
            {" "}
            <div className="patient">
              <div className="patient-info">
                <div className="patient-picture">
                  <Avatar alt="Remy Sharp" src={undefined} />
                </div>
                <div className="patient-data">
                  <h3>
                    {handlePatientName(infoSolicitation.medicalAppointment.patient)}
                  </h3>
                  <h4>
                    CPF:{" "}
                    {cpfMask(
                      infoSolicitation.medicalAppointment.patient.user.cpf
                    )}
                  </h4>
                </div>
              </div>
              <div className="patient-health-insurances-info">
                <span>
                  Carteirinha:{" "}
                  {
                    infoSolicitation.medicalAppointment.patient.patientHasPlans
                      .membershipCard
                  }
                </span>
              </div>
            </div>
            <div className="services">
              <div className="title">
                O paciente solicitou atendimento para o seguinte serviço:
              </div>

              <div className="service">
                <span className="name-speciality">
                  {infoSolicitation.medicalAppointment.speciality.name}
                </span>
                {infoSolicitation.medicalAppointment.establishment &&
                  infoSolicitation.medicalAppointment.establishment.clinic && (
                    <span>
                      Empresa:{" "}
                      {
                        infoSolicitation.medicalAppointment.establishment.clinic
                          .companyName
                      }
                    </span>
                  )}

                <span>
                  Profissional:{" "}
                  {infoSolicitation.medicalAppointment.provider.user.name}
                </span>
                { infoSolicitation.medicalAppointment.provider.doctor &&
                  <span>
                    CRM: {infoSolicitation.medicalAppointment.provider.doctor?.crm}
                  </span>
                }
                { infoSolicitation.medicalAppointment.provider.professionalHasProvider &&
                  <span>
                    {infoSolicitation.medicalAppointment.provider.professionalHasProvider.professionalCouncil.name}:
                    {infoSolicitation.medicalAppointment.provider.professionalHasProvider.professionalDocument}-
                    {infoSolicitation.medicalAppointment.provider.professionalHasProvider.uf}
                  </span>
                }
              </div>
              {isPossibleChangeMedicalAppointment() && (
                <div className="accept-service">
                  <div className="title-accept-terms">Aprovar serviço:</div>
                  <FormControl className={classesCheckbox.root}>
                    <FormLabel component="legend"></FormLabel>
                    <RadioGroup
                      aria-label="accept-service"
                      value={requestedService}
                      onChange={(e) => setRequestedService(e.target.value)}
                      name="accept-service"
                      data-test="accept-service"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="APPROVED"
                            control={<Radio />}
                            label="Sim"
                            name="approved"
                            data-test="approved"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="REJECTED"
                            control={<Radio />}
                            label="Não"
                            name="rejected"
                            data-test="rejected"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={classesModal.footer}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          Cancelar
        </Button>
        <Button
          disabled={loading || !isPossibleChangeMedicalAppointment()}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => handleConfirm()}
        >
          Confirmar
        </Button>
      </div>

      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
});
