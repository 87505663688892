import React, { useState, memo } from "react";

import Loading from "../../../../common/circular-progress";

import Grid from "@material-ui/core/Grid";

import Input from "../../../../common/input";
import Topic from "../../../../common/topic";

import empty from "../../../../common/validators/empty";

import cepValidator from "../../../../common/validators/cep";
import telphoneValidator from "../../../../common/validators/telephone";
import maskCep from "../../../../common/mask/cep";
import maskTelephone from "../../../../common/mask/telephone";

import { formTopic } from "./style";

export default memo((props) => {
  const [loading, setLoading] = useState(false);

  const { country, setCountry, countryError, setCountryError } = props;
  const { zipCode, setZipCode, zipCodeError, setZipCodeError } = props;
  const { address, setAddress } = props;
  const { number, setNumber, numberError, setNumberError } = props;
  const { complement, setComplement } = props;
  const {
    neighborhood,
    setNeighborhood,
    neighborhoodError,
    setNeighborhoodError,
  } = props;
  const { district, setDistrict } = props;
  const { city, setCity } = props;
  const { phone, setPhone, phoneError, setPhoneError } = props;
  const { whatsapp, setWhatsapp, whatsappError, setWhatsappError } = props;

  async function handleValidateCep() {
    setLoading(true);
    setZipCodeError({ error: false, typeError: "" });
    if (empty(zipCode).error) {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({
        error: true,
        typeError: "Esse Campo é obrigatório!",
      });
    }
    const unitsDetails = await cepValidator(zipCode);
    if (unitsDetails) {
      setAddress(unitsDetails.logradouro);
      setNeighborhood(unitsDetails.bairro);
      setDistrict(unitsDetails.uf);
      setCity(unitsDetails.localidade);
      setLoading(false);
    } else {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({ error: true, typeError: "CEP inválido" });
    }
  }

  const formTopicClasses = formTopic();
  return (
    <div>
      {loading && (
        <Loading size={80} position="absolute" height="30%" width="50%" />
      )}
      <div>
        <Topic classesNameCol={formTopicClasses.root} title="Endereço Sede da Empresa" />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9} md={3}>
          <Input
            name="input-country-address"
            datatest="input-country-address"
            width="100%"
            disabled={loading}
            label="País"
            placeholder="País"
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            onBlur={() => setCountryError(empty(country))}
            error={countryError.typeError}
          />
        </Grid>
        <Grid item xs={9} md={3}>
          <Input
            name="input-zipCode-address"
            datatest="input-zipCode-address"
            width="100%"
            disabled={loading}
            label="CEP"
            placeholder="CEP"
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(maskCep(e.target.value))}
            onBlur={() => handleValidateCep()}
            error={zipCodeError.typeError}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={9} md={5}>
          <Input
            name="input-address-address"
            datatest="input-address-address"
            width="100%"
            disabled={loading}
            label="Endereço"
            placeholder="Endereço"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={9} md={2}>
          <Input
            name="input-number-address"
            datatest="input-number-address"
            width="100%"
            disabled={loading}
            label="Número"
            placeholder="Número"
            type="text"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            onBlur={() => setNumberError(empty(number))}
            error={numberError.typeError}
          />
        </Grid>

        <Grid item xs={9} md={2}>
          <Input
            name="input-complement-address"
            datatest="input-complement-address"
            width="100%"
            disabled={loading}
            label="Complemento"
            placeholder="Complemento"
            type="text"
            value={complement}
            onChange={(e) => setComplement(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={9} md={4}>
          <Input
            name="input-neighborhood-address"
            datatest="input-neighborhood-address"
            width="100%"
            disabled={loading}
            label="Bairro"
            placeholder="Bairro"
            type="text"
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            onBlur={() => setNeighborhoodError(empty(neighborhood))}
            error={neighborhoodError.typeError}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <Input
            name="input-district-address"
            datatest="input-district-address"
            width="100%"
            disabled={loading}
            label="Estado"
            placeholder="Estado"
            type="text"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />
        </Grid>

        <Grid item xs={9} md={3}>
          <Input
            name="input-city-address"
            datatest="input-city-address"
            width="100%"
            disabled={loading}
            label="Cidade"
            placeholder="Cidade"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "50px" }}>
        <Grid item xs={9} md={3}>
          <Input
            name="input-phone-address"
            datatest="input-phone-address"
            width="100%"
            disabled={loading}
            label="Telefone"
            placeholder="Telefone"
            type="text"
            value={maskTelephone(phone)}
            onChange={(e) => setPhone(maskTelephone(e.target.value))}
            onBlur={() => setPhoneError(telphoneValidator(phone))}
            error={phoneError.typeError}
          />
        </Grid>
        <Grid item xs={9} md={3}>
          <Input
            name="input-whatsapp-address"
            datatest="input-whatsapp-address"
            width="100%"
            disabled={loading}
            label="Whatsapp"
            placeholder="Whatsapp"
            type="text"
            value={maskTelephone(whatsapp)}
            onChange={(e) => setWhatsapp(maskTelephone(e.target.value))}
            onBlur={() => setWhatsappError(telphoneValidator(whatsapp))}
            error={whatsappError.typeError}
          />
        </Grid>
      </Grid>
    </div>
  );
});
