const axios = require("axios");

export default async function (value) {
  const cep = value.replace(/[^\d]+/g, "");

  if (cep.length === 8) {
    const addressDetails = await axios.get(
      `https://viacep.com.br/ws/${cep}/json/`
    );
    if (addressDetails.data.erro) {
      return false;
    } else {
      return addressDetails.data;
    }
  } else {
    return false;
  }
}
