import React, { useState } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Pagination from "@material-ui/lab/Pagination";
import Hidden from "@material-ui/core/Hidden";

import { pagination } from "../styles/style";

export default (props) => {
  const classesPagination = pagination();
  const {
    page,
    setPage,
    limit,
    setLimit,
    showMinimumNumber,
    showMaxNumber,
    count,
    qtyPages,
  } = props;

  const [openDropDownSetLimit, setOpenDropDownSetLimit] = useState(false);

  function handleUpdateLimit(value) {
    setLimit(value);
    setPage(1);
    setOpenDropDownSetLimit(false);
  }
  return (
    <div className={classesPagination.root}>
      <div
        className="qty-items-per-page"
        onClick={() => setOpenDropDownSetLimit(!openDropDownSetLimit)}
      >
        <Hidden smDown>
          <span>
            {limit} itens{" "}
            <i>
              <ArrowDropDownIcon />
              {openDropDownSetLimit && (
                <div className="dropDown" style={{ top: "-128px" }}>
                  <div onClick={() => handleUpdateLimit(5)}>
                    <span>5</span>
                  </div>
                  <div onClick={() => handleUpdateLimit(10)}>
                    <span>10</span>
                  </div>
                  <div onClick={() => handleUpdateLimit(15)}>
                    <span>15</span>
                  </div>
                </div>
              )}
            </i>
          </span>
        </Hidden>
        <Hidden smDown>
          <span>
            Mostrando do {showMinimumNumber < count ? showMinimumNumber : count}{" "}
            ao {showMaxNumber < count ? showMaxNumber : count} de {count} itens.
          </span>
        </Hidden>
      </div>
      <div className="pagination">
        <Pagination
          count={qtyPages > 0 ? qtyPages : 1}
          shape="rounded"
          onChange={(e, value) => setPage(value)}
          page={page}
        />
      </div>
    </div>
  );
};
