import React, { useState, forwardRef } from "react";

import InformationNewUser from "./informationNewUser";
import ConfirmPassword from "./confirmPassword";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { modal } from "./style";

export default forwardRef((props, ref) => {
  const classesModal = modal();

  const [step, setStep] = useState(0);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ error: false, typeError: "" });
  const [cpf, setCpf] = useState("");
  const [cpfError, setCpfError] = useState({ error: false, typeError: "" });

  const nextStep = () => {
    setStep(step + 1);
  };
  const previousStep = () => {
    setStep(step - 1);
  };

  return (
    <div className={classesModal.root}>
      <div
        className={classesModal.title}
        id="modal-register-consultation-title"
      >
        <h1>Cadastrar Analista de Convênio</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {step === 0 && (
          <InformationNewUser
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            name={name}
            setName={setName}
            nameError={nameError}
            setNameError={setNameError}
            cpf={cpf}
            setCpf={setCpf}
            cpfError={cpfError}
            setCpfError={setCpfError}
            classesModal={classesModal}
            nextStep={nextStep}
            handleClose={props.handleClose}
          />
        )}
        {step === 1 && (
          <ConfirmPassword
            name={name}
            email={email}
            cpf={cpf}
            classesModal={classesModal}
            previousStep={previousStep}
            handleClose={props.handleClose}
            setStateSnackBar={props.setStateSnackBar}
          />
        )}
      </div>
    </div>
  );
});
