const INITIAL_STATE = {
  userStatus: "",
  userName: "",
  timeSpan: { startSelectedDate: new Date(), endSelectedDate: new Date() },
  daysWork: [],
  daysOfWeek: [],
  selectedAllDays: false,
  duration: "",
};

const scheduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_SLOT_MODAL":
      const { open, ...data } = action.payload;

      return {
        ...state,
        openSlotModal: open,
        slotModalData: { ...data },
      };
    case "ADD_USER_STATUS":
      return {
        ...state,
        userStatus: action.payload,
      };
    case "ADD_USER_NAME":
      return {
        ...state,
        userName: action.payload,
      };
    case "ADD_TIME_SPAN":
      return {
        ...state,
        timeSpan: action.payload,
      };
    case "ADD_DAYS_WORK":
      return {
        ...state,
        daysWork: action.payload,
      };

    case "ADD_DAYS_OF_WEEK":
      return {
        ...state,
        daysOfWeek: action.payload,
      };
    case "TOGGLE_SELECTED_ALL_DAYS":
      return {
        ...state,
        selectedAllDays: action.payload,
      };
    case "ADD_DURATION":
      return {
        ...state,
        duration: action.payload,
      };
    default:
      return state;
  }
};

export default scheduleReducer;
