import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditMenu from '../editMenu';

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0',
        fontWeight: '400',
  },
  item: {
        height: '64px',
        backgroundColor: "#FFFFFF",
        border: '1px solid #E7E7ED',
        padding: '0',
  },
  combo: {
    display: 'flex',
    width: '95%',
    margin: 'auto',
    height: '100%',
  },
  icons: {
    display: 'flex',
    width: '7%'
  },
  icon: {
    width: '48px'
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "88%",
    marginLeft: "2.5%",
    color: "#6B6C7E"
  },
  line: {
    height: '40px',
    width: "93%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  code: {
    width: props => props.firstColumnWidth ? props.firstColumnWidth : '60px',
  },
  name: {
    width: props => props.secondColumnWidth ? props.secondColumnWidth : '27%',
    marginLeft: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  table: {
    width: props => props.thirdColumnWidth ? props.thirdColumnWidth : '24%',
    marginLeft: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
    
  },
  price: {
    width: props => props.forthColumnWidth ? props.forthColumnWidth : '10%',
    marginLeft: '30px',
  },
  schedule: {
    width: props => props.fifthColumnWidth ? props.fifthColumnWidth : '18%',
    marginLeft: '30px',
    borderRadius: '12.5px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ".makeStyles-headers-33 > &": {
      justifyContent: "flex-start",
    }
  },
  with: {
    backgroundColor: "#F7F8F9",
    fontSize: "10px",
  },
  without: {
    backgroundColor: "#EEF2FA",
    fontSize: "10px",
    color: "#2E5AAC"
  }
}));



const ListDisplay = (props) => {

  const classes = useStyles(props);
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const deleteItem = (index) => {
    props.deleteItem(index);
  }

  return (
    <List className={classes.root}>
      <div className={classes.headers}>  
        <div className={classes.code}>  
          <span id={[props.firstHeader]}>
            {props.firstHeader}
          </span>
        </div>
        <div className={classes.name}>
          <span id={props.secondHeader}>
            {props.secondHeader} 
          </span>
        </div>
        <div className={classes.table}>
          <span id={props.thirdHeader}>
            {props.thirdHeader}
          </span>
        </div>
        <div className={classes.price}>
          <span id={props.forthHeader}>  
            {props.forthHeader}
          </span>
        </div>
        <div className={classes.schedule}>
          <span id={props.fifthHeader}> 
            {props.fifthHeader}
          </span>
        </div>
      </div>
        {props.fullList.map((value, index) => {
          const labelId = `checkbox-label-${value[props.firstParam]}-${value[props.secondParam]}-${value[props.thirdParam]}`;
          return (
            <ListItem className={classes.item} key={labelId} role={undefined} dense button onClick={handleToggle(value)}>
              <div className={classes.combo}>  
                <div className={classes.line}>  
                  <div className={classes.code}>  
                    <span id={value[props.firstParam]}>
                      {value[props.firstParam]}
                    </span>
                  </div>
                  <div className={classes.name}>
                    <span id={value[props.secondParam]}>
                      {value[props.secondParam]} 
                    </span>
                  </div>
                  <div className={classes.table}>
                    <span id={value[props.thirdParam]}>
                      {value[props.thirdParam]}
                    </span>
                  </div>
                  {props.forthParam ? 
                    <div className={classes.price}>
                      <span id={value[props.forthParam]}>  
                        R$ {value[props.forthParam]}
                      </span>
                    </div>
                    :
                    null
                  }
                  {props.fifthParam ? 
                    <div className={`${classes.schedule} ${value[props.fifthParam] === "Sim" ? classes.with : classes.without}`}>
                        <span id={value[props.fifthParam]}> 
                          {value[props.fifthParam] === "Sim" ? "COM AGENDAMENTO" : "SEM AGENDAMENTO"}
                        </span>
                    </div>
                    :
                    null
                  }
                </div>
                <div className={classes.icons}>
                  <IconButton
                    disableRipple
                    className={classes.icon} 
                    edge="end" aria-label="delete" 
                    onClick={() => deleteItem(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <EditMenu
                    items={props.menuItems}
                    index={index}
                  />
                </div>
              </div> 
            </ListItem>
          );
        })}
      </List>
  );
}

export default ListDisplay;