const INITIAL_STATE = {
  active: undefined,
  otherServices: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALTER_ACTIVE_OTHERSERVICES":
      return {
        ...state,
        active: action.payload,
      };
    case "REGISTER_OTHERSERVICES":
      return {
        ...state,
        otherServices: [...state.otherServices, action.payload],
      };
    case "DELETE_OTHERSERVICES":
      return {
        ...state,
        otherServices: state.otherServices.filter(
          (service, index) => index !== action.payload
        ),
        active: undefined,
      };

    case "UPDATE_OTHERSERVICES":
      return {
        ...state,
        otherServices: state.otherServices.map((service, index) =>
          index === action.payload ? (service = action.details) : service
        ),
      };

    case "DELETE_ALL_OTHER_SERVICES":
      return {
        ...state,
        otherServices: [],
        active: undefined,
      };
    default:
      return state;
  }
}
