import graphqlApi from './api'

export const listUnitsOfEstablishment = async () => {
  try {
    const res = await graphqlApi({
      data: {
        query: `
          query{
            listUnitsOfEstablishment{
              unit{
                id
                address
                number
                unitHasSpecialities{
                  id
                  price
                  active
                  specialities{
                    id
                    name
                    group
                  }

                }
              }
            }
          }
        `,
      }
    })

    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return res?.data?.data?.listUnitsOfEstablishment?.unit
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar buscar as unidades'}
  }
}