import React from "react";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import "./global.css";

import Routes from "./routes";

import { Provider } from "react-redux";
import store from "./common/store";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat , sans-serif"
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
