import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

import { convertToText } from "../../utils/time/convertToText";

import Slot from "../slot";
import { isSameDay } from 'date-fns'
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
});

const Day = ({
  day, 
  timeArray, 
  onlyDaysWithSchedule, 
  beginShifts, 
  duration, 
  unavailabilities,
  scheduleGroupProviderId,
  scheduleGroupEmployeeId,
  ...props
}) => {
  const classes = useStyles();
  let scheduleHasProviderId = "";
  let scheduleHasEmployeeId = "";
  let firstAvailableTime = 0;
  let daySlots = 0;
  let slotAdjustments = 0;

  const selectedScheduleInfo = onlyDaysWithSchedule.find(item => isSameDay(item.day, day))

  if (!!selectedScheduleInfo) {
    const dayData = selectedScheduleInfo
    scheduleHasProviderId = dayData.scheduleHasProviderId;
    scheduleHasEmployeeId = dayData.scheduleHasEmployeeId;
    firstAvailableTime = dayData.beginShift;
    daySlots = Math.floor((dayData.endShift - dayData.beginShift) / duration);
    slotAdjustments = Math.floor((firstAvailableTime - beginShifts) / duration);
  }

  const daySchedule = [...new Array(daySlots)].map((eachTimeSlot, index) => {
    if (selectedScheduleInfo) {
      const time = firstAvailableTime + index * duration;
      const timeStamp = convertToText(time);
      let isAnythingScheduledForThisSlot = [];
      if (unavailabilities) {
        isAnythingScheduledForThisSlot = unavailabilities.filter(
          (unavailability) => {
            const unavailabilityLocalDate = new Date(
              unavailability.dateTimeBeginUnavailabilityUtc
            );
            const unavailabilityLocalTime = format(
              unavailabilityLocalDate,
              "HH:mm"
            );
            return unavailabilityLocalTime === timeStamp;
          }
        );
      }
      return (
        <Slot
          active
          scheduleHasProviderId={scheduleHasProviderId}
          scheduleHasEmployeeId={scheduleHasEmployeeId}
          scheduleGroupProviderId={scheduleGroupProviderId}
          scheduleGroupEmployeeId={scheduleGroupEmployeeId}
          key={index}
          day={day}
          timeStamp={timeStamp}
          duration={duration}
          unavailability={isAnythingScheduledForThisSlot}
        />
      );
    } else {
      return null;
    }
  });
  for (let i = 0; i < slotAdjustments; i++) {
    daySchedule.unshift(<Slot key={Math.random()} unavailability={[]} />);
  }

  return (
    <div className={classes.root}>
      {daySchedule}
    </div>
  );
};

export default Day;
