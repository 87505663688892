import empty from "./empty";

export default (telephone) => {
  if (empty(telephone).error) {
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  } else if (telephone.length < 14) {
    return { error: true, typeError: "número inválido" };
  } else {
    return { error: false, typeError: "" };
  }
};
