import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../button";

import "../style.css";

import { modal } from "../style";

import * as addressAction from "../../store/actions/registerAddress";

export default forwardRef((props, ref) => {
  const classesModal = modal({ ...modal, maxWidth: "800px" });

  const units = useSelector((state) => state.Address.units);
  const activeAddress = useSelector((state) => state.Address.active);
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");

  function handleDeleteAddress() {
    dispatch(addressAction.deleteAddress(activeAddress));
    props.handleClose();
  }

  useEffect(() => {
    if (activeAddress >= 0) {
      setAddress(units[activeAddress].address);
    }
  }, [activeAddress, units]);

  return (
    <div className={classesModal.root}>
      <div className={classesModal.modalDelete} id="modal-delete-address-body">
        <h1>
          Deseja realmente excluir o endereço <br />
        </h1>
        <h1>
          <strong>{address} ?</strong>
        </h1>
      </div>
      <div className={classesModal.footerModalDelete}>
        <Button
          name="cancel-button-delete-address"
          datatest="cancel-button-delete-address"
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px "
          onClick={() => props.handleClose()}
        >
          Cancelar
        </Button>
        <Button
          name="button-delete-address"
          datatest="button-delete-address"
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0px"
          onClick={() => handleDeleteAddress()}
        >
          Excluir
        </Button>
      </div>
    </div>
  );
});
