import empty from "./empty";

export default function (cpf) {
  if (empty(cpf).error) {
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  }
  const cpfNumbers = cpf.replace(/\D/g, "");

  if (cpfNumbers.length < 11) {
    return { error: true, typeError: "CPF inválido" };
  }

  const nineFirtNUmbers = cpfNumbers.substr(0, 9).split("");

  if (
    cpfNumbers[0] === cpfNumbers[1] &&
    cpfNumbers[0] === cpfNumbers[2] &&
    cpfNumbers[0] === cpfNumbers[3] &&
    cpfNumbers[0] === cpfNumbers[4] &&
    cpfNumbers[0] === cpfNumbers[5] &&
    cpfNumbers[0] === cpfNumbers[6] &&
    cpfNumbers[0] === cpfNumbers[7] &&
    cpfNumbers[0] === cpfNumbers[8]
  ) {
    return { error: true, typeError: "CPF inválido" };
  }

  const mult = 10;

  let sumFirtNumber =
    11 -
    (nineFirtNUmbers
      .map((number, index) => number * (mult - index))
      .reduce((acumulador, atual) => acumulador + atual) %
      11);
  // eslint-disable-next-line
  sumFirtNumber > 9 ? (sumFirtNumber = 0) : (sumFirtNumber = sumFirtNumber);

  const cpfFirstNumber = `${nineFirtNUmbers.join("")}${sumFirtNumber}`.split(
    ""
  );

  let sumSecondNumber =
    11 -
    (cpfFirstNumber
      .map((number, index) => number * (mult + 1 - index))
      .reduce((acumulador, atual) => (acumulador += atual)) %
      11);

  sumSecondNumber > 9
    ? (sumSecondNumber = 0)
    : // eslint-disable-next-line
      (sumSecondNumber = sumSecondNumber);

  const cpfComplete = `${cpfFirstNumber.join("")}${sumSecondNumber}`;

  if (cpfComplete === cpfNumbers) {
    return { error: false, typeError: "" };
  } else {
    return { error: true, typeError: "CPF inválido" };
  }
}
