import React, { useState } from "react";

import ChooseUnits from "../common/choose-units";
import CrudConsultation from "./crud-consultation";

export default (props) => {
  const [alreadyChooseUnit, setAlreadyChooseUnit] = useState(false);
  const [unitInformations, setUnitInformations] = useState({});

  return (
    <div className="consultations">
      {!alreadyChooseUnit && (
        <ChooseUnits
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          setUnitInformations={setUnitInformations}
          title='Procedimento'
          subtitle='Selecione uma unidade para ver as consultas cadastradas'
        />
      )}
      {alreadyChooseUnit && (
        <CrudConsultation
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          unitInformations={unitInformations}
        />
      )}
    </div>
  );
};
