import React, { useState } from "react";

import CrudProfessionals from "./crud-professionals";
import ChooseUnits from "../common/choose-units";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
export default (props) => {

  const unitData = useSelector((state) => state.UnitsReducer.unitData);
  const history = useHistory()

  const anyUnitInReducers = !!history.location.state?.alreadyChooseUnit && !!unitData.id
  const [ unitInformations, setUnitInformations ] = useState(unitData)
  const [ alreadyChooseUnit, setAlreadyChooseUnit ] = useState(anyUnitInReducers);


  return (
    <div className="consultations">
      {!alreadyChooseUnit && (
        <ChooseUnits
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          setUnitInformations={setUnitInformations}
          title= 'Profissionais'
          subtitle='Selecione uma unidade para ver os profissionais cadastrados'
        />
      )}
      {alreadyChooseUnit && (
        <CrudProfessionals
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          unitInformations={unitInformations}
        />
      )}
    </div>
  );
};
