import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import apiGraphql from "../../../services/api";
import apiRest from "../../../services/restApi";

import Loading from "../../../common/circular-progress";

import SnackBar from "../../../common/snackbar";

import Box from "@material-ui/core/Box";
import Button from "../../../common/button";

import maskCpf from "../../../common/mask/cpf";

import {
  boxBackGroundAcceptTerm,
  boxContentAcceptTerm,
  boxButtons,
} from "./style";

const getTerms = () => {
  return apiGraphql({
    data: {
      query: `
        query {
          getTerms(termType: SERVICE)
        }
      `,
    },
  })
    .then((r) => {
      const result = r.data;
      if (result.errors) {
        throw new Error("Erro ao buscar os termos");
      }

      return result.data.getTerms;
    })
    .catch((err) => {
      throw new Error("Erro ao buscar os termos");
    });
};

export default (props) => {
  const date = new Date();
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const classesBoxBackgroundAcceptTerms = boxBackGroundAcceptTerm();
  const classesContentAcceptTerms = boxContentAcceptTerm();
  const classesBoxButtons = boxButtons();

  const stablishment = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj
  );
  const units = useSelector((state) => state.Address.units);

  const type = stablishment.typeStablishment;
  const stablishmentDatas = stablishment.stablishmentDatas;

  const userName = localStorage.getItem("Name-Vida-Connect");
  const userCpf = localStorage.getItem("CPF-Vida-Connect");

  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [termError, setTermError] = useState("");

  const handleRegisterStablishment = () => {
    setLoading(true);
    const formData = new FormData();
    if (type === "clinic" || type === "hospital" || type === "laboratory") {
      let plans = [];
      if (
        stablishmentDatas.healthInsurances[0].healthInsurances.plans.length > 0
      ) {
        plans = stablishmentDatas.healthInsurances
          .filter((plan) => plan.healthInsurances.id)
          .map((plan) => ({
            healthOperatorId: plan.healthInsurances.id,
            planId: plan.healthInsurances.plans[0].id,
          }));
      }

      formData.append("tradingName", stablishmentDatas.tradingName);
      formData.append("companyName", stablishmentDatas.companyName);
      formData.append("cnpj", stablishmentDatas.cnpj.replace(/\D/g, ""));
      formData.append("companyEmail", stablishmentDatas.companyEmail);
      formData.append(
        "technicianResponsibleName",
        stablishmentDatas.technicianResponsibleName
      );
      formData.append(
        "technicianResponsibleDocument",
        stablishmentDatas.technicianResponsibleDocument
      );
      formData.append(
        "legalResponsibleName",
        stablishmentDatas.legalResponsibleName
      );
      formData.append(
        "legalResponsibleDocument",
        stablishmentDatas.legalResponsibleDocument
      );

      formData.append("logo", stablishmentDatas.logo);
      formData.append("bank", stablishmentDatas.bank.value);
      formData.append("agency", stablishmentDatas.agency);
      formData.append("account", stablishmentDatas.account);
      formData.append(
        "numberOfInstallments",
        stablishmentDatas.numberOfInstallments
      );
      formData.append(
        "minimumInstallmentAmount",
        stablishmentDatas.minimumInstallmentAmount
      );
      formData.append("plans", JSON.stringify(plans));
      formData.append("socialContract", stablishmentDatas.socialContract);
      formData.append("cnpjAttachment", stablishmentDatas.cnpjAttachment);
      formData.append(
        "companyResponsibleAttachment",
        stablishmentDatas.companyResponsibleAttachment
      );
      formData.append(
        "technicianResponsibleDocumentAttachment",
        stablishmentDatas.technicianResponsibleDocumentAttachment
      );
      formData.append("proofResidence", stablishmentDatas.proofResidence);
      formData.append("careModality", stablishmentDatas.careModality);
      formData.append("units", JSON.stringify(units));
      formData.append("country", stablishmentDatas.country);
      formData.append("zipcode", stablishmentDatas.zipCode.replace(/\D/g, ""));
      formData.append("streetNumber", stablishmentDatas.number);
      formData.append("streetAddress", stablishmentDatas.address);
      formData.append("streetAddressComplement", stablishmentDatas.complement);
      formData.append("neighborhood", stablishmentDatas.neighborhood);
      formData.append("state", stablishmentDatas.district);
      formData.append("city", stablishmentDatas.city);
      formData.append(
        "telephoneNumber",
        stablishmentDatas.phone.replace(/\D/g, "")
      );

      formData.append(
        "whatsappNumber",
        stablishmentDatas.whatsapp.replace(/\D/g, "")
      );

      let i = 0;
      for (const unit of units) {
        console.log("unit for", unit);
        formData.append(`addressExams_${i}`, unit.examsSpreadsheet);
        i++;
      }
    } else if (type === "health-operator") {
      //const plans = stablishmentDatas.plans.filter((plan) => plan.name);
      const plans = [{ name: `Plano ${stablishmentDatas.tradingName}` }];

      formData.append("fantasyName", stablishmentDatas.tradingName);
      formData.append("companyName", stablishmentDatas.companyName);
      formData.append("cnpj", stablishmentDatas.cnpj.replace(/\D/g, ""));
      formData.append("email", stablishmentDatas.companyEmail);
      formData.append("ansRegistry", stablishmentDatas.ansRegistry);
      formData.append(
        "technicalManagerName",
        stablishmentDatas.technicianResponsibleName
      );
      formData.append(
        "technicalManagerDocumentNumber",
        stablishmentDatas.technicianResponsibleDocument
      );
      formData.append(
        "legalManagerName",
        stablishmentDatas.legalResponsibleName
      );
      formData.append(
        "legalManagerDocumentNumber",
        stablishmentDatas.legalResponsibleDocument
      );
      formData.append("brandLogo", stablishmentDatas.logo);

      if (type !== "health-operator") {
        formData.append("bank", stablishmentDatas.bank);
        formData.append("agency", stablishmentDatas.agency);
        formData.append("account", stablishmentDatas.account);
      }

      formData.append("plans", JSON.stringify(plans));

      formData.append("socialContract", stablishmentDatas.socialContract);
      formData.append("proofCnpj", stablishmentDatas.cnpjAttachment);
      formData.append(
        "legalManagerDocument",
        stablishmentDatas.companyResponsibleAttachment
      );
      formData.append(
        "technicalManagerDocument",
        stablishmentDatas.technicianResponsibleDocumentAttachment
      );
      formData.append("proofResidence", stablishmentDatas.proofResidence);
      formData.append("country", stablishmentDatas.country);
      formData.append("zipcode", stablishmentDatas.zipCode.replace(/\D/g, ""));
      formData.append("streetNumber", stablishmentDatas.number);
      formData.append("streetAddress", stablishmentDatas.address);
      formData.append("streetAddressComplement", stablishmentDatas.complement);
      formData.append("neighborhood", stablishmentDatas.neighborhood);
      formData.append("state", stablishmentDatas.district);
      formData.append("city", stablishmentDatas.city);
      formData.append(
        "telephoneNumber",
        stablishmentDatas.phone.replace(/\D/g, "")
      );
      formData.append(
        "whatsappNumber",
        stablishmentDatas.whatsapp.replace(/\D/g, "")
      );
    }

    apiRest
      .post(`/establishment/${type}`, formData)
      .then(() => {
        setLoading(false);
        localStorage.setItem("Status-Vida-Connect", "PENDING_APPROVAL");
        props.nextStep();
      })
      .catch((err) => {
        setLoading(false);
        const result = err.response;
        if (result) {
          const status = result.status;

          if (status === 400) {
            setStateSnackBar({
              ...stateSnackBar,
              open: true,
              message:
                result.data.message ||
                "Houve um erro de conexão com nosso servidor.Tente novamente, por favor.",
            });
          }
          return;
        }
        setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message:
            "Desculpe, houve um erro ao tentar cadastrar seu estabelecimento",
        });
      });
  };

  useEffect(() => {
    getTerms()
      .then(setTerm)
      .catch(() => setTermError("Erro ao buscar os termos"));
  }, []);

  function getMounthInPortuguese(month) {
    switch (month) {
      case 0:
        return "Janeiro";
      case 1:
        return "Fevereiro";
      case 2:
        return "Março";
      case 3:
        return "Abril";
      case 4:
        return "Maio";
      case 5:
        return "Junho";
      case 6:
        return "Julho";
      case 7:
        return "Agosto";
      case 8:
        return "Setembro";
      case 9:
        return "Outubro";
      case 10:
        return "Novembro";
      case 11:
        return "Dezembro";
      default:
        return "";
    }
  }

  return (
    <Box className={classesBoxBackgroundAcceptTerms.root}>
      {loading && <Loading size={80} />}
      <Box className={classesContentAcceptTerms.root}>
        <h1>Formulário de solicitação de cadastro</h1>
        <p>
          {stablishmentDatas.tradingName}, pessoa jurídica de direito privado,
          inscrita no CNPJ sob o nº {stablishmentDatas.cnpj}, com sede na{" "}
          {stablishmentDatas.address}, neste ato representada por seu (sua)
          Representante Legal, ao final assinados, vem solicitar o seu
          cadastramento na Plataforma Vida Connect, declarando expressamente que
          são verdadeiras todas as informações inseridas no sistema.
          <br />
          Declara ainda que teve acesso, leu e concorda com todas as regras e
          condições de uso da Plataforma Vida Connect. <br /> <br />
          {stablishmentDatas.city} - {stablishmentDatas.district},{" "}
          {date.getDate()} de {getMounthInPortuguese(date.getMonth())} de{" "}
          {date.getFullYear()}
          <span className="accept-terms-box-signatures">
            <span className="accept-terms-signatures">
              <span>{userName}</span>
              <span>{maskCpf(userCpf)}</span>
              <span className="accept-terms-responsible">
                Representante Legal
              </span>
            </span>

            <span className="accept-terms-signatures">
              <span>{stablishmentDatas.technicianResponsibleName}</span>
              <span>{stablishmentDatas.technicianResponsibleDocument}</span>
              <span className="accept-terms-responsible">
                Diretor /Responsável Técnico
              </span>
            </span>
          </span>
        </p>
      </Box>
      <Box className={classesContentAcceptTerms.root}>
        {/* <h1>Contrato de prestação de serviços</h1> */}
        {Boolean(term) && (
          <iframe
            id="iframe"
            height="600"
            title="Contrato de prestação de serviços"
            src={term}
          ></iframe>
        )}
        {Boolean(termError) && <h2>Erro oa buscar os termos</h2>}
      </Box>

      <Box className={classesBoxButtons.root} style={{ marginBottom: "30px" }}>
        <Button
          onClick={() => {
            const f = window.frames["iframe"];
            f.contentWindow.print();
          }}
          width="120px"
          border="1px solid #CDCED9"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          margin="0 20px 0 0"
          data-test="button-termo-de-consentimento-voltar"
        >
          Imprimir
        </Button>
        <Button
          name="button-back-accept-terms"
          datatest="button-back-accept-terms"
          margin="0 30px 0 0"
          disabled={loading}
          backgroundColor="#F7F8F9"
          border="1px solid #CDCED9"
          color="#272833"
          width="155px"
          onClick={() => props.previousStep()}
        >
          Voltar
        </Button>
        <Button
          name="button-accept-terms"
          datatest="button-accept-terms"
          disabled={loading || Boolean(termError)}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="155px"
          onClick={() => handleRegisterStablishment()}
        >
          Aceitar
        </Button>
      </Box>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </Box>
  );
};
