import React, { useState, useEffect, useCallback } from "react";
import apiGraphql from "../../../services/api";

import Loading from "../../../common/circular-progress";

import ModalExportFile from "./modal";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";

import DatePicker from "../../../common/datepicker";
import Button from "../../../common/button";

import currencyMask from "../../../common/mask/currency";

import SnackBar from "../../../common/snackbar";

import { laytoutPageHealthOperatorReports } from "./style";

export default (props) => {
  const classesReports = laytoutPageHealthOperatorReports();
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [openModal, setOpenModal] = useState(false);

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [listServices, setListServices] = useState([]);

  const getReport = useCallback(() => {
    const healthOperatorId = localStorage.getItem("healthOperatorId");
    if (toDate !== null) {
      setLoading(true);
      apiGraphql({
        data: {
          query: `
            query{
              reportHealthOperatorRequests(healthOperatorId:"${healthOperatorId}", initialDate:"${fromDate}", finalDate:"${toDate}"){
                patient
                membershipCard
                plan
                status
                price
                tax
                createdAt
              }
            }
          `,
        }
      })
        .then((res) => {
          setLoading(false);
          setListServices(res.data.data.reportHealthOperatorRequests);
        })
        .catch((err) => {
          setLoading(false);
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            message:
              "Houve um erro ao buscar os dados, tente novamente mais tarde! ",
          }));
          console.log(err);
        });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  const statusService = (status) => {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "REJECTED":
        return "Reprovado";
      case "APPROVED":
        return "Realizado";
      case "DISABLED":
        return "Desabilitado";
      case "CANCELED":
        return "Cancelado";
      default:
        return "";
    }
  };
  const dateService = (date) => {
    date = new Date(date);
    const day = ("0" + date.getDay()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className={classesReports.root}>
      <div className="header">
        <div className="title">
          <h1>Relatório de Serviços</h1>
        </div>
        <div className="date-range">
          <div className="select-date">
            <span className="label">De:</span>
            <DatePicker
              value={fromDate}
              onChange={(date) => {
                setFromDate(date);
                setToDate(null);
              }}
              animateYearScrolling
            />
          </div>
          <div className="select-date">
            <span className="label">Até:</span>
            <DatePicker
              value={toDate}
              onChange={(date) => {
                date.setMinutes(date.getMinutes() + 1);

                if (date >= fromDate) {
                  setToDate(date);
                } else {
                  setStateSnackBar({
                    ...stateSnackBar,
                    open: true,
                    message:
                      "A data final deve ser maior ou igual à data inicial!",
                  });
                }
              }}
              animateYearScrolling
            />
          </div>
        </div>
        <div className="export">
          <Button
            backgroundColor="#FFF"
            border="1px solid #CDCED9"
            color="#6B6C7E"
            width="30vw"
            maxWidth="195px"
            onClick={() => handleOpenModal()}
          >
            Exportar dados
          </Button>
        </div>
      </div>
      <div className="list-services">
        {loading && <Loading size={80} />}
        {!loading && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paciente</TableCell>
                  <TableCell>Carteirinha</TableCell>
                  <TableCell>Convênio</TableCell>
                  <TableCell>Serviço Solicitado</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>V. do Serviço</TableCell>
                  <TableCell>Taxa V.C.</TableCell>
                  <TableCell>Solicitado em</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {listServices.map((service, index) => (
                  <TableRow key={index}>
                    <TableCell>{service.patient}</TableCell>
                    <TableCell>{service.membershipCard}</TableCell>
                    <TableCell>{service.plan}</TableCell>
                    <TableCell>Consulta</TableCell>
                    <TableCell>{statusService(service.status)}</TableCell>
                    <TableCell>
                      R$ {currencyMask(service.price.toFixed(2))}
                    </TableCell>
                    <TableCell>
                      R$ {currencyMask(service.tax.toFixed(2))}
                    </TableCell>
                    <TableCell>{dateService(service.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalExportFile handleClose={handleCloseModal} report={listServices} />
      </Modal>

      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
