import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EstablishmentType from "./establishmentType";
import EstablishmentData from "./establishmentData";
import RegisterAddress from "../common/register-address/registerAddress";
import AcceptTerms from "./acceptTerms";
import AwaitValidation from "./awaitValidation";

import "./style.css";

export default () => {
  const typeEstablishment = localStorage.getItem("Type-Vida-Connect");
  const status = localStorage.getItem("Status-Vida-Connect");

  const type = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj.typeStablishment
  );
  // TODO: retornar para useState(0)
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  
  useEffect(() => {
    if (status === "PENDING_APPROVAL") {
      setActiveStep(4);
    } else if (typeEstablishment === "COMPANY_CLINIC" && status === "OK") {
      history.push("/administrador/profissionais");
    } else if (
      typeEstablishment === "COMPANY_HEALTH_OPERATOR" &&
      status === "OK"
    ) {
      history.push("/operadora-de-saude/solicitacao");
    } else if (typeEstablishment === null) {
      localStorage.clear();
      history.push("/");
    }
  }, [status, typeEstablishment, history]);

  const handleNext = () => {
    if (type === "health-operator" && activeStep === 1)
      return setActiveStep(activeStep + 2);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (type === "health-operator" && activeStep === 3)
      return setActiveStep(activeStep - 2);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  switch (activeStep) {
    case 1:
      return (
        <EstablishmentData
          previousStep={handleBack}
          nextStep={handleNext}
          activeStep={activeStep}
          type={type}
        />
      );
    case 2:
      return (
        <RegisterAddress
          previousStep={handleBack}
          nextStep={handleNext}
          activeStep={activeStep}
        />
      );
    case 3:
      return (
        <AcceptTerms
          previousStep={handleBack}
          nextStep={handleNext}
          activeStep={activeStep}
        />
      );
    case 4:
      return <AwaitValidation />;
    default:
      return (
        <EstablishmentType nextStep={handleNext} activeStep={activeStep} />
      );
  }
};
