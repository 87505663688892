import empty from "./empty";

export default function (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (empty(cnpj).error) {
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  }

  if (cnpj.length !== 14) return { error: true, typeError: "CNPJ inválido" };

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return { error: true, typeError: "CNPJ inválido" };

  // Valida DVs
  let cnpjLength = cnpj.length - 2;
  let numbers = cnpj.substring(0, cnpjLength);
  let digits = cnpj.substring(cnpjLength);
  let sum = 0;
  let pos = cnpjLength - 7;
  for (let i = cnpjLength; i >= 1; i--) {
    sum += numbers.charAt(cnpjLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  // eslint-disable-next-line
  if (result != digits.charAt(0))
    return { error: true, typeError: "CNPJ inválido" };

  cnpjLength = cnpjLength + 1;
  numbers = cnpj.substring(0, cnpjLength);
  sum = 0;
  pos = cnpjLength - 7;
  for (let i = cnpjLength; i >= 1; i--) {
    sum += numbers.charAt(cnpjLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  // eslint-disable-next-line
  if (result != digits.charAt(1))
    return { error: true, typeError: "CNPJ inválido" };

  return { error: false, typeError: "" };
}
