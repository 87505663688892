import graphqlApi from './api'

export const confirmProcedureDate = async (procedureRequestId, date) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `
          mutation confirmProcedureDate($input: ConfirmProcedureDateInput!){    
            confirmProcedureDate(input: $input)
          }
        `,
        variables: {input: { procedureRequestId, date } }
      }
    })

    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    }

    else { 
      return res.data.data.confirmProcedureDate
    }
  } catch (error) {

  }
}