import React, { useState, useEffect } from 'react'
import { Modal } from '@material-ui/core'

import RegisterConsultation from '../../../../../common/modal/consultation/registerConsultation'
import DeleteAndUpdateConsultation from '../../../../../common/modal/consultation/deleteAndUpdateConsultations'
import * as specialtiesService from '../../../../../services/specialties'

export const ConsultationModal = ({
  openModalRegisterConsultation,
  handleCloseModalRegisterConsultation,
  openModalDeleteAndUpdateConsultation,
  handleCloseModalDeleteAndUpdateConsultation
}) => {

  const [ listConsultations, setListConsultations ] = useState([])

  useEffect(() => {
    const consultations = specialtiesService.listSpecialties()
    setListConsultations(consultations)
  }, [])

  return (
    <>
      <Modal
        open={openModalRegisterConsultation}
        onClose={handleCloseModalRegisterConsultation}
        aria-labelledby="modal-register-consultation-title"
        aria-describedby="modal-register-consultation-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RegisterConsultation
          handleClose={handleCloseModalRegisterConsultation}
          // ref={modalRegisterConsultationRef}
          listSpecialities={listConsultations}
        />
      </Modal>
      <Modal
        open={openModalDeleteAndUpdateConsultation}
        onClose={handleCloseModalDeleteAndUpdateConsultation}
        aria-labelledby="modal-alter-consultation-title"
        aria-describedby="modal-alter-consultation-body"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DeleteAndUpdateConsultation
          handleClose={handleCloseModalDeleteAndUpdateConsultation}
          // ref={modalDeleteAndUpdateConsultationRef}
          listSpecialities={listConsultations}
        />
      </Modal>
    </>
  )
}