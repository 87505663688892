import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Input from "../../input";
import Button from "../../button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

import empty from "../../validators/empty";
import cepValidator from "../../validators/cep";
import telphoneValidator from "../../validators/telephone";
import maskCep from "../../mask/cep";
import maskTelephone from "../../mask/telephone";

import SnackBar from "../../snackbar";

import Loading from "../../circular-progress";

import "../style.css";

import { modal } from "../style";

import * as addressAction from "../../store/actions/registerAddress";

export default forwardRef((props, ref) => {
  const classesModal = modal({ ...modal, maxWidth: "800px" });

  const units = useSelector((state) => state.Address.units);
  const activeAddress = useSelector((state) => state.Address.active);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [cnes, setCnes] = useState("");
  const [cnesError, setCnesError] = useState({ error: true, typeError: "" });

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState({
    error: true,
    typeError: "",
  });

  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState({
    error: false,
    typeError: "",
  });

  const [address, setAddress] = useState("");

  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");

  const [neighborhood, setNeighborhood] = useState("");
  const [neighborhoodError, setNeighborhoodError] = useState({
    error: true,
    typeError: "",
  });

  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState({
    error: true,
    typeError: "",
  });

  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState({
    error: true,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  useEffect(() => {
    setCnesError({ ...cnesError, error: empty(cnes).error });
    setCountryError({ ...countryError, error: empty(country).error });
    setPhoneError({
      ...phoneError,
      error: telphoneValidator(phone).error,
    });
    setWhatsappError({
      ...whatsappError,
      error: telphoneValidator(whatsapp).error,
    });
    setNeighborhoodError({
      ...neighborhoodError,
      error: empty(neighborhood).error,
    });
    //eslint-disable-next-line
  }, [cnes, country, number, complement, phone, whatsapp, neighborhood]);

  useEffect(() => {
    if (activeAddress >= 0) {
      setCnes(units[activeAddress].cnes);
      setCountry(units[activeAddress].country);
      setZipCode(units[activeAddress].zipCode);
      setAddress(units[activeAddress].address);
      setNumber(units[activeAddress].number);
      setComplement(units[activeAddress].complement);
      setNeighborhood(units[activeAddress].neighborhood);
      setDistrict(units[activeAddress].district);
      setCity(units[activeAddress].city);
      setPhone(maskTelephone(units[activeAddress].phone));
      setWhatsapp(maskTelephone(units[activeAddress].whatsapp));
    }
  }, [activeAddress, units]);

  useEffect(() => {
    const ac = new AbortController();
    async function validateCep() {
      await handleValidateCep();
    }
    if (zipCode === 9) {
      validateCep();
    }
    return () => ac.abort();
    //eslint-disable-next-line
  }, [zipCode]);

  async function handleValidateCep() {
    setLoading(true);
    setZipCodeError("");
    if (empty(zipCode).error) {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({
        error: true,
        typeError: "Esse Campo é obrigatório!",
      });
    }
    const unitsDetails = await cepValidator(zipCode);
    if (unitsDetails) {
      setAddress(unitsDetails.logradouro);
      setNeighborhood(unitsDetails.bairro);
      setNeighborhoodError({
        error: false,
        typeError: "",
      });
      setDistrict(unitsDetails.uf);
      setCity(unitsDetails.localidade);
      setLoading(false);
      return {
        error: false,
        typeError: "",
      };
    } else {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({ error: true, typeError: "CEP inválido" });
    }
  }

  function checkRegisteredAddresses() {
    const unitAlreadyRegistered = units.some(
      (unit) =>
        unit.zipCode === zipCode.replace(/\D/g, "") &&
        unit.number === number &&
        unit.complement === complement
    );
    if (unitAlreadyRegistered) {
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Este endereço ja foi cadastrado!",
      });
    }
    return unitAlreadyRegistered;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setCnesError(empty(cnes));
    setCountryError(empty(country));
    setNeighborhoodError(empty(neighborhood));
    setPhoneError(telphoneValidator(phone));
    setWhatsappError(telphoneValidator(whatsapp));

    const unitAlreadyRegistered = checkRegisteredAddresses();

    if (
      !(
        cnesError.error ||
        countryError.error ||
        neighborhoodError.error ||
        phoneError.error ||
        whatsappError.error ||
        unitAlreadyRegistered
      )
    ) {
      const phoneWithoutString = phone.replace(/\D/g, "");
      const whatsappWithoutString = whatsapp.replace(/\D/g, "");
      const zipCodeWithoutString = zipCode.replace(/\D/g, "");
      const payload = {
        cnes,
        country,
        zipCode: zipCodeWithoutString,
        address,
        number,
        complement,
        neighborhood,
        district,
        city,
        phone: phoneWithoutString,
        whatsapp: whatsappWithoutString,
        specialities: units[activeAddress].specialities,
        //exams: units[activeAddress].exams,
        //proceedings: units[activeAddress].proceedings,
        //otherServices: units[activeAddress].otherServices,
      };

      dispatch(addressAction.updateAddress(payload, activeAddress));
      props.handleClose();
    }
  };

  return (
    <div className={classesModal.root}>
      {loading && <Loading height="70vh" width="50vw" size={60} />}
      <div className={classesModal.title} id="modal-update-address-title">
        <h1>Alterar Endereço</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classesModal.body} id="modal-update-address-body">
        <Input
          name="input-cnes-update-address"
          datatest="input-cnes-update-address"
          width="90%"
          disabled={loading}
          label="CNES"
          placeholder="CNES"
          type="text"
          value={cnes}
          onChange={(e) => setCnes(e.target.value)}
          onBlur={() => setCnesError(empty(cnes))}
          error={cnesError.typeError}
        />
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Input
              name="input-country-update-address"
              datatest="input-country-update-address"
              width="90%"
              disabled={loading}
              label="Pais"
              placeholder="Pais"
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              onBlur={() => setCountryError(empty(country))}
              error={countryError.typeError}
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              name="input-zipCode-update-address"
              datatest="input-zipCode-update-address"
              width="90%"
              disabled={loading}
              label="CEP"
              placeholder="CEP"
              type="text"
              value={maskCep(zipCode)}
              onChange={(e) => setZipCode(maskCep(e.target.value))}
              onBlur={() => handleValidateCep()}
              error={zipCodeError.typeError}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Input
              name="input-address-update-address"
              datatest="input-address-update-address"
              width="90%"
              disabled={true}
              label="Endereço"
              placeholder="Endereço"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-number-update-address"
              datatest="input-number-update-address"
              width="90%"
              disabled={loading}
              label="Número"
              placeholder="Núm."
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-complement-update-address"
              datatest="input-complement-update-address"
              width="80%"
              disabled={loading}
              label="Complemento"
              placeholder="Compl."
              type="text"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Input
              name="input-neighborhood-update-address"
              datatest="input-neighborhood-update-address"
              width="90%"
              disabled={true}
              label="Bairro"
              placeholder="Bairro"
              type="text"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              error={neighborhoodError.typeError}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-district-update-address"
              datatest="input-district-update-address"
              width="90%"
              disabled={true}
              label="Estado"
              placeholder="UF"
              type="text"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name="input-city-update-address"
              datatest="input-city-update-address"
              width="100%"
              disabled={true}
              label="Cidade"
              placeholder="Cidade"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Input
              name="input-phone-update-address"
              datatest="input-phone-update-address"
              width="90%"
              disabled={loading}
              label="Telefone"
              placeholder="Telefone"
              type="text"
              value={maskTelephone(phone)}
              onChange={(e) => setPhone(maskTelephone(e.target.value))}
              onBlur={() => setPhoneError(telphoneValidator(phone))}
              error={phoneError.typeError}
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              name="input-whatsapp-update-address"
              datatest="input-whatsapp-update-address"
              width="90%"
              disabled={loading}
              label="Whatsapp"
              placeholder="Whatsapp"
              type="text"
              value={maskTelephone(whatsapp)}
              onChange={(e) => setWhatsapp(maskTelephone(e.target.value))}
              onBlur={() => setWhatsappError(telphoneValidator(whatsapp))}
              error={whatsappError.typeError}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classesModal.footer}>
        <Button
          name="button-cancel-update-address"
          datatest="button-cancel-update-address"
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          name="button-update-address"
          datatest="button-update-address"
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={(e) => handleSubmit(e)}
          disabled={loading}
        >
          Alterar
        </Button>
      </div>

      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
});
