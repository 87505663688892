import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";

import Autocomplete from "@material-ui/lab/Autocomplete";
import listBank from "./listBank";

import empty from "../../../../common/validators/empty";

import { formTopic } from "./style";

import VMasker from 'vanilla-masker'

export default memo((props) => {
  const formTopicClasses = formTopic();

  const { bank, setBank, bankError, setBankError } = props;
  const { agency, setAgency, agencyError, setAgencyError } = props;
  const { account, setAccount, accountError, setAccountError } = props;

  const getMask = (bank) => {
    const object = listBank.find(b => b.value === bank)
    if (!object) return { agency: 'SSSSSSSSSSSSSSS', account: 'SSSSSSSSSSSSSSS-S' }
    return object.mask
  }

  const maskAccount = (value) => {
    if (value.length < 2) return value
    return value.replace(/[^A-Za-z0-9]/g, "").replace(/(.*)(.)/g ,'$1-$2')
  }

  const validateAgency = (value, length) => {
    const _empty = empty(value)
    if (_empty.error) return _empty
    return value.length !== length ? { error: true, typeError: "Número da agência inválido" } : { error: false, typeError: "" }
  }

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Dados Bancários"
          subtitle="Informe os seus dados bancários para o recebimento dos serviços prestados."
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9} md={3}>
          <Autocomplete
            id="combo-box-bank"
            options={listBank}
            getOptionLabel={(bank) => bank.name || ''}
            getOptionSelected={(bank) => bank.value}
            value={bank}
            onChange={(e, newValue) =>
              newValue ? setBank(newValue) : setBank("")
            }
            renderInput={(params) => (
              <Input
                name="input-bank-bankData"
                datatest="input-bank-bankData"
                width="100%"
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                label="Banco"
                placeholder="Banco"
                type="text"
                onBlur={() => setBankError(empty(bank))}
                error={bankError.typeError}
              />
            )}
          />
        </Grid>
        <Grid item xs={7} md={1}>
          <Input
            name="input-agency-bankData"
            datatest="input-agency-bankData"
            width="100%"
            label="Agencia"
            placeholder="Agencia"
            type="text"
            value={agency}
            onChange={(e) => setAgency(VMasker.toPattern(e.target.value, getMask(bank.value).agency))}
            onBlur={() => setAgencyError(validateAgency(agency, getMask(bank.value).agency.length))}
            error={agencyError.typeError}
          />
        </Grid>
        <Grid item xs={8} md={2}>
          <Input
            name="input-account-bankData"
            datatest="input-account-bankData"
            width="100%"
            label="Conta"
            placeholder="Conta"
            type="text"
            value={account}
            inputProps={{ maxLength: getMask(bank.value).account.length }}
            onChange={(e) => setAccount(maskAccount(e.target.value))}
            onBlur={() => setAccountError(empty(account))}
            error={accountError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
