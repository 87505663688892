import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
import { format, differenceInMinutes } from "date-fns";
import Pagination from "../../../../common/pagination";

import apiGraphql from "../../../../services/api";

import { handlePatientName } from "../../../../common/handlePatientName";
const useStyles = makeStyles({
  fullComponent: {
    padding: "4vh 0 7vh 0",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "& > .text": {
      background: "#F7F8F9",
      border: "1px solid #E7E7ED",
      width: "100%",
      boxSizing: "border-box",
    },
    "& > .text > p": {
      marginLeft: "30px",
    },
  },
  status: {
    "& .ONLINE_PENDING_TRIAGE": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .ONLINE_PENDING_HEALTH_OPERATOR": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .ONLINE_OK": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .ONLINE_OK_OUT_OF_HOURS": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .ONLINE_PENDING_TOKEN_VALIDATION": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .ONLINE_VALIDATED_TOKEN": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .ONLINE_REJECTED_TRIAGE": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .ONLINE_REJECTED_HEALTH_OPERATOR": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .ONLINE_START_CONSULTATION": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .ONLINE_PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    },
    "& .PRESENTIAL_PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    },
    "& .PRESENTIAL_PENDING_TRIAGE": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .PRESENTIAL_PENDING_HEALTH_OPERATOR": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .PRESENTIAL_OK": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .PRESENTIAL_PENDING_TOKEN_VALIDATION": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .PRESENTIAL_VALIDATED_TOKEN": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .PRESENTIAL_REJECTED_TRIAGE": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .PRESENTIAL_REJECTED_HEALTH_OPERATOR": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .PRESENTIAL_START_CONSULTATION": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
  },
});

const PatientsList = ({ patients, title, ...props }) => {
  const classes = useStyles();
  const { employeeId, unitId } = props;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showMinimumNumber, setShowMinimumNumber] = useState(0);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [qtyPages, setQtyPages] = useState(0);
  const [medicalAppointments, setMedicalAppointments] = useState([]);
  function getMedicalAppointmentsPerRequest() {
    apiGraphql({
      data: {
        query: `query {
          getEmployeeMedicalAppointmentsPerRequest(
            input: { 
              unitId: "${unitId}",
              employeeId: "${employeeId}"
              limit: ${Number(limit)},
              page: ${Number(page)}
            }){
            count
            rows{
              id
            status
            dateTimeBeginAppointmentUtc
            beginAppointment
            appointmentLocation
            doctorHasAttended
            patient{
              id
              socialName
              user {name}
            }
            }
          }
        } `,
      },
    }).then((res) => {
      setMedicalAppointments(
        res.data.data.getEmployeeMedicalAppointmentsPerRequest.rows
      );
      const count =
        res.data.data.getEmployeeMedicalAppointmentsPerRequest.count;
      setCount(count);

      setShowMinimumNumber(limit * (page - 1) + 1);
      setShowMaxNumber(limit * page);
      setQtyPages(
        count % limit === 0
          ? Math.floor(count / limit)
          : Math.floor(count / limit) + 1
      );
    });
  }

  useEffect(() => {
    getMedicalAppointmentsPerRequest();
    // eslint-disable-next-line
  }, [unitId, employeeId, page, limit]);

  const RenderChip = ({ status, appointmentLocation, datetime }) => {
    const diffInMinutes = differenceInMinutes(new Date(datetime), new Date());

    const statusConversion = {
      OK:
        appointmentLocation === "ONLINE" && diffInMinutes <= 15
          ? "Iniciar atendimento"
          : "Aguardando consulta",
      PENDING_TRIAGE: "Triagem",
      PENDING_HEALTH_OPERATOR: "Aguardando operadora de saúde",
      REJECTED_TRIAGE: "Triagem recusada",
      REJECTED_HEALTH_OPERATOR: "Triagem recusada",
      CANCELED: "Consulta cancelada",
      PENDING_TOKEN_VALIDATION: "Aguardando paciente",
      PENDING_PAYMENT: "Aguardando pagamento",
      START_CONSULTATION: "Iniciar atendimento",
      VALIDATED_TOKEN: "Token validado",
    };

    const label = statusConversion[status];
    let _class = `${appointmentLocation}_${status}`;

    if (
      label === "Aguardando consulta" &&
      appointmentLocation === "ONLINE" &&
      diffInMinutes > 15
    )
      _class += "_OUT_OF_HOURS";

    return (
      <Chip
        size="small"
        label={statusConversion[status]}
        className={`${_class} box`}
      />
    );
  };

  const medicalAppointmentsFormated = medicalAppointments.map((item) => ({
    ...item,
    patientName: handlePatientName(item.patient),
    dateFormated: format(
      new Date(item?.dateTimeBeginAppointmentUtc || "2020-01-01"),
      "dd/MM/yyyy - HH:mm"
    ),
  }));

  return (
    <div className={classes.fullComponent}>
      {!medicalAppointmentsFormated.length ? (
        <Alert severity="info">
          Não encontramos nenhuma consulta agendada por solicitação
        </Alert>
      ) : (
        <>
          <div className={classes.list}>
            <TableContainer>
              {!!medicalAppointmentsFormated.length && (
                <Alert severity="info">
                  Pacientes agendados por solicitação
                </Alert>
              )}

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Paciente</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Atendimento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicalAppointmentsFormated.map(
                    (medicalAppointment, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {handlePatientName(medicalAppointment.patient)}
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(
                              medicalAppointment.dateTimeBeginAppointmentUtc
                            ),
                            "dd/MM/yyyy - HH:mm"
                          )}
                        </TableCell>

                        <TableCell className={classes.status}>
                          <RenderChip
                            status={medicalAppointment.status}
                            appointmentLocation={
                              medicalAppointment.appointmentLocation
                            }
                            datetime={
                              medicalAppointment.dateTimeBeginAppointmentUtc
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={
                              medicalAppointment.appointmentLocation ===
                                "ONLINE"
                                ? "ONLINE"
                                : "PRESENCIAL"
                            }
                            size="small"
                            variant="outlined"
                            style={{ fontSize: ".7rem" }}
                          ></Chip>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>

                <TableFooter>
                  <TableRow></TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            showMinimumNumber={showMinimumNumber}
            showMaxNumber={showMaxNumber}
            count={count}
            qtyPages={qtyPages}
          />
        </>
      )}
    </div>
  );
};

export default PatientsList;
