import graphqlApi from './api'

export const getTaxesByTypes = async () => {
  try {
    const res = await graphqlApi({
      data: {
        query: `
          query{
            getTaxesByTypes (type:[APPOINTMENT_FEE_PATIENT,APPOINTMENT_FEE_PROVIDER]) {
              type
              value
            }
          }
    
        `,
      }
    })

    const taxAppointmentFeeProvider = res.data.data.getTaxesByTypes.filter(tax => 
      tax.type === 'APPOINTMENT_FEE_PROVIDER')[0].value
    const taxAppointmentFeePatient = res.data.data.getTaxesByTypes.filter(tax => 
      tax.type === 'APPOINTMENT_FEE_PATIENT')[0].value

    return { taxAppointmentFeeProvider, taxAppointmentFeePatient }
  } catch (error) {
    return { error: true, message: 'Ocorreum um erro ao tentar buscar o valor da taxa.' }
  }

}
