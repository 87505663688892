import { makeStyles } from "@material-ui/core/styles";

const modal = makeStyles({
  root: {
    position: "absolute",
    marginBottom: "16px",
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: "10px",
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : "474px"),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    height: "68px",
    alignItems: "center",
    borderBottom: "1px solid #E7E7ED",
    "& h1": {
      fontWeight: 600,
      fontSize: "1.28rem",
      color: "#272833",
      margin: "0 0 0 24px",
      padding: "0",
    },
  },
  body: {
    marginLeft: "24px",
    minHeight: "325px",
    maxHeight: "70vh",
    overflowY: "auto",
    marginBottom: "30px",
    "& div.patient": {
      display: "flex",
      flexDirection: "column",
      "& div.patient-info": {
        marginTop: "26px",
        display: "flex",
        "& .MuiAvatar-root": {
          height: "48px",
          width: "48px",
        },
        "& div.patient-data": {
          marginLeft: "13px",
          display: "flex",
          flexDirection: "column",
          "& h3": {
            margin: 0,
            fontSize: "1.28rem",
            fontWeight: "normal",
            color: "#272833",
          },
          "& h4": {
            margin: "4px 0 0 0",
            fontWeight: "normal",
            color: "#6B6C7E",
          },
        },
      },
      "& div.patient-health-insurances-info": {
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        "& span": {
          color: "#272833",
          marginTop: "5px",
        },
      },
    },
    "& div.services": {
      marginTop: "20px",
      "& div.title": {
        marginBottom: "25px",
        color: "#272833",
        fontWeight: 600,
      },
      "& div.service": {
        display: "flex",
        flexDirection: "column",
        "& span.name-speciality": {
          marginBottom: "8px",
          color: "#393A4A",
          fontWeight: 600,
        },
        "& span": {
          color: "#272833",
          marginBottom: "5px",
        },
      },
    },
    "& div.accept-service": {
      marginTop: "16px",
      "& div.title-accept-terms": {
        marginBottom: "8px",
        color: "#272833",
        fontWeight: 600,
      },
    },
  },

  footer: {
    marginTop: "20px",
    minHeight: "83px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: " 0px 0px 10px 10px;",
    backgroundColor: "#F7F8F9",
  },
});

export { modal };
