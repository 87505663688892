import React, { useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import Analysts from "./analysts";

import { layoutPageAdmin } from "./style";

export default (props) => {
  const perfil = props.computedMatch.params.perfil;
  const classesLayoutPageAdmin = layoutPageAdmin();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const linksMenu = () => {
    return (
      <Grid className={classesLayoutPageAdmin.sidebar} item md={2}>
        <Link to="/operadora-de-saude/administrador/analistas">
          <div
            className={perfil === "analistas" ? "active" : ""}
            onClick={() => handleDrawerToggle()}
          >
            Analistas de Convênio
          </div>
        </Link>
      </Grid>
    );
  };

  return (
    <div>
      <Grid container>
        <Hidden smDown>{linksMenu()}</Hidden>
        <Hidden mdUp>
          <Drawer
            classes={{ paper: classesLayoutPageAdmin.drawer }}
            variant="temporary"
            open={openDrawer}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {linksMenu()}
          </Drawer>
        </Hidden>
        <Grid className={classesLayoutPageAdmin.content} item xs={12} md={10}>
          <>
            <Hidden mdUp>
              <div className="menu" onClick={() => handleDrawerToggle()}>
                <MenuOpenIcon />
              </div>
            </Hidden>
            {perfil === "analistas" && <Analysts />}
          </>
        </Grid>
      </Grid>
    </div>
  );
};
