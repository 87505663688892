import { combineReducers } from "redux";

import RegisterConsultations from "./registerConsultations";
import Exams from "./exams";
import Proceedings from "./proceeding";
import OtherServices from "./otherServices";
import Address from "./address";
import PersonalDatas from "./personalDatas";
import ScheduleReducer from "./schedule.reducer";
import UnitsReducer from './units.reducer'
export default combineReducers({
  RegisterConsultations,
  Exams,
  Proceedings,
  OtherServices,
  Address,
  PersonalDatas,
  ScheduleReducer,
  UnitsReducer
});
