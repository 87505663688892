export function personalDatasTypeAccount(payload) {
  return {
    type: "INSERT_TYPE_ACCOUNT_INITIAL_REGISTER",
    payload,
  };
}

export function personalDatas(payload) {
  return {
    type: "UPDATE_INITIAL_REGITER",
    payload,
  };
}

export function personalDatasPassword(payload) {
  return {
    type: "INSERT_PASSWORD_INITIAL_REGISTER",
    payload,
  };
}

export function stablishmentTypePj(payload) {
  return {
    type: "UPDATE_PJ_REGITER_TYPE",
    payload,
  };
}

export function personalDatasPj(payload) {
  return {
    type: "UPDATE_PJ_REGITER_DATA",
    payload,
  };
}

export function personalDatasPf(payload) {
  return {
    type: "UPDATE_PF_REGITER_DATA",
    payload,
  };
}

export function curriculumDatasPf(payload) {
  return {
    type: "UPDATE_PF_REGITER_CURRICULUM",
    payload,
  };
}
