const ProcedureRequestStatusEnum = {
  BUDGET_SAVED: 'BUDGET_SAVED',
  AWAITING_BUDGET: 'AWAITING_BUDGET',
  CHOOSE_A_BUDGET: 'CHOOSE_A_BUDGET',
  AWAITING_DATE: 'AWAITING_DATE',
  CONFIRM_SCHEDULE: 'CONFIRM_SCHEDULE',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  FINISHED: 'FINISHED',
  OK: 'OK',
}
Object.freeze(ProcedureRequestStatusEnum)
export { ProcedureRequestStatusEnum }
