import React, { useState, useEffect, useCallback } from "react";
import apiGraphql from "../../services/api";

import Loading from "../../common/circular-progress";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import sidebar from "../../assets/sidebar.png";
import Input from "../../common/input";
import InputPassword from "../../common/input-password";
import Topic from "../../common/topic";
import Button from "../../common/button";

import { clickOnEnter } from '../../utils/keyboard/onClickWithEnter'

import { titleLogin } from "./style";

export default () => {
  const classesTitleLogin = titleLogin();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgError, setMsgError] = useState("");
  const history = useHistory();

  const verifyToken = useCallback(() => {
    const token = localStorage.getItem("Token-Vida-Connect");
    const status = localStorage.getItem("Status-Vida-Connect");
    if (token && status === "OK") {
      setLoading(true);
      apiGraphql({
        data: {
          query: `
            query{
              checkSessionTokenEstablishment
            }
          `,
        }
      })
        .then((res) => {
          setLoading(false);
          if (res.data.data.checkSessionTokenEstablishment) {
            const type = localStorage.getItem("Type-Vida-Connect");
            if (
              type === "COMPANY_HEALTH_OPERATOR" ||
              type === "PROVIDER_HEALTH_OPERATOR_EMPLOYEE"
            ) {
              history.push("/operadora-de-saude/solicitacao");
            } else {
              history.push("/administrador/profissionais");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          localStorage.clear();
        });
    }
  }, [history]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  useEffect(() => {
    clickOnEnter()
  })

  const handleSubmit = () => {
    setLoading(true);
    setMsgError("");
    apiGraphql({
      method: "post",
      data: {
        query: `mutation {
          loginEstablishment(password:"${password}", email:"${email}"){
              sessionToken
              user{
                id
                name
                cpf
                type
                status
                establishment{
                  id
                  healthOperator{
                    id
                  }
                }
                provider{
                  healthOperatorEmployees{
                    healthOperator{
                      id
                    }
                  }
                }
              }
            }
          }`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.errors) {
          setMsgError("Email ou Senha inválidos");
        } else {
          const token = res.data.data.loginEstablishment.sessionToken;
          const name = res.data.data.loginEstablishment.user.name;
          const userCpf = res.data.data.loginEstablishment.user.cpf;
          const typeEstablishment = res.data.data.loginEstablishment.user.type;
          const status = res.data.data.loginEstablishment.user.status;
          let establishmentId = "";
          let healthOperatorId = "";
          if (res.data.data.loginEstablishment.user.establishment) {
            establishmentId =
              res.data.data.loginEstablishment.user.establishment.id;

            healthOperatorId = res.data.data.loginEstablishment.user
              .establishment.healthOperator
              ? res.data.data.loginEstablishment.user.establishment
                  .healthOperator.id
              : "";
          }
          if (res.data.data.loginEstablishment.user.provider) {
            healthOperatorId =
              res.data.data.loginEstablishment.user.provider
                .healthOperatorEmployees.healthOperator.id;
          }

          localStorage.setItem("Token-Vida-Connect", token);
          localStorage.setItem("Name-Vida-Connect", name);
          localStorage.setItem("Email-Vida-Connect", email);
          localStorage.setItem("CPF-Vida-Connect", userCpf);
          localStorage.setItem("Type-Vida-Connect", typeEstablishment);
          localStorage.setItem("Status-Vida-Connect", status);
          localStorage.setItem(
            "Establishment-ID-Vida-Connect",
            establishmentId
          );
          localStorage.setItem("healthOperatorId", healthOperatorId);

          if (
            typeEstablishment === "COMPANY" ||
            status === "PENDING_APPROVAL"
          ) {
            history.push("/cadastro/pj");
          } else if (
            typeEstablishment === "COMPANY_HEALTH_OPERATOR" ||
            typeEstablishment === "PROVIDER_HEALTH_OPERATOR_EMPLOYEE"
          ) {
            history.push("/operadora-de-saude/solicitacao");
          } else {
            history.push("/administrador/profissionais");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="screen">
      {loading && <Loading size={80} />}
      {!loading && (
        <>
          {" "}
          <Hidden smDown>
            <div className="sidebar-image">
              <img src={sidebar} alt="Sidebar Vida Connect" />
            </div>
          </Hidden>
          <div className="content">
            <Topic
              classesNameCol={classesTitleLogin.root}
              title="Entre"
              subtitle="Bem-Vindo parceiro Vida Connect."
              classesNameSubtitle="text-black"
            />

            <Grid container>
              <form>
                <Grid item className="space-above-item-less">
                  <Input
                    name="input-email-login"
                    datatest="input-email-login"
                    type="email"
                    label="Email"
                    disabled={loading}
                    width="360px"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item className="space-above-item-less">
                  <InputPassword
                    name="input-password-login"
                    datatest="input-password-login"
                    label="Senha"
                    disabled={loading}
                    width="360px"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={msgError}
                    linkText="ESQUECI MINHA SENHA"
                    link={`${process.env.REACT_APP_SIGNUP}/reset-password?type=company`}
                  />
                </Grid>
                <Grid item className="space-above-item">
                  <Button
                    name="button-enter-login"
                    datatest="button-enter-login"
                    type="submit"
                    width="170px"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Entrar
                  </Button>
                </Grid>
                {!loading && (
                  <Grid item className="space-above-item-less">
                    <a
                      className="grey-text"
                      href={`${process.env.REACT_APP_SIGNUP}`}
                    >
                      Ainda não é um parceiro? Clique aqui e faça parte do Vida
                      Connect.
                    </a>
                  </Grid>
                )}
              </form>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
