export default function currency(value) {
  value = value.toString().replace(/\D/g, "");

  if (value.length < 3) {
    value = `0${value}`;
    return currency(value);
  } else if (value.length === 4) {
    value = value.replace(/^0/, "");
  }
  value = value.toString().replace(/\D/g, "");
  value = value.toString().replace(/(\d)(\d{8})$/, "$1.$2");
  value = value.toString().replace(/(\d)(\d{5})$/, "$1.$2");
  value = value.toString().replace(/(\d)(\d{2})$/, "$1,$2");

  return value;
}
