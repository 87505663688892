import React, { useState, forwardRef } from "react";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import PersonalData from "./personalData";
import Specialities from "./specialities";

import { modal } from "../style";

export default forwardRef((props, ref) => {
  const classesModal = modal();

  const [step, setStep] = useState(0);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ error: false, typeError: "" });
  const [addresses, setAddresses] = useState([]);
  const [addressesError, setAddressesError] = useState({
    error: false,
    typeError: "",
  });

  const [listSpecialities, setListSpecialities] = useState([]);

  const nextStep = () => {
    setStep(step + 1);
  };
  const previousStep = () => {
    setStep(step - 1);
  };

  return (
    <div className={classesModal.root}>
      <div
        className={classesModal.title}
        id="modal-register-consultation-title"
      >
        <h1>Cadastrar Profissional</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {step === 0 && (
          <PersonalData
            classesModal={classesModal}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            addresses={addresses}
            setAddresses={setAddresses}
            addressesError={addressesError}
            setAddressesError={setAddressesError}
            listSpecialities={listSpecialities}
            setListSpecialities={setListSpecialities}
            handleClose={props.handleClose}
            nextStep={nextStep}
          />
        )}
        {step === 1 && (
          <Specialities
            classesModal={classesModal}
            previousStep={previousStep}
            listSpecialities={listSpecialities}
            setListSpecialities={setListSpecialities}
            email={email}
            getListProfessionals={props.getListProfessionals}
            handleClose={props.handleClose}
          />
        )}
      </div>
    </div>
  );
});
