import React, { useState, forwardRef } from "react"
import Loading from "../../circular-progress";

import Button from "../../button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { modal } from "../style";
import * as consultationService from '../../../services/consultation'


export default forwardRef((props, ref) => {
  const {unitHasSpecialityId,
     setStateSnackBar, stateSnackBar,
     setListConsultations,listConsultations } = props;
  
  const classesModal = modal({maxWidth: '540px'});
  const [loading, setLoading] = useState(false);
  

  const deleteConsultation = async() => {
    setLoading(true);
    
    const res = await consultationService.inactiveConsultation(unitHasSpecialityId) 

    if(!res.error) {

      setStateSnackBar({ ...stateSnackBar, open: true, message: 'Especialidade excluída com sucesso!', color: 'success' });
      props.handleClose();
      setListConsultations(listConsultations.filter(x => x.id !== unitHasSpecialityId))
    } else {
      setStateSnackBar({ ...stateSnackBar, open: true, message: res.message, color: 'error' });
    }
    setLoading(false)
  };

  function handleSubmit(e) {
      deleteConsultation()
      props.handleClose();   
  }

  return (
    <React.Fragment>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <>
          <div className={classesModal.root} >
            <div
              className={classesModal.title}
              id="modal-delete-consultation-title"
            >
              <h1>Excluir Especialidade</h1>
              <IconButton
                aria-label="close"
                onClick={() => props.handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className={classesModal.modalDelete}
              id="modal-delete-consultation-body"
            >
          
              <span className={classesModal.span} style={{fontSize: '1.3rem'}}>
              Deseja mesmo excluir a especialidade dessa unidade?
              </span>
            </div>
            <div className={classesModal.footerModalDelete}>
              <Button
                name="button-cancel-delete-consultation"
                datatest="button-cancel-delete-consultation"
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                width="22vw"
                maxWidth="120px"
                margin="0 20px 0 0 "
                onClick={() => props.handleClose()}
              >
                Não
              </Button>
              <Button
                name="button-delete-consultation"
                datatest="button-delete-consultation"
                backgroundColor="#FFF"
                border="1px solid #DF9000"
                color="#DF9000"
                width="22vw"
                maxWidth="120px"
                margin="0 10% 0 0"
                onClick={(e) => handleSubmit(e)}
              >
                Sim
              </Button>
            </div>
      
          </div>
        </>
      )}
    </React.Fragment>
  );
});
