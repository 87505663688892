import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import apiGraphql from '../../../services/api'

import Loading from "../../../common/circular-progress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";

import Pagination from "../../../common/pagination";
import Select from "./select-filter";
import ModalSolicitation from "./modal";

import { laytoutPageHealthOperatorSolicitation } from "./style";

import { format, parseISO } from 'date-fns'
import { handlePatientName } from "../../../common/handlePatientName";

export default (props) => {
  const classesSolicitation = laytoutPageHealthOperatorSolicitation();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [showMinimunNumber, setShowMinimunNumber] = useState(0);
  const [qtyPages, setQtyPages] = useState(1);
  const [filter, setFilter] = useState("DESC");
  const [openModal, setOpeModal] = useState(false);

  const [listSolicitations, setListSolicitations] = useState([]);
  const [infoSolicitation, setInfoSolicitation] = useState({});

  const handleOpenModal = () => {
    setOpeModal(true);
  };

  const handleCloseModal = () => {
    setOpeModal(false);
  };

  const getServiceStatus = (status, dateMedicalAppointment) => {
    if (dateMedicalAppointment) {
      const dayMedicalAppointment = dateMedicalAppointment.split("-")[2];
      const monthMedicalAppointment = dateMedicalAppointment.split("-")[1] - 1;
      const yearMedicalAppointment = dateMedicalAppointment.split("-")[0];
      dateMedicalAppointment = new Date(
        yearMedicalAppointment,
        monthMedicalAppointment,
        dayMedicalAppointment
      );
    }
    const actualDate = new Date();
    switch (status) {
      case "PENDING":
        return "Aguardando validação";
      case "APPROVED":
        if (dateMedicalAppointment > actualDate) {
          return "Aguardando realização";
        } else {
          return "Realizado";
        }
      case "REJECTED":
        return "Solicitação Reprovada";
      case "CANCELED":
        return "Cancelado";
      default:
        return status;
    }
  };

  const statusNameInPortuguese = (status) => {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "APPROVED":
        return "Aprovado";
      case "REJECTED":
        return "Reprovado";
      case "CANCELED":
        return "Cancelado";
      default:
        return status;
    }
  };

  const getDate = (unformatedDate) => {
    return format(parseISO(unformatedDate), 'dd/MM/yyyy')
  };

  const getListSolicitations = useCallback(() => {
    setLoading(true);
    const healthOperatorId = localStorage.getItem("healthOperatorId");

    apiGraphql({
      data: {
        query: `
          query{
            listHealthOperatorRequest(page:${page},limit:${limit},order:${filter},healthOperatorId:"${healthOperatorId}"){
              count
              rows{
                id
                status
                createdAt
                healthOperator{
                  id
                  companyName
                  fantasyName
                }
                medicalAppointment{
                  id
                  date
                  appointmentLocation
                  speciality{
                    name
                  }
                  provider{
                    user{
                      name
                    }
                    doctor{  
                      crm       
                    }
                    professionalHasProvider{
                      uf
                      professionalDocument
                      professionalCouncil {
                        name
                      }
                    }
                  }
                  patient{
                    socialName
                    patientHasPlans{
                       membershipCard
                      plan{
                        healthOperator{
                          companyName
                        }   
                      }  
                    }
                    user{
                      name
                      cpf
                    }
                  } 
                  establishment{
                    clinic{
                      companyName
                    }
                  }           
                }
              }
            }
          }
        `,
      }
     })
      .then((res) => {
        setListSolicitations(res.data.data.listHealthOperatorRequest.rows);
        setCount(res.data.data.listHealthOperatorRequest.count);
        setShowMaxNumber(limit * page);
        setShowMinimunNumber(page * limit - limit + 1);
        setQtyPages(
          count % limit === 0
            ? Math.floor(count / limit)
            : Math.floor(count / limit) + 1
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, limit, count, filter]);

  useEffect(() => {
    const typeEstablishment = localStorage.getItem("Type-Vida-Connect");
    if (
      typeEstablishment !== "COMPANY_HEALTH_OPERATOR" &&
      typeEstablishment !== "PROVIDER_HEALTH_OPERATOR_EMPLOYEE"
    ) {
      history.push("/administrador/profissionais");
    } else {
      getListSolicitations();
    }
  }, [history, getListSolicitations]);

  return (
    <div className={classesSolicitation.root}>
      <div className="content-solicitation">
        <div className="header">
          <h1>Solicitações de Atendimento</h1>
          <Select
            width="100%"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        {loading && <Loading size={80} />}
        {!loading && (
          <>
            <div className="list-solicitations">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Paciente</TableCell>
                      <TableCell>Carteirinha</TableCell>
                      <TableCell>Convênio</TableCell>
                      <TableCell>Serviço Solicitado</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Data da Consulta</TableCell>
                      <TableCell>Solicitado em</TableCell>
                      <TableCell>Status do Serviço</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {listSolicitations.map((solicitation, index) => (
                      <TableRow
                        key={index}
                        onClick={() => {
                          setInfoSolicitation(solicitation);
                          handleOpenModal();
                        }}
                      >
                        <TableCell>
                          {handlePatientName(solicitation.medicalAppointment.patient)}
                        </TableCell>
                        <TableCell>
                          {
                            solicitation.medicalAppointment.patient
                              .patientHasPlans.membershipCard
                          }
                        </TableCell>
                        <TableCell>
                          {
                            solicitation.healthOperator.companyName
                          }
                        </TableCell>
                        <TableCell>Consulta</TableCell>
                        <TableCell>{solicitation?.medicalAppointment?.appointmentLocation === 'ONLINE' ? 'Online' : 'Presencial'}</TableCell>
                        <TableCell>
                          {Boolean(solicitation?.medicalAppointment?.date) ? getDate(solicitation.medicalAppointment.date) : '-'}
                        </TableCell>
                        <TableCell>{Boolean(solicitation?.createdAt) ? format(new Date(solicitation.createdAt), 'dd/MM/yyyy - HH:mm') : '-'}</TableCell>
                        <TableCell>
                          {getServiceStatus(
                            solicitation.status,
                            solicitation.medicalAppointment.date
                          )}
                        </TableCell>
                        <TableCell>
                          <div
                            className={`status-solicitation ${solicitation.status.toLowerCase()}`}
                          >
                            {statusNameInPortuguese(
                              solicitation.status.toUpperCase()
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="footer">
              <Pagination
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                showMinimumNumber={showMinimunNumber}
                showMaxNumber={showMaxNumber}
                count={count}
                qtyPages={qtyPages}
              />
            </div>
          </>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalSolicitation
          handleClose={handleCloseModal}
          infoSolicitation={infoSolicitation}
          getListSolicitations={getListSolicitations}
        />
      </Modal>
    </div>
  );
};
