const INITIAL_STATE = {
  active: undefined,
  consultations: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "REGISTER_CONSULTATIONS":
      return {
        ...state,
        consultations: [...state.consultations, action.payload],
      };

    case "ALTER_ACTIVE_CONSULTATION":
      return {
        ...state,
        active: action.payload,
      };
    case "DELETE_CONSULTATION":
      return {
        ...state,
        consultations: state.consultations.filter(
          (consultation, index) => index !== action.payload
        ),
        active: undefined,
      };

    case "UPDATE_CONSULTATION":
      return {
        ...state,
        consultations: state.consultations.map((consultation, index) =>
          index === action.payload
            ? (consultation = action.details)
            : consultation
        ),
      };

    case "DELETE_ALL_CONSULTATIONS":
      return {
        ...state,
        consultations: [],
        active: undefined,
      };

    default:
      return state;
  }
}
