import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputBase } from "@material-ui/core";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    width: "90%",
    height: "40px",
  },
  root: {
    borderRadius: 4,
    textIndent: "0.4rem",
    height: "3rem",
    backgroundColor: "#FFF",
    color: "#6B6C7E",
  },
});

export default (props) => {
  const classes = useStyles(props);
  return (
    <div className={`row first-field-topic ${props.classesNameRow || ""}`}>
      <div
        className={`col ${props.classesNameCol || ""} file-field input-field`}
      >
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            name={props.name}
            data-test={props.datatest}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            displayEmpty
            input={<InputBase />}
            className={`selection ${classes.root}`}
          >
            <MenuItem value="csv" selected>
              .csv
            </MenuItem>
            <MenuItem value="xlsx">.xlsx</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
