import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Input from "../../input";
import Button from "../../button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

import empty from "../../validators/empty";
import cepValidator from "../../validators/cep";
import telphoneValidator from "../../validators/telephone";
import maskCep from "../../mask/cep";
import maskTelephone from "../../mask/telephone";

import Loading from "../../circular-progress";

import SnackBar from "../../snackbar";

import "../style.css";

import { modal } from "../style";

import * as addressAction from "../../store/actions/registerAddress";

export default forwardRef((props, ref) => {
  const classesModal = modal({ ...modal, maxWidth: "800px" });

  const units = useSelector((state) => state.Address.units);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [cnes, setCnes] = useState("");
  const [cnesError, setCnesError] = useState({ error: true, typeError: "" });

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState({
    error: true,
    typeError: "",
  });

  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState({
    error: true,
    typeError: "",
  });

  const [address, setAddress] = useState("");

  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");

  const [neighborhood, setNeighborhood] = useState("");
  const [neighborhoodError, setNeighborhoodError] = useState({
    error: true,
    typeError: "",
  });
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState({
    error: true,
    typeError: "",
  });

  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState({
    error: true,
    typeError: "",
  });

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  async function handleValidateCep() {
    setLoading(true);
    setZipCodeError({ error: false, typeError: "" });
    if (empty(zipCode).error) {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({
        error: true,
        typeError: "Esse Campo é obrigatório!",
      });
    }
    const unitsDetails = await cepValidator(zipCode);
    if (unitsDetails) {
      setAddress(unitsDetails.logradouro);
      setNeighborhood(unitsDetails.bairro);
      setNeighborhoodError({ error: false, typeError: "" });
      setDistrict(unitsDetails.uf);
      setCity(unitsDetails.localidade);
      setLoading(false);
    } else {
      setAddress("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setLoading(false);
      return setZipCodeError({ error: true, typeError: "CEP inválido" });
    }
  }

  function checkRegisteredAddresses() {
    const unitAlreadyRegistered = units.some(
      (unit) =>
        unit.zipCode === zipCode.replace(/\D/g, "") &&
        unit.number === number &&
        unit.complement === complement
    );
    if (unitAlreadyRegistered) {
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Este endereço ja foi cadastrado!",
      });
    }
    return unitAlreadyRegistered;
  }

  function handleSubmit(e) {
    e.preventDefault();

    setCnesError(empty(cnes));
    setCountryError(empty(country));
    setNeighborhoodError(empty(neighborhood));
    setPhoneError(telphoneValidator(phone));
    setWhatsappError(telphoneValidator(whatsapp));

    const unitAlreadyRegistered = checkRegisteredAddresses();

    if (
      !(
        cnesError.error ||
        countryError.error ||
        neighborhoodError.error ||
        zipCodeError.error ||
        phoneError.error ||
        whatsappError.error ||
        unitAlreadyRegistered
      )
    ) {
      const phoneWithoutString = phone.replace(/\D/g, "");
      const whatsappWithoutString = whatsapp.replace(/\D/g, "");
      const zipCodeWithoutString = zipCode.replace(/\D/g, "");
      const payload = {
        cnes,
        country,
        zipCode: zipCodeWithoutString,
        address,
        number,
        complement,
        neighborhood,
        district,
        city,
        phone: phoneWithoutString,
        whatsapp: whatsappWithoutString,
        specialities: [],
        //exams: [],
        //proceedings: [],
        //otherServices: [],
      };

      dispatch(addressAction.registerAddress(payload));
      dispatch(addressAction.alterActiveAddress(units.length));
      dispatch(addressAction.toggleRegisterServices());

      setCnes("");
      setCountry("");
      setZipCode("");
      setAddress("");
      setNumber("");
      setComplement("");
      setNeighborhood("");
      setDistrict("");
      setCity("");
      setPhone("");
      setWhatsapp("");

      props.handleClose();
    }
  }

  useEffect(() => {
    const ac = new AbortController();

    async function validateCep() {
      await handleValidateCep();
    }
    if (zipCode === 9) {
      validateCep();
    }
    return () => ac.abort();
    //eslint-disable-next-line
  }, [zipCode]);

  return (
    <div className={classesModal.root}>
      {loading && <Loading height="70vh" width="50vw" size={60} />}
      <div className={classesModal.title} id="modal-register-address-title">
        <h1>Cadastrar Endereço</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classesModal.body} id="modal-register-address-body">
        <Input
          name="input-cnes-register-address"
          datatest="input-cnes-register-address"
          width="90%"
          disabled={loading}
          label={<span>CNES <small>(Para consultar o seu CNES acesse o link <a href="http://cnes.datasus.gov.br/" target="_blank" rel="noopener noreferrer">http://cnes.datasus.gov.br/</a>)</small></span>}
          placeholder="CNES"
          type="text"
          value={cnes}
          onChange={(e) => setCnes(e.target.value)}
          onBlur={() => setCnesError(empty(cnes))}
          error={cnesError.typeError}
        />
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Input
              name="input-country-register-address"
              datatest="input-country-register-address"
              width="90%"
              disabled={loading}
              label="Pais"
              placeholder="Pais"
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              onBlur={() => setCountryError(empty(country))}
              error={countryError.typeError}
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              name="input-zipCode-register-address"
              datatest="input-zipCode-register-address"
              width="90%"
              disabled={loading}
              label="CEP"
              placeholder="CEP"
              type="text"
              value={maskCep(zipCode)}
              onChange={(e) => setZipCode(maskCep(e.target.value))}
              onBlur={() => handleValidateCep()}
              error={zipCodeError.typeError}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Input
              name="input-address-register-address"
              datatest="input-address-register-address"
              width="90%"
              disabled={loading}
              label="Endereço"
              placeholder="Endereço"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-number-register-address"
              datatest="input-number-register-address"
              width="90%"
              disabled={loading}
              label="Número"
              placeholder="Núm."
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-complemnent-register-address"
              datatest="input-complemnent-register-address"
              width="80%"
              disabled={loading}
              label="Complemento"
              placeholder="Compl."
              type="text"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Input
              name="input-neighborhood-register-address"
              datatest="input-neighborhood-register-address"
              width="90%"
              disabled={loading}
              label="Bairro"
              placeholder="Bairro"
              type="text"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              error={neighborhoodError.typeError}
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              name="input-district-register-address"
              datatest="input-district-register-address"
              width="90%"
              disabled={loading}
              label="Estado"
              placeholder="UF"
              type="text"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name="input-city-register-address"
              datatest="input-city-register-address"
              width="100%"
              disabled={loading}
              label="Cidade"
              placeholder="Cidade"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Input
              name="input-phone-register-address"
              datatest="input-phone-register-address"
              width="90%"
              disabled={loading}
              label="Telefone"
              placeholder="Telefone"
              type="text"
              value={maskTelephone(phone)}
              onChange={(e) => setPhone(maskTelephone(e.target.value))}
              onBlur={() => setPhoneError(telphoneValidator(phone))}
              error={phoneError.typeError}
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              name="input-whatsapp-register-address"
              datatest="input-whatsapp-register-address"
              width="90%"
              disabled={loading}
              label="Whatsapp"
              placeholder="Whatsapp"
              type="text"
              value={maskTelephone(whatsapp)}
              onChange={(e) => setWhatsapp(maskTelephone(e.target.value))}
              onBlur={() => setWhatsappError(telphoneValidator(whatsapp))}
              error={whatsappError.typeError}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classesModal.footer}>
        <Button
          name="button-cancel-register-address"
          datatest="button-cancel-register-address"
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          name="button-register-address"
          datatest="button-register-address"
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={(e) => handleSubmit(e)}
          disabled={loading}
        >
          Salvar
        </Button>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
});
