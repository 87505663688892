import graphqlApi from './api'

export const listSpecialties = async() => {
  try {
    const res = await graphqlApi({
      data: {
        query: `
          query listSpecialities($input:GetSpecialitiesInput){
            listSpecialities(input:$input){
              rows{
                id,
                name,
                group
              }
            }
          }
        `,
        variables: {
          input: { limit: 100, page: 1 },
        },
      }
    })

    return res.data.data.listSpecialities.rows

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar buscar a lista de especialidades' }
  }
}