// import graphqlApi from './api'
import axios from 'axios'
export const listExams = async () => {

  return [
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true },
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true },
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true },
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true },
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true },
    { code: '1234', name: 'Exam1', category: 'Imagem', price: 123.00, schedule: true }
  ]

}


export const saveExamSpreadsheet = async (payload) => {
    const urlRest = `${process.env.REACT_APP_API_URL}/servicesProvided/saveEstablishmentExams`;
    
         return await axios({
                method: "post",
                url: urlRest,
                data: payload,
            }).then(() => {
                return {success: true}
            }).catch((err) => {
                console.log()
                if(err.response.data.statusCode === 500){
                    return {success: false, message: "Houve um erro interno, por favor, tente novamente mais tarde."}    
                }
                else{
                return {success: false, message: err.response.data.message}
                }
                
            });
    

    };