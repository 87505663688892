import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

import "./style.css";

import Logo from "../../assets/Logo-VC.png";

const useStyles = makeStyles((theme) => ({
  grow: {
    width: "100%",
    overflowX: "hidden",
    top: 0,
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    height: "auto",
  },
  edgeStart: {
    height: "auto",
    margin: 0,
    width: "27%",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "flex-end",
      "& button": {
        margin: "0 0 0 10px",
        padding: 0,
        width: 40,
      },
    },
  },

  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setMobileMoreAnchorEl] = React.useState(null);

  const typeEstablishment = localStorage.getItem("Type-Vida-Connect");
  const status = localStorage.getItem("Status-Vida-Connect");
  const userName = localStorage.getItem("Name-Vida-Connect");

  const active =
    typeEstablishment === "COMPANY_HEALTH_OPERATOR" ||
    typeEstablishment === "PROVIDER_HEALTH_OPERATOR_EMPLOYEE"
      ? props.location.pathname.split("/")[2]
      : props.location.pathname.split("/")[1];

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logout = () => {
    localStorage.clear();
    props.history.push("/");
  };

  const menuId = "primary-search-account-menu";

  const menuItems = () => {
    if (
      typeEstablishment === "COMPANY_HEALTH_OPERATOR" ||
      typeEstablishment === "PROVIDER_HEALTH_OPERATOR_EMPLOYEE"
    ) {
      return (
        <>
          <MenuItem>
            <NavLink
              exact
              to="/operadora-de-saude/solicitacao"
              activeStyle={{ color: "#DF9000" }}
            >
              Solicitações
            </NavLink>
          </MenuItem>
          <MenuItem>
            {typeEstablishment === "COMPANY_HEALTH_OPERATOR" && (
              <NavLink
                exact
                to="/operadora-de-saude/relatorio"
                activeStyle={{ color: "#DF9000" }}
              >
                Relatórios
              </NavLink>
            )}
          </MenuItem>
          <MenuItem>
            {typeEstablishment === "COMPANY_HEALTH_OPERATOR" && (
              <NavLink
                to="/operadora-de-saude/administrador/analistas"
                activeStyle={{ color: "#DF9000" }}
              >
                Administrador
              </NavLink>
            )}
          </MenuItem>

        </>
      );
    } else {
      return (
        <>
          <MenuItem>
            <NavLink
              to="/administrador/profissionais"
              style={
                active === "administrador" ? { color: "#DF9000" } : { color: "#6B6C7E" }
              }
            >
              Administrador
            </NavLink>
          </MenuItem>

          { typeEstablishment === "COMPANY_HOSPITAL" && 
            <MenuItem>
              <NavLink
                to="/solicitacoes"
                style={
                  active === "solicitacoes" ? { color: "#DF9000" } : { color: "#6B6C7E" }
                }
              >
                Solicitações
              </NavLink>
            </MenuItem>
          }

        </>
      );
    }
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem style={{ paddingRight: "50px" }} onClick={() => logout()}>
        Sair
      </MenuItem>
      {typeEstablishment !== "COMPANY" && status !== "PENDING_APPROVAL" && (
        <Hidden mdUp>
          <Divider />
          {menuItems()}
        </Hidden>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className="nav" position="static">
        <Toolbar className="nav-bar">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            disabled
          >
            <img src={Logo} alt="Logo Vida" />
          </IconButton>
          {typeEstablishment !== "COMPANY" && status !== "PENDING_APPROVAL" && (
            <Hidden smDown>
              <div className="links">{menuItems()}</div>
            </Hidden>
          )}

          <div className={classes.sectionDesktop}>
            <div className="user-info">
              <span className="user-name">{userName}</span>
              <span className="user-job"></span>
            </div>
            <IconButton
              edge="start"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              classes={{ edgeStart: classes.edgeStart }}
            >
              <Avatar src={undefined} alt="User" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default withRouter(NavBar);
