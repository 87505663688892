const INITIAL_STATE = {
  active: undefined,
  exams: [],
  examExists: 0,
  establishmentExams: [],
  selectedEstablishmentExam: {},
  openAddModal: false,
  openEditModal: false,
  openModal: false,
  examsSpreadsheet: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALTER_ACTIVE_EXAM":
      return {
        ...state,
        active: action.payload,
      };
    case "REGISTER_EXAM":
      return {
        ...state,
        exams: [...state.exams, action.payload],
      };
      case "GET_EXAM":
      return {
          ...state,
          examExists: state.exams.filter(exam => exam.serviceProvidedId === action.payload).length,
        };
    case "DELETE_EXAM":
      return {
        ...state,
        exams: state.exams.filter((exam, index) => index !== action.payload),
        active: undefined,
      };

    case "UPDATE_EXAM":
      return {
        ...state,
        exams: state.exams.map((exam, index) =>
          index === action.payload ? (exam = action.details) : exam
        ),
      };

    case "DELETE_ALL_EXAMS":
      return {
        ...state,
        exams: [],
        active: undefined,
      };
      case "REGISTER_ESTABLISHMENT_EXAM":
return {
...state,
establishmentExams: [...state.establishmentExams, action.payload],
};

case "UPDATE_ESTABLISHMENT_EXAMS":
return {
...state,
establishmentExams: state.establishmentExams.map((establishmentExams, index) =>
index === action.payload ? action.details: establishmentExams
), 
};

case "SET_CHOOSEN_ESTABLISHMENT_EXAMS":
return {
...state,
establishmentExams: action.payload,
}
case "GET_SELECTED_ESTABLISHMENT_EXAM":
return {
...state,
selectedEstablishmentExam: action,
}
case "OPEN_MODAL_ADD_EXAM":
return {
        ...state,
        openAddModal: action.payload
      }
case "OPEN_EDIT_MODAL_EXAM":
 return {
  ...state,
  openEditModal: action.payload
}
case "OPEN_MODAL_EXAM":
  return {
    ...state,
    openModal: action.payload
  }

  case "REGISTER_EXAMS_SPREADSHEET":
      return {
        ...state,
        examsSpreadsheet:  action.payload,
      };

  case "DELETE_EXAMS_SPREADSHEET":
        return {
          ...state,
          examsSpreadsheet:{},
  };
     
    default:
      return state;
  }
}
