import React from "react";

import Box from "@material-ui/core/Box";

import { titleAwaitValidation } from "./style";

export default () => {
  const classesTitleAwaitValidation = titleAwaitValidation();
  return (
    <Box className={classesTitleAwaitValidation.root}>
      <h1>O seu cadastro está </h1>
      <h1>aguardando validação.</h1>
      <h2>Enviaremos um email confirmando o seu cadastro</h2>
    </Box>
  );
};
