import React, { useState, forwardRef } from "react";
import apiGraphql from "../../../../../services/api";

import Loading from "../../../../../common/circular-progress";

import Button from "../../../../../common/button";

import { modal } from "../style";

export default forwardRef((props, ref) => {
  const { name, status, employeeId } = props;

  const [loading, setLoading] = useState(false);

  const handleInactiveOrActiveDoctor = () => {
    setLoading(true);
    const goToStatus = status === "INACTIVE" ? "ACTIVE" : "INACTIVE";
    apiGraphql({
      data: {
        query: `
          mutation updateEmployeeStatus($input: UpdateEmployeeStatusInput!){
            updateEmployeeStatus(input: $input)
          }
        `,
        variables: {
          input: { status: goToStatus, id: employeeId },
        },
      }
    })
      .then((_) => {
        setLoading(false);
        props.getListProfessionals();
        props.handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const classesModal = modal();

  return (
    <div className={classesModal.root}>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <div className={classesModal.modalActiveOrInactive}>
          <h3>
            Deseja realmente {status === "INACTIVE" ? "ativar" : "inativar"}{" "}
            o(a) profissional
          </h3>
          <h3>
            <strong>{name} </strong> ?
          </h3>
        </div>
      )}

      <div className={classesModal.footerModalActiveOrInactive}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          cancelar
        </Button>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => handleInactiveOrActiveDoctor()}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
});
