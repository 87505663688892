import React, { useState, useEffect } from 'react'
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  makeStyles, 
  IconButton,
} from '@material-ui/core' 
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close';

import apiRest from "../../../services/restApi";
import Button from '../../../common/button'
import { Budget } from './budget'
import { DateApproval } from './dateApproval'
import { Summary } from './summary'
import { ProcedureRequestStatusEnum, BudgetStatusEnum } from '../../../common/enums/'
import SnackBar from "../../../common/snackbar";
import * as procedureService from '../../../services/procedure'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
    borderBottom: "1px solid #E7E7ED",
  },
  titleSuccess: {
    color: '#287D3C',
    backgroundColor: '#EDF9F0'
  },
  check: {
    position: 'relative',
    top: 5,
    marginRight: 10,
  },
  closeButton: {
    float: 'right',
    right: theme.spacing(-1),
    top: theme.spacing(-1)
  },
  actions: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#F7F8F9",
  },
  back: {
    float: 'left',
    position: 'absolute'
  }
}))

export const ProcedureRequestModal = ({open, setOpen, budget, reload}) => {
  const classes = useStyles()

  const status = budget?.procedureRequest.status;

  const [ step, setStep ] = useState(1)
  const [ success, setSuccess ] = useState(false) 
  const [ budgetFile, setBudgetFile ] = useState({ name: "" })
  const [ budgetError, setBudgetError ] = useState({
    error: false,
    typeError: "",
  })
  const [ price, setPrice ] = useState(0)
  const [ dateResponse, setDateResponse ] = useState()
  const [ disableSend, setDisableSend ] = useState(false)

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const hasNextStep = (
    status === ProcedureRequestStatusEnum.CONFIRM_SCHEDULE || 
    status === ProcedureRequestStatusEnum.AWAITING_BUDGET || 
    budget?.status === BudgetStatusEnum.BUDGET_SENDED
  )

  const nextStep = async () => {
    if(!hasNextStep || success) {
      setOpen(false)
    }
    else if(step < 2) {
      setStep(step + 1)
    } else if (!disableSend){
      
      if(status === ProcedureRequestStatusEnum.CONFIRM_SCHEDULE) {
        
        
        const result = await procedureService.confirmProcedureDate(budget.procedureRequest.id, dateResponse)

        if(!result.error) {
          setSuccess(true)
          reload()
        } else {
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: result.message || "Houve um erro ao tentar enviar as informações"
          })
        }

      } 
      
      else if(
        (budget?.status === BudgetStatusEnum.BUDGET_SENDED || status === ProcedureRequestStatusEnum.AWAITING_BUDGET) &&
        !budgetError.error
      ) {
        const formData = new FormData()
        formData.append('budgetFile', budgetFile)
        formData.append('establishmentPrice', price)
        formData.append('budgetId', budget.id)
        apiRest.post('/budget/budget-response', formData)
        .then(() => {
          setSuccess(true)
          reload()
        })
        .catch((error) => {
          const result = error.response
          if (result) {
            const status = result.status

            if (status === 400) {
              setStateSnackBar({
                ...stateSnackBar,
                open: true,
                message: result.data.message || "Houve um erro de conexão com nosso servidor.Tente novamente, por favor.",
              });
            }
            return
        }
        setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message:
            "Desculpe, houve um erro ao tentar enviar as informações",
        });
        })
      } 
    }
  }

  const previousStep = () => {
    if(step > 1) {
      setStep(step - 1)
      setDisableSend(false)
    }
  }

  useEffect(() => {
    if(open) {
      setStep(1)
      setSuccess(false)
      setDisableSend(false)
      setDateResponse()
      setBudgetFile({ name: ""  })
      setPrice(0)
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <Dialog
      open={open}
      width={'800px'}
      classes={{
        paper: classes.root
      }}
    >
      <DialogTitle
        className={`${classes.title} ${(success) && classes.titleSuccess}`}
      >
        { success && <CheckCircleIcon className={classes.check}/> }
        <b>{ success ? 'Enviado com Sucesso' : 'Solicitação de Orçamento' }</b>
        
        <IconButton 
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      
      </DialogTitle>
      
      <DialogContent>
        { 
          step === 1 &&
          <Summary budget={budget} />
        }
        { 
          budget?.procedureRequest.status === ProcedureRequestStatusEnum.CONFIRM_SCHEDULE && 
          step === 2 && 
          <DateApproval 
            proposedDate1={budget?.procedureRequest.proposedDate1}
            proposedDate2={budget?.procedureRequest.proposedDate2}
            proposedDate3={budget?.procedureRequest.proposedDate3}
            // response={dateResponse}
            // setResponse={setDateResponse}
            dateResponse={dateResponse}
            setDateResponse={setDateResponse}
            providerName={budget?.provider.user.name}
            providerPhone={budget?.provider.user.phone}
            setDisableSend={setDisableSend}
            success={success}
          />
        }
        { 
          (budget?.status === BudgetStatusEnum.BUDGET_SENDED || budget?.procedureRequest.status === ProcedureRequestStatusEnum.AWAITING_BUDGET) &&
          step === 2 && 
          <Budget 
            budgetFile={budgetFile}
            setBudgetFile={setBudgetFile}
            budgetError={budgetError}
            setBudgetError={setBudgetError}
            price={price}
            setPrice={setPrice}
            setDisableSend={setDisableSend}
            success={success}
          />
        }
      </DialogContent>

      <DialogActions
        className={classes.actions}
      >
        { step > 1 && !success && 
          <Button
            className={classes.back}
            width="100px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            onClick={previousStep}
          >
            Voltar
          </Button>
        }

        <Button
          width="100px"
          border="1px solid #F9B233"
          backgroundColor="#FFFFFF"
          color="#F9B233"
          margin="0 20px 0 0"
          onClick={nextStep}
          disabled={disableSend}
        >
          { hasNextStep && !success ? "Próximo" : "OK"}
        </Button>
      </DialogActions>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </Dialog>
  )
}
