export function RegisterConsultation(payload) {
  return {
    type: "REGISTER_CONSULTATIONS",
    payload: payload,
  };
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_CONSULTATION",
    payload: index,
  };
}

export function deleteConsultation(index) {
  return {
    type: "DELETE_CONSULTATION",
    payload: index,
  };
}

export function updateConsultation(index, details) {
  return {
    type: "UPDATE_CONSULTATION",
    payload: index,
    details,
  };
}

export function deleteAllConsultations() {
  return { type: "DELETE_ALL_CONSULTATIONS" };
}
