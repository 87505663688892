import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as scheduleActions from "../store/actions/schedule.action";
import { handlePatientName} from '../../common/handlePatientName'

const useStyles = makeStyles({
  root: {
    opacity: (props) => (props.active ? 1 : 0),
    height: "90px",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    margin: 0,
    border: "1px solid #E7E7ED",
    "& > span": {
      margin: "10px 10px 3px 10px",
      backgroundColor: "transparent",
      fontSize: "12px",
      color: "#393A4A",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F9B233",
    },
    "&:hover > span": {
      color: "#FFF",
    },
    "&:hover > MuiInputBase-root > input": {
      color: "#FFF",
    },
  },
  whiteSpace: {
    height: "100%",
    padding: 0,
    borderRadius: "0px",
    fontSize: "12px",
  },
  disabled: {
    backgroundColor: "#FFF4EC",
    "& > .info": {
      color: "#B95000",
      textAlign: "center",
      fontSize: "14px",
    },
  },
});

const Slot = ({
  unavailability,
  timeStamp,
  day,
  duration,
  scheduleHasProviderId,
  scheduleHasEmployeeId,
  scheduleGroupProviderId,
  scheduleGroupEmployeeId,
  ...props
}) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  let status = "";
  let unavailabilityProviderId = "";
  let unavailabilityEstablishmentId = "";
  let medicalAppointmentId = "";
  if (unavailability.length > 0 && unavailability[0].medicalAppointment)
    medicalAppointmentId = unavailability[0].medicalAppointment.id;

  let cssClassToApply = "";
  const dataToShow = (() => {
    //Checking if there is any unavailability for this slot
    if (unavailability.length > 0) {
      status = unavailability[0].status;
      unavailabilityProviderId = unavailability[0].id;
      unavailabilityEstablishmentId = unavailability[0].id;
      //Depending on the medicalAppointmentId and unavailability status, we must render different things
      //If a medicalAppointmentId exists, it must be an appointment (right?!)
      if (medicalAppointmentId) {
        switch (status) {
          case "REJECTED_TRIAGE":
            return null;
          case "REJECTED_HEALTH_OPERATOR":
            return null;
          default:
            return handlePatientName(unavailability[0].medicalAppointment.patient);
        }
      } else {
        //It means that is a regular or a deactivated schedule slot. But we must know its status
        switch (status) {
          case "DISABLED":
            cssClassToApply = "disabled";
            return "Desativada";
          default:
            return null;
        }
      }
    }
  })();

  return (
    <div
      className={`${classes.root} ${classes[cssClassToApply]}`}
      onClick={() => {
        dispatch(
          scheduleActions.openSlotModal({
            open: true,
            unavailabilityProviderId,
            unavailabilityEstablishmentId,
            status,
            medicalAppointmentId,
            dataToShow,
            timeStamp,
            day,
            duration,
            scheduleHasProviderId,
            scheduleGroupProviderId,
            scheduleHasEmployeeId,
            scheduleGroupEmployeeId,
          })
        );
      }}
    >
      <span>{timeStamp}</span>
      <span className={`${classes.whiteSpace} info`}>{dataToShow}</span>
    </div>
  );
};

export default Slot;
