import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import ComplexList from '../../../../common/itemList/listForMultipleFields';
import ModalRegisterExamButton from '../../../../common/modal/exam/registerExam';
import InputFileButton from "../../../../common/fileInput/fileInputButton";
import SnackBar from '../../../../common/snackbar';
import { validationFileType } from '../../../../common/validators/fileInput'
import DownloadSpreadsheet from '../../../../common/DownloadSpreadsheet'

import * as registerExams from "../../../../common/store/actions/registerExams";

import {
  contentPageRegisterServices,
} from "../style";
export const Exams = () => {
  const classesNameContentPage = contentPageRegisterServices()
  const dispatch = useDispatch()
  const exams = useSelector((state) => state.Exams.exams);
  const examsSpreadsheet = useSelector((state) => state.Exams.examsSpreadsheet)
  const [disableAddExam, setDisableAddExam]= useState(!!examsSpreadsheet?.name)

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const handleUpdateExam = (index) => {
    dispatch(registerExams.alterActive(index));
    dispatch(registerExams.openModal(true));
  }

  const deleteExam = (index) => {
    dispatch(registerExams.deleteExam(index));
  }

  const handleChangeInputFile = async (value, typesAccepted) => {
    if (!value)
    {
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Erro ao incluir planilha, por favor, tente novamente.",
      });
      return 
    }
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "O tipo do arquivo selecionado não é aceito para esse campo!",
      });
    

      return
    } 
    console.log(value)
    dispatch(registerExams.RegisterExamSpreadsheet(value));
    setDisableAddExam(true)
    return 
  }

  const typesAccepted = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']


  return (
    <>

      <Box className={classesNameContentPage.root}>
      <Box className={classesNameContentPage.boxMarginSide}>
        <Box className={classesNameContentPage.boxTitleWithButton}>
          <Box component="span" className={classesNameContentPage.boxTitle}>
            <h1>Exames</h1>
            <h2>Cadastre aqui os exames que realizará neste endereço</h2>
          </Box>
          <Box
            component="span"
            className={classesNameContentPage.boxButton}
          >

            <DownloadSpreadsheet/>
            
           <InputFileButton
                      id="file"
                      width="190px"
                      accept=".xlsx"
                      onChange={(e) =>
                        handleChangeInputFile(e.target.files[0], typesAccepted)
                      }
                      value={ "Importar planilha"}
                      dataTest="input-pf-file"
                      margin="0 5px 0 0"
                      />
               <ModalRegisterExamButton
            data-target="modal-register-exam"
            disabled={disableAddExam}
          >
            Adicionar
          </ModalRegisterExamButton>
          </Box>
          </Box>
      <Box maxWidth="83vw">     
            { exams.length > 0 && <ComplexList 
              fullList={exams} 
              firstHeader={"Cód"}
              secondHeader={"Descrição"}
              thirdHeader={"Preparo"}
              forthHeader={"Preço"}
              firstParam={"examCode"}
              secondParam={"name"}
              thirdParam={"preparation"}
              forthParam={"price"}
              menuItems={
                [
                  {name: "Editar esse exame", handleItem: handleUpdateExam}
                ]
              }
              deleteItem={deleteExam}
            />
          }
        
          </Box>
        
      </Box>


    </Box>
    <SnackBar
                stateSnackBar={stateSnackBar}
                setStateSnackBar={setStateSnackBar}
        />
    </>
  )
}
