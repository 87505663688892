import React, { useState, useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import Pagination from "../../../../common/pagination";
import Loading from "../../../../common/circular-progress";

import * as establishmentService from '../../../../services/establishment'

export default (props) => {
  const [listUnits, setListUnits] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [qtyPages, setQtyPages] = useState(1);
  const [showMinimumNumber, setShowMinimunNumber] = useState(0);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listUnitsPagination, setListUnitsPagination] = useState([]);

  const handleChooseUnit = (unitInformation) => {
    props.setUnitInformations(unitInformation);
    props.setAlreadyChooseUnit(true);
  };

  const loadUnits = async () => {
    setLoading(true)
    const units = await establishmentService.listUnitsOfEstablishment()
    if(!units?.error) {
      setListUnits(units)
    } else {

    }
    setLoading(false)
  }

  useEffect(() => {
    loadUnits()
  }, []);

  useEffect(() => {
    setListUnitsPagination(listUnits.slice(limit * (page - 1), limit * page));
    setShowMinimunNumber(limit * (page - 1) + 1);
    setShowMaxNumber(limit * page);
    setCount(listUnits.length);
    setQtyPages(
      listUnits.length % limit === 0
        ? Math.floor(listUnits.length / limit)
        : Math.floor(listUnits.length / limit) + 1
    );
  }, [limit, listUnits, page]);
  return (
    <React.Fragment>
      {loading && <Loading size={80} />}
      {!loading && (
        <>
          {" "}
          <div className="header-choice-units">
            <h1>{ props.title }</h1>
            <h2>{ props.subtitle }</h2>
          </div>
          <div className="list-units">
            <TableContainer>
              <Table>
                <TableBody className="table-body-unit">
                  {listUnitsPagination.map((unit, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleChooseUnit(unit)}
                    >
                      <TableCell>
                        {unit.address}
                        {unit.number ? ", nº" + unit.number : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="footer">
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              showMinimumNumber={showMinimumNumber}
              showMaxNumber={showMaxNumber}
              count={count}
              qtyPages={qtyPages}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};
