import React, { useState } from "react";
import apiGraphql from "../../../../../services/api";

import InputPassword from "../../../../../common/input-password";
import Button from "../../../../../common/button";

import empty from "../../../../../common/validators/empty";

import Loading from "../../../../../common/circular-progress";

export default (props) => {
  const { name, email, cpf, setStateSnackBar } = props;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState({
    error: false,
    typeError: "",
  });
  const [loading, setLoading] = useState(false);

  const registerAnalyst = () => {
    setLoading(true);
    const emailHealthOperator = localStorage.getItem("Email-Vida-Connect");
    const healthOperatorId = localStorage.getItem("healthOperatorId");
    apiGraphql({
      data: {
        query: `mutation {
          loginEstablishment(password:"${password}", email:"${emailHealthOperator}"){
              sessionToken
            }
          }`,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          setLoading(false);
          setPasswordError({
            error: true,
            typeError: "A senha digita está incorreta!",
          });
        } else {
          apiGraphql({
            data: {
              query: `
                mutation {
                  createHealthOperatorEmployee(name:"${name}", email:"${email}",cpf:"${cpf.replace(
                /[^\d]+/g,
                ""
              )}",healthOperatorId:"${healthOperatorId}")
                }
              `,
            }
          })
            .then((res) => {
              setLoading(false);
              if (res.data.errors) {
                const messageError = res.data.errors[0].message;
                setStateSnackBar((state) => ({
                  ...state,
                  color: "error",
                  message:
                    messageError === "Email or cpf already exists"
                      ? "O CPF ou Email digitado já se encontram cadastrados."
                      : "houve um erro ao enviar o convite, tente novamente mais tarde ",
                  open: true,
                }));
              } else {
                setStateSnackBar((state) => ({
                  ...state,
                  color: "success",
                  message: "Convite enviado com sucesso ",
                  open: true,
                }));
                props.handleClose();
              }
            })
            .catch((err) => {
              setLoading(false);
              setStateSnackBar((state) => ({
                ...state,
                color: "error",
                message:
                  "houve um erro ao tentar conectar com o servidor, tente novamente mais tarde ",
                open: true,
              }));
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          color: "error",
          message:
            "houve um erro ao tentar conectar com o servidor, tente novamente mais tarde ",
          open: true,
        }));
        console.log(err);
      });
  };

  return (
    <div>
      <div className={props.classesModal.body}>
        {loading && (
          <Loading size={80} position="absolute" height="40vh" width="30vw" />
        )}
        <h3>Confirme sua senha para cadastrar representante</h3>

        <InputPassword
          width="90%"
          label="Senha"
          placeholder="Senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => setPasswordError(empty(password))}
          error={passwordError.typeError}
        />
      </div>
      <div className={props.classesModal.footer}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.previousStep()}
        >
          Voltar
        </Button>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => registerAnalyst()}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};
