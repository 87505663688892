import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles';
import apiGraphql from "../../../services/api";

import ModalBase from '../modalBaseWithouFlow';

import empty from "../../validators/empty";
import Input from "../../input";
import TextArea from '../../textarea';
import InputCurrency from '../../inputCurrency/InputCurrencyFormat';
import SnackBar from '../../snackbar'
import "../style.css";
import * as exam from "../../store/actions/registerExams";
import Select from '../../select';
import SearchServiceProvided from '../../SearchServiceProvided'
import ModalUpdateExam from './updateExam'
import * as examsActions from "../../store/actions/registerExams";

const useStyles = makeStyles( {
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  }
})

const ModalRegisterExam = (props) => {
  
  useEffect(() => {
    apiGraphql({
      data: {
        query: `
            query Query($getTaxByType: TaxType!) {
               getTaxByType(type: $getTaxByType)
            }`,
        variables: {
          getTaxByType: "EXAM_FEE_PROVIDER"
        },
      },
    }).then((res) => {
      if(res.data.data.getTaxByType){
      
        const taxExamFee = res.data.data.getTaxByType
        console.log('TAX', taxExamFee)
        setExamFee(taxExamFee)
      }
  
    }).catch((err) => {
      console.log(err);
      
    });
    //eslint-disable-next-line
  },[])
  
  const dispatch = useDispatch();

  const classes = useStyles();

  
const [stateSnackBar, setStateSnackBar] = useState({
  open: false,
  vertical: "top",
  horizontal: "right",
  message: "",
});
const [examFee, setExamFee] = useState(0)
  
  const examExists = useSelector( (state) => state.Exams.examExists);

  const [examId, setExamId] = useState("");

  const [preparation, setPreparation] = useState("");
  const [preparationError, setPreparationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  
  const [examCode, setExamCode] = useState("");
  const [examCodeError, setExamCodeError] = useState("");

  const [name, setName] = useState("");
  
  const [examsFromApi, setExamsFromApi] = useState({});
  const [examError, setExamError] = useState("");
  const [examLoading, setExamLoading] = useState(false);


  const openModal = useSelector( (state) => state.Exams.openModal);
  
  let examData = useSelector((state) => state.Exams.exams);
  
  useEffect(() => {
    
    setExamCode(examsFromApi.code)
    setExamCodeError("")
    setExamId(examsFromApi.id)
    setName(examsFromApi.description)
    dispatch(exam.GetExam(examsFromApi.id))
    // eslint-disable-next-line
  }, [examsFromApi])


  function handleSubmit(e) {
    e.preventDefault();

    if(isNaN(price) || empty(price).typeError || empty(examCode).typeError || empty(preparation).typeError || empty(name).typeError)
          {         
            setExamCodeError(empty(examCode).typeError);
            setPriceError(empty('').typeError);
            setPreparationError(empty(preparation).typeError);
            
            return
          }
      const payload = { examCode, name, preparation, needScheduling: true, price, serviceProvidedId: examId };

        
        if(examExists > 0) { 
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: "Este exame já foi incluído na lista.",
          });
          console.log('EXAME DUPLICADO');
          return
        }
        dispatch(exam.RegisterExam(payload));

      dispatch(exam.openModal(false));
      dispatch(exam.alterActive(examData.length + 1));
      
      setExamCode("")
      setName("");
      setPreparation("");
      setPrice("");
      setExamCodeError("")
      setPriceError("")
      setPreparationError("")
      
      
      
      return true;
   
  }

  const handleClose = () => {
    
    setExamCode("")
    setName("");
    setPreparation("");
    setPrice("");
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")
    dispatch(examsActions.openModal(false));
  };

  return ( !openModal ? (
    <ModalBase 
      title="Cadastrar Exame"
      buttonWidth={props.buttonWidth}
      buttonText={props.buttonText}
      handleSubmit={handleSubmit}
      open={openModal}
      onClose={handleClose}
      disabled={props.disabled}>
      <Select
                        genericSelect={true}
                        label="Tabela"
                        InputProps={{readOnly: true}}                            
                        value={'TUSS'}
                        itemList={[
                          {value: "TUSS", exhibit: "TUSS" },
                        ]}
                      />
    
    <SearchServiceProvided
        onChange={setExamsFromApi}
        error={setExamError}
        isLoading={setExamLoading}
        value={name}
        
      />
      {Boolean(examError) && <span>{examError}</span>}
      {!examLoading && <span>Carregando exames...</span>}
    <Input
                width="100%"
                label="Código do exame"
                value={examCode}
                error={examCodeError}
                InputProps={{readOnly: true}} 
              />
         
     
    
       <InputCurrency
                width="100%"
                label="Valor em reais (R$)"
                value={price}
                onChange={setPrice}
                error={priceError}
                styles={{fontSize: '12px',
                marginTop: '3px',
                color: "#DA1414"}}
                dataTest="modal-cadastrar-consulta-input-preco"
              />
               <DialogContentText className={classes.lettering}>
                O valor cobrado pela plataforma será de: R$ {((examFee/100)*price).toFixed(2)}
              </DialogContentText>
          
              <TextArea
                width="93%"
                label="Preparo para o exame"
                placeholder="Instruções para o preparo"       
                rows={8}
                value={preparation}
                onChange={(e) => setPreparation(e.target.value)}
                onBlur={() => setPreparationError(empty(preparation).typeError)}
                error={preparationError}
                max={250}
              />
     
     <SnackBar
                stateSnackBar={stateSnackBar}
                setStateSnackBar={setStateSnackBar}
      />      
          
        
     
    </ModalBase>
  ): (<ModalUpdateExam/>));
};

export default ModalRegisterExam;
