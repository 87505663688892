import { makeStyles } from "@material-ui/core/styles";

const modal = makeStyles({
  root: {
    position: "absolute",
    marginBottom: "16px",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    width: "100%",
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : "474px"),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    height: "68px",
    alignItems: "center",
    borderBottom: "1px solid #E7E7ED",
    "& h1": {
      fontWeight: 600,
      fontSize: "1.28rem",
      color: "#272833",
      margin: "0 0 0 24px",
      padding: "0",
    },
  },
  body: {
    margin: "14px 0px 30px 24px ",
    maxHeight: "70vh",
    overflowY: "auto",
  },

  footer: {
    marginTop: "20px",
    minHeight: "83px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: " 0px 0px 10px 10px;",
    backgroundColor: "#F7F8F9",
    padding: "0 10% 0 0",

    "& button.download-table-xlsx-button": {
      backgroundColor: "#FFF",
      border: "1px solid #DF9000",
      color: "#DF9000",
      width: "22vw",
      maxWidth: "120px",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "1px",
      fontFamily: "roboto",
      "&:hover": {
        backgroundColor: "#F9B233",
        color: "#FFFFFF",
        transition: "0.34s",
      },
    },
  },
});

export { modal };
