import { makeStyles } from "@material-ui/core/styles";

const headerPageRegisterServices = makeStyles({
  root: {
    width: "100%",
    height: "94px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F5F5F5",
    marginBottom: "30px",
    "& div.title-address": {
      display:'flex',
      flexDirection:'column',
      margin: "0 0 0 6.25vw",
      "& h1": {
        color: "#272833",
        margin: "0",
        fontSize: "1.71rem",
        fontWeight: "bold",
      },
      "& h2": {
        margin: 0,
        color: "#393A4A",
        fontSize: "1.14rem",
        fontWeight: "600",
      },
    },

  },
  boxButton: {
    display: "flex",
    marginRight: "6.25vw",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const formTopic = makeStyles({
  root: {
    margin: (props) => (props.margin ? props.margin : "35px 0 20px 0"),
    display: "flex",
    flexDirection: "column",
    "& h1": {
      fontSize: "1.42rem",
      fontWeight: "bold",
      color: "#272833",
      margin: "0 0 4px 0",
      padding: 0,
    },
    "& h2": {
      fontSize: "1.16rem",
      fontWeight: 600,
      color: "#393A4A",
      margin: 0,
      padding: 0,
    },
    
  },
});

const contentPageRegisterServices = makeStyles({
  root: {
    marginTop: (props) => (props.marginTop ? props.marginTop : 0),
    display: "inline-flex",
    width: "100%",
    marginBottom: "64px",
  },
  boxMarginSide: {
    width: "100%",
    margin: "0 6.25vw",
  },
  boxTitleWithButton: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "24px",
  },
  boxTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& h1": {
      margin: 0,
      color: "#272833",
      fontSize: "1.42rem",
      fontWeight: "bold",
      "&.service-title": {
        fontSize: "1.7rem",
      }
    },
    "& h2": {
      margin: 0,
      color: "#393A4A",
      fontSize: "1.14rem",
      fontWeight: "600",
    },
  },
  boxButton: {
    width: "100%",
    display: "inline-flex",
    flexGrow: "1",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  boxServicesRegistered: {
    backgroundColor: "#F7F8F9",
    minHeight: "126px",
    padding: "20px 0",
  },
});

const addressRegistered = makeStyles({
  root: {
    minHeight: "64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E7E7ED",
    "& h3": {
      fontSize: "1rem",
      fontWeight: "normal",
      margin: "0 0 0 24px",
    },
    "& i": {
      position: "relative",
      marginRight: "24px",
      cursor: "pointer",
      color: "#6B6C7E",
    },
  },
  dropdown: {
    position: "absolute",
    top: 28,
    right: 0,
    left: 0,
    zIndex: 10,
    border: "1px solid",
    width: "100px",
    "& div": {
      borderBottom: "1px solid",
      height: "30px",
      minWidth: "100px",
      width: "100%",
      paddingBottom: "20px",
      color: "#000",
      backgroundColor: "#FFF",
    },
  },
});

export {
  headerPageRegisterServices,
  contentPageRegisterServices,
  addressRegistered,
  formTopic,
};
