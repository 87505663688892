import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputBase } from "@material-ui/core";
import empty from '../validators/empty';
import "./style.css";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    minWidth: 120,
    width: (props) => props.width,
    marginTop: "3px",
  },
  root: {
    borderRadius: 4,
    textIndent: "0.4rem",
    height: "3rem",
  },
});

export default (props) => {
  const classes = useStyles(props);
  return (
    <div className={`row first-field-topic ${props.classesNameRow || ""}`}>
      <div
        className={`col ${props.classesNameCol || ""} file-field input-field`}
      >
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <FormControl variant="outlined" className={classes.formControl}>
         {props.genericSelect 
          ? (<Select
            value={props.value}
            displayEmpty
            onChange={(e) => {
              props.onChange(e.target.value);
              props.onBlur(empty(e.target.value));
            }}
            input={<InputBase />}
            className={`selection ${classes.root}`}
            inputProps={{"data-test":props.dataTest}}
          >
            <MenuItem value="" selected disabled>Selecione</MenuItem>
            {
              props.itemList.map( (item, index) => {
                return (
                  <MenuItem
                    className={item.disabled ? classes.disabled : null}
                    key={`${index} ${item.value}`}
                    value={item.value}
                  >
                    {item.exhibit}
                  </MenuItem>
                )
              })
            }
          </Select>) :
          (
          <Select
            name={props.name}
            data-test={props.datatest}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            displayEmpty
            input={<InputBase />}
            className={`selection ${classes.root}`}
          >
            <MenuItem value="" selected disabled>
              Selecione
            </MenuItem>
            <MenuItem value="PRESENTIAL">
              Presencial
            </MenuItem>
            <MenuItem value="ONLINE">Online</MenuItem>
            <MenuItem value="PRESENTIAL_AND_ONLINE">
              Presencial e Online
            </MenuItem>
          </Select>
          )
}
          <span className="error-input">{props.error}</span>
        </FormControl>
          
      </div>
    </div>
  );
};
