import React, { memo } from "react";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";
import cnpjValidator from "../../../../common/validators/cnpj";
import emailValidator from "../../../../common/validators/email";
import maskCnpj from "../../../../common/mask/cnpj";

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const type = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj.typeStablishment
  );

  const {
    tradingName,
    setTradingName,
    tradingNameError,
    setTradingNameError,
  } = props;
  const {
    companyName,
    setCompanyName,
    companyNameError,
    setCompanyNameError,
  } = props;

  const { cnpj, setCnpj, cnpjError, setCnpjError } = props;

  const {
    companyEmail,
    setCompanyEmail,
    companyEmailError,
    setCompanyEmailError,
  } = props;

  const {
    ansRegistry,
    setAnsRegistry,
    ansRegistryError,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Dados da Empresa"
          subtitle="Precisamos de algumas informações da sua empresa para continuar"
        />
      </div>
      <Grid>
        <Grid item xs={10} md={4}>
          <Input
            name="input-tradingName-stablishmentData"
            datatest="input-tradingName-stablishmentData"
            width="100%"
            label="Nome Fantasia"
            placeholder="Nome Fantasia da Empresa"
            type="text"
            value={tradingName}
            onChange={(e) => setTradingName(e.target.value)}
            onBlur={() => setTradingNameError(empty(tradingName))}
            error={tradingNameError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={10} md={4}>
          <Input
            name="input-companyName-stablishmentData"
            datatest="input-companyName-stablishmentData"
            label="Razão Social"
            placeholder="Razão Social da Empresa"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onBlur={() => setCompanyNameError(empty(companyName))}
            error={companyNameError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={8} md={2}>
          <Input
            name="input-cnpj-stablishmentData"
            datatest="input-cnpj-stablishmentData"
            label="CNPJ"
            placeholder="Insira o CNPJ"
            type="text"
            value={maskCnpj(cnpj)}
            onChange={(e) => setCnpj(maskCnpj(e.target.value))}
            onBlur={() => setCnpjError(cnpjValidator(cnpj))}
            error={cnpjError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={9} md={3}>
          <Input
            name="input-companyEmail-stablishmentData"
            datatest="input-companyEmail-stablishmentData"
            label="Email da Empresa"
            placeholder="Insira o Email da empresa"
            type="text"
            value={companyEmail}
            onChange={(e) => setCompanyEmail(e.target.value)}
            onBlur={() => setCompanyEmailError(emailValidator(companyEmail))}
            error={companyEmailError.typeError}
          />
        </Grid>
      </Grid>
      {type === "health-operator" && (
        <Grid>
          <Grid item xs={9} md={3}>
            <Input
              name="input-ans-registry-stablishmentData"
              datatest="input-ans-registry-stablishmentData"
              label="Registro na ANS"
              placeholder="Insira seu registro na ANS"
              type="text"
              value={ansRegistry}
              onChange={(e) => setAnsRegistry(e.target.value)}
              error={ansRegistryError.typeError}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
});
