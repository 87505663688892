import React, { forwardRef, useState } from "react";
import ReactExport from "react-export-excel";

import Select from "./select";

import Button from "../../../../common/button";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { modal } from "./style";

export default forwardRef((props, ref) => {
  const classesModal = modal();

  const { report } = props;

  const [typeFile, SetTypeFile] = useState("csv");

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const downloadCsv = (data) => {
    const blob = new Blob([data], {
      type: "text/csv;charset=utf-18",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Relatorio_Vida_Connect.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const objectToCsv = () => {
    const fileRows = [];
    const headers = Object.keys(report[0]);
    const headerInFile = Object.keys({
      Paciente: "",
      Carteirinha: "",
      Convênio: "",
      Status: "",
      "V. do Serviço": "",
      "Taxa V.C.": "",
      "Solicitado Em": "",
    });
    fileRows.push(headerInFile.join(";"));

    for (const row of report) {
      const values = headers.map((header) => {
        return `"${row[header]}"`;
      });
      fileRows.push(values.join(";"));
    }

    const formatedFileRows = fileRows.join("\n");

    downloadCsv(formatedFileRows);
  };

  return (
    <div className={classesModal.root}>
      <div className={classesModal.title}>
        <h1>Exportar dados</h1>
        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classesModal.body}>
        <Select
          label="Formato do arquivo"
          value={typeFile}
          onChange={(e) => SetTypeFile(e.target.value)}
        />
      </div>
      <div className={classesModal.footer}>
        <Button
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          Cancelar
        </Button>
        {typeFile === "csv" && (
          <Button
            id="button-export"
            backgroundColor="#FFF"
            border="1px solid #DF9000"
            color="#DF9000"
            width="22vw"
            maxWidth="120px"
            onClick={objectToCsv}
          >
            Confirmar
          </Button>
        )}
        {typeFile === "xlsx" && (
          <ExcelFile
            filename="Relatorio_Vida_Connect"
            element={
              <button className="download-table-xlsx-button">Confirmar</button>
            }
          >
            <ExcelSheet data={report} name="Relatório_Vida_Connect">
              <ExcelColumn label="Paciente" value="patient" />
              <ExcelColumn label="Carteirinha" value="membershipCard" />
              <ExcelColumn label="Convênio" value="plan" />
              <ExcelColumn label="Status" value="status" />
              <ExcelColumn label="V. do Serviço" value="price" />
              <ExcelColumn label="Taxa V.C." value="tax" />
              <ExcelColumn label="Solicitado em" value="createdAt" />
            </ExcelSheet>
          </ExcelFile>
        )}
      </div>
    </div>
  );
});
