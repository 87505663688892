import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import "./styles.css";

class LocalizedUtils extends DateFnsUtils {
  getWeekdays() {
    return ["D", "S", "T", "Q", "Q", "S", "S"];
  }
}

const StaticDatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBR}>
      <DatePicker
        disableToolbar
        autoOk
        variant="static"
        openTo="date"
        value={props.selectedDate}
        onChange={(date) => props.onPick(date)}
        minDate={props.interval.start}
        maxDate={props.interval.end}
        minDateMessage="A data escolhida está fora da agenda selecionada."
        maxDateMessage="A data escolhida está fora da agenda selecionada."
      />
    </MuiPickersUtilsProvider>
  );
};

export default StaticDatePicker;
