import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'

import HorizontalList from '../../common/horizontalList'
// import Schedules from './tabs/schedules'
import Procedures from './tabs/procedures'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#F7F8F9',
    padding: '30px'
  },
}))

export default () => {
  const classes = useStyles()

  const [selectedCategory, setCategory] = useState(0)
  const listOptions = [
    // { text: 'Agendamento' },
    { text: 'Orçamento' },
  ]

  return (
    <div className={classes.root}>
      <h1>Solicitações</h1>

      <HorizontalList
        width="90%"
        listItems={listOptions}
        setSelectedList={setCategory}
        selectedList={selectedCategory}
      />

      { selectedCategory === 0 &&
        <Procedures />
      }

      {/* { selectedCategory === 1 &&
        <Schedules />
      } */}

    </div>
  )
}
