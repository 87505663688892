import React, { useState } from "react";

import CrudExam from "./crud-exam";
import ChooseUnits from "../common/choose-units";

export default (props) => {

  const [ alreadyChooseUnit, setAlreadyChooseUnit ] = useState(false);
  const [ unitInformations, setUnitInformations ] = useState({})

  return (
    <div className="consultations">
      {!alreadyChooseUnit && (
        <ChooseUnits
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          setUnitInformations={setUnitInformations}
          title= 'Exames'
          subtitle='Selecione uma unidade para ver os exames cadastrados'
        />
      )}
      {alreadyChooseUnit && (
        <CrudExam
          setAlreadyChooseUnit={setAlreadyChooseUnit}
          unitInformations={unitInformations}
        />
      )}
    </div>
  );
};
