import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import InputFile from "../../../../common/fileInput";

import inputFileValidator, { validationFileType } from '../../../../common/validators/fileInput'

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();
  const { logo, setLogo, logoError, setLogoError, setStateSnackBar } = props;

   const handleChangeInputFile = (value, onChange, typesAcceptedExtension) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type, typesAcceptedExtension)
    if (!typeIsAccpted) {
      onChange({name: ''})
      setStateSnackBar( (state) =>({
        ...state,
        open: true,
        message:
          "O tipo do arquivo selecionado não é aceito para esse campo!",
      }))
      return
    } 
    return onChange(value)
  }

  const typesAcceptedExtension = ['image/png', 'image/jpg', 'image/jpeg']


  return (
    <React.Fragment>
      <div className="row">
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Logotipo da sua Marca"
          subtitle=" O logotipo da empresa será usado dentro do aplicativo do
              paciente para facil identificação da sua empresa."
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={9} md={3}>
          <InputFile
            name="input-logo-logo"
            datatest="input-logo-logo"
            id="stablishment-logo"
            width="100%"
            label="Logotipo"
            subtitle="Formatos aceitos: png, jpg e jpeg. Tamanho máximo 20MB - A foto deve ser quadrada."
            accept=".png, .jpg, .jpeg"
            value={logo.name}
            onChange={(e) =>  handleChangeInputFile(e.target.files[0], setLogo, typesAcceptedExtension)}
            onBlur={() =>
              setLogoError(inputFileValidator(logo, 20))
            }
            error={logoError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
