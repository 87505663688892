const INITIAL_STATE = {    
    unitData: {},
  };

  const unitsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){

    case "SET_UNIT_DATA":
        return {
            ...state,
            unitData: action.payload,
            };
    default:
        return state;
    }
  };

  export default unitsReducer;