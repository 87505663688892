import React from 'react'
import { makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  list: {
      display: 'flex',
      width: props => props.width ? props.width : '100%',
      justifyContent: 'flex-start',
      alignItems: "center",
  },
  listItem: {
    width: 'auto',
    paddingLeft: '15px',
    paddingRight: '15px',
    "& > span": {
        textAlign: 'center',
        fontSize: '14px'
    },
  },
  selected: {
    backgroundColor: 'transparent !important',
    borderBottom: '2px solid #FEC65A',
    "& > div > span" :{
        fontWeight: '600 !important',
    }
  },
}))

export default (props) => {
  const classes = useStyles(props)

  const handleListItemClick = (index) => {
    props.setSelectedList(index);
  };

  return(
    <div className={classes.root}>
      <List 
        component="ul" 
        aria-label="main mailbox folders"
        classes={{
          root: classes.list
        }}
      >
        {props.listItems.map((item, index) => {
          return (
            <ListItem
              key={item.text}
              button
              selected={props.selectedList === index}
              onClick={(event) => handleListItemClick(index)}
              classes={{root:classes.listItem, selected: classes.selected}}
            >
              <ListItemText primary={item.text} classes={{root: classes.listItem}}/>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}