const calcTax = ({ tax, price, sum = false }) => {
  if (isNaN(Number(price))) throw new Error('O preço não é um número válido')
  if (isNaN(Number(tax))) throw new Error('A taxa não é um número válido')
  if (tax === 0 && !sum) return 0
  if (tax === 0 && sum) return price

  const dividedTax = tax > 0 ? tax / 100 : 0
  const calculedTax = Number(dividedTax * price)
  if (!sum) return calculedTax?.toFixed(2)
  return Number(calculedTax + Number(price))?.toFixed(2)
}

export default calcTax
