import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "column",
    width: (props) => props.width,
  },
  input: {
    display: "none",
  },
  lookLikeInput: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid #e7e7ed",
    borderRadius: "4px",
    width: "100%",
    padding: "6px 0",
    overflow: "hidden",

    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  label: {
    textIndent: "0.4rem",
    textAlign: "left",
    width: "100%",
    height: "25px",
    margin: 0,
    fontSize: "16px",
    textTransform: "none",
  },
}));

export default function FileInput(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <span>
        <strong className="label-input">{props.label}</strong>
      </span>
      {props.subtitle && <span><small>{props.subtitle}</small></span>}
      <input
        name={props.name}
        data-test={props.datatest}
        className={classes.input}
        id={props.id}
        type="file"
        accept={props.accept || ".pdf, .doc, .docx, .jpg, .png, .jpeg, .txt"}
        onChange={props.onChange}
        disabled={props.disabled || false}
      />
      <label htmlFor={props.id} style={{ margin: "3px 0 0 0" }}>
        <Button
          variant="contained"
          component="span"
          onBlur={props.onBlur}
          classes={{
            contained: classes.lookLikeInput,
          }}
        >
          <span className={classes.label}>
            {props.value.length ? (
              props.value
            ) : (
              <span className="light-grey-text">Selecione um documento</span>
            )}
          </span>
        </Button>
      </label>
      <span className="error-input">{props.error}</span>
    </div>
  );
}
