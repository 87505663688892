import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import InputFile from "../../../../common/fileInput";

import inputFileValidator, { validationFileType } from '../../../../common/validators/fileInput'

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const {
    socialContract,
    setSocialContract,
    socialContractError,
    setSocialContractError,
  } = props;

  const {
    cnpjAttachment,
    setCnpjAttachment,
    cnpjAttachmentError,
    setCnpjAttachmentError,
  } = props;

  const {
    companyResponsibleAttachment,
    setCompanyResponsibleAttachment,
    companyResponsibleAttachmentError,
    setCompanyResponsibleAttachmentError,
  } = props;

  const {
    technicianResponsibleDocumentAttachment,
    setTechnicianResponsibleDocumentAttachment,                                                   
    technicianResponsibleDocumentAttachmentError,
    setTechnicianResponsibleDocumentAttachmentError,
  } = props;

  const {
    proofResidence,
    setProofResidence,
    proofResidenceError,
    setProofResidenceError,
  } = props;

  const { setStateSnackBar } = props

  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      setStateSnackBar( (state) =>({
        ...state,
        open: true,
        message:
          "O tipo do arquivo selecionado não é aceito para esse campo!",
      }))
      return
    } 
    return onChange(value)
  }

  const typesAcceptedExtension = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

  React.useEffect(() => {
    console.log(socialContract)
  },[socialContract])


  return (
    <React.Fragment>
      <div className="row">
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Anexos"
          subtitle="Precisamos que você envie alguns documentos:"
        />
      </div>
      <Grid>
        <Grid item xs={9} md={4}>
          <InputFile
            name="input-socialContract-attachment"
            datatest="input-socialContract-attachment"
            width="100%"
            id="social-contract"
            label="1.Contrato social"
            subtitle="Formatos aceito: png, jpg, jpeg, pdf, docx, doc e txt. Tamanho máximo 25MB"
            accept=".png, .jpg, .jpeg, .pdf, .docx, .doc, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setSocialContract, typesAcceptedExtension)  
            }
            value={socialContract.name}
            onBlur={() =>
              setSocialContractError(inputFileValidator(socialContract, 25))
            }
            error={socialContractError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={9} md={4}>
          <InputFile
            name="input-cnpjAttachment-attachment"
            datatest="input-cnpjAttachment-attachment"
            width="100%"
            id="proof-of-registration"
            label="2.Comprovante de Inscrição e Situação Cadastral - CNPJ"
            subtitle="Formatos aceito: png, jpg, jpeg, pdf, docx, doc e txt. Tamanho máximo 25MB"
            accept=".png, .jpg, .jpeg, .pdf, .docx, .doc, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setCnpjAttachment, typesAcceptedExtension)
            }
            value={cnpjAttachment.name}
            onBlur={() =>
              setCnpjAttachmentError(inputFileValidator(cnpjAttachment, 25))
            }
            error={cnpjAttachmentError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={9} md={4}>
          <InputFile
            name="input-companyResposibleAttachment-attachment"
            datatest="input-companyResposibleAttachment-attachment"
            width="100%"
            id="legal-guardian-document"
            label="3.Documento do responsável legal"
            subtitle="Formatos aceito: png, jpg, jpeg, pdf, docx, doc e txt. Tamanho máximo 25MB"
            accept=".png, .jpg, .jpeg, .pdf, .docx, .doc, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setCompanyResponsibleAttachment, typesAcceptedExtension)
            }
            value={companyResponsibleAttachment.name}
            onBlur={() =>
              setCompanyResponsibleAttachmentError(inputFileValidator(companyResponsibleAttachment, 25))
            }
            error={companyResponsibleAttachmentError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={9} md={4}>
          <InputFile
            name="input-technicianResponsibleDocumentAttachment-attachment"
            datatest="input-technicianResponsibleDocumentAttachment-attachment"
            width="100%"
            id="technicia-responsible-document"
            label="4.Documento do responsável técnico/diretor técnico"
            subtitle="Formatos aceito: png, jpg, jpeg, pdf, docx, doc e txt. Tamanho máximo 25MB"
            accept=".png, .jpg, .jpeg, .pdf, .docx, .doc, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setTechnicianResponsibleDocumentAttachment, typesAcceptedExtension)
            }
            value={technicianResponsibleDocumentAttachment.name}
            onBlur={() =>
              setTechnicianResponsibleDocumentAttachmentError(inputFileValidator(technicianResponsibleDocumentAttachment, 25))
            }
            error={technicianResponsibleDocumentAttachmentError.typeError}
          />
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={9} md={4}>
          <InputFile
            name="input-proofResidence-attachment"
            datatest="input-proofResidence-attachment"
            width="100%"
            id="proof-of-residence"
            label="5.Comprovante de endereço"
            subtitle="Formatos aceito: png, jpg, jpeg, pdf, docx, doc e txt. Tamanho máximo 25MB"
            accept=".png, .jpg, .jpeg, .pdf, .docx, .doc, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setProofResidence, typesAcceptedExtension)
            }
            value={proofResidence.name}
            onBlur={() =>
              setProofResidenceError(inputFileValidator(proofResidence, 25))
            }
            error={proofResidenceError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
