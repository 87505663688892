import { makeStyles } from "@material-ui/core/styles";

const laytoutPageHealthOperatorReports = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    padding: "30px",
    "& div.title h1": {
      margin: 0,
      fontSize: "1.71rem",
      fontWeight: 600,
      color: "#272833",
    },
    "& div.header": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& div.date-range": {
        display: "flex",
        flexWrap: "wrap",
        "& span.label": {
          margin: "0 15px",
          fontWeight: 600,
        },
        "& div.select-date": {
          display: "flex",
          alignItems: "center",
          "& div": {
            marginTop: 0,
          },
          "& button": {
            width: "80px",
          },
        },
      },
    },
    "& div.list-services": {
      marginTop: "15px",
      "& div.MuiTableContainer-root": {
        margin: "0 !important",
        overflowY: "hidden",
      },
      "& th": {
        fontWeight: "normal ",
        color: "#6B6C7E",
        border: 0,
      },
    },
    "& tbody": {
      backgroundColor: "#fff",
      border: "1px solid rgba(224, 224, 224, 1)",
      "& tr.MuiTableRow-root": {
        height: "64px !important",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.02)",
        },
      },
    },
  },
});

export { laytoutPageHealthOperatorReports };
