import React, { useState, useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Button from '../button';

import "./style.css";

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  }
})

// Component

const ModalBase = (props) => {

// Opening and Closing the Modal
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);

  useEffect( () => {
    if(props.open) {
      setOpen(true);
    }
  },[props.open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(props.keepState) {
      props.keepState(false)
    };
    if(props.onClose){
      props.onClose();
    }
  };

  const composedHandleSubmit = async (e) => {
      if(await props.handleSubmit(e)){
        setOpen(false);
      };
  };

  return (
    <div>
        {!props.dontShowButton && <Button
          width={props.buttonWidth || "168px"}
          border= {props.buttonBorder || "1px solid #CDCED9"}
          margin = {props.buttonMargin}
          backgroundColor="#FFFFFF"
          color= {props.buttonColor || "#6B6C7E"}
          className="modal-trigger space-above-item-less"
          data-target="modal-register-consultation"
          onClick={handleClickOpen}
          disabled={props.disabled || false}
        >
        {props.buttonText || "Adicionar"}
      </Button>}
      <Dialog 
        open={open}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{enter: 200, exit: 0}}
      >
        <div className={classes.header}>
          <DialogTitle>
            {props.title}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form onSubmit={props.handleSubmit}>
            <div className="modal-body">
              {props.children}
            </div>
            <DialogActions className={classes.extraMargin}>
              <Button 
                onClick={handleClose}
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
              >
                Cancelar
              </Button>
              <Button 
                onClick={composedHandleSubmit}
                width="120px"
                border="1px solid #DF9000"
                backgroundColor="#FFFFFF"
                color="#DF9000"
              >
                Salvar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalBase;