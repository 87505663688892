import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Schedule from "./schedule";

export default (props) => {
  const userInformation = useSelector((state) => state.ScheduleReducer);
  const doctorName = userInformation.userName;
  const doctorStatus = userInformation.userStatus;
  const history = useHistory();

  useEffect(() => {
    if (!doctorStatus || !doctorName) history.push("/administrador/profissionais");
  }, [doctorName, doctorStatus, history]);

      return (
        <Schedule
          employeeId={props.computedMatch.params.id}
          doctorName={doctorName}
          unitId={props.computedMatch.params.unitId}
        />
      );
};
