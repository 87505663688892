import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import apiGraphql from "../../../../services/api";

import { Link } from "react-router-dom";

import Loading from "../../../../common/circular-progress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "../../../../common/button";
import Modal from "@material-ui/core/Modal";

import Pagination from "../../../../common/pagination";
import ModalInviteDoctors from "../modal/inviteDoctors";
import ModalEditDoctor from "../modal/editDoctor";
import ModalInactiveOrActiveDoctor from "../modal/inactivateOrActivateDoctor";

import * as scheduleActions from "../../../../common/store/actions/schedule.action";
import * as unitsActions from '../../../../common/store/actions/units.action'
import calendar from "../../../../assets/calendar.svg";

export default ({unitInformations, ...props}) => {
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [qtyPages, setQtyPages] = useState(1);
  const [showMinimumNumber, setShowMinimunNumber] = useState(0);
  const [showMaxNumber, setShowMaxNumber] = useState(0);
  const [openModalInviteDoctors, setOpenModalInviteDoctors] = useState(false);
  const [openModalEditDoctor, setOpenModalEditDoctor] = useState(false);
  const [
    openModalInactiveOrActiveDoctor,
    setOpenModalInactiveOrActiveDoctor,
  ] = useState(false);
  const [openDrodown, setOpenDropDown] = useState(false);
  const [dropDownActive, setOpenDropDownActive] = useState(0);
  const [providerId, setProviderId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [
    propsModalActiveOrInactiveDoctor,
    setPropsModalActiveOrInactiveDoctor,
  ] = useState({});

  const [listProfessionals, setListProfessionals] = useState([]);

  const statusNameInPortugese = (status) => {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "ACTIVE":
        return "Ativo";
      case "INACTIVE":
        return "Inativo";
      default:
        return status;
    }
  };

  const getListProfessionals = useCallback(() => {
    setLoading(true);
    
    apiGraphql({
      data: {
        query: `
        query listEmployees($paginationDto:PaginationDto!, $unitId: String!){
          listEmployees(paginationDto:$paginationDto, unitId:$unitId){
            data{
              providerId
              userId
              name
              status
              employeeId
       

              providerHasSpecialities {
                id
                specialityId
                active
                status
                rqe
                rqeAttachment
                unitId
                price
              } 
            }
            totalCount
          }
        }
        `,
        variables: {
          paginationDto: { page, limit },
          unitId: unitInformations.id
        },
      }
    })
      .then((res) => {
        setListProfessionals(res.data.data.listEmployees.data);
        setCount(res.data.data.listEmployees.totalCount);
        setShowMaxNumber(limit * page);
        setShowMinimunNumber(page * limit - limit + 1);
        setQtyPages(
          count % limit === 0
            ? Math.floor(count / limit)
            : Math.floor(count / limit) + 1
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [page, limit, count, unitInformations.id]);

  useEffect(() => {
    getListProfessionals();
  }, [getListProfessionals]);

  const handleOpenModalInviteDoctors = () => {
    setOpenModalInviteDoctors(true);
  };
  const handleCloseModalInviteDoctors = () => {
    setOpenModalInviteDoctors(false);
  };

  const handleOpenModalEditDoctor = () => {
     setOpenModalEditDoctor(true);
    //setOpenModalEditDoctor(false);
  };

  const handleCloseModalEditDoctor = () => {
    setOpenModalEditDoctor(false);
  };

  const handleOpenModalInactiveOrActiveDoctor = () => {
    setOpenModalInactiveOrActiveDoctor(true);
  };

  const handleCloseModalInactiveOrActiveDoctor = () => {
    setOpenModalInactiveOrActiveDoctor(false);
  };

  const handleCloseDropDown = () => {
    if (openDrodown) setOpenDropDown(false);
  };

  return (
    <React.Fragment>
      {loading && <Loading size={90} width="80vw" />}
      {!loading && (
        <div className="professionals" onClick={() => handleCloseDropDown()}>
          <div className="header-consultation">
          
          <div>
              <Button
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                fontSize="1 rem"
                width="100vw"
                maxWidth="91px"
                onClick={() => props.setAlreadyChooseUnit(false)}
              >
                <NavigateBeforeIcon />
                Voltar
              </Button>
              <h1>
                {unitInformations.address}
                {unitInformations.number
                  ? ",  nº" + unitInformations.number
                  : ""}
              </h1>
            </div>   
            <Button
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              fontFamily="Montserrat"
              fontSize="1 rem"
              width="100vw"
              maxWidth="195px"
              onClick={() => handleOpenModalInviteDoctors()}
            >
              Adicionar Profissional
            </Button>
          </div>
          <div className="list-Professionals">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {listProfessionals.map((professional, index) => (
                    <TableRow key={index}>
                      <TableCell>{professional.name}</TableCell>
                      <TableCell>
                        <div
                          className={`status-proffesional ${professional.status.toLowerCase()}`}
                        >
                          {statusNameInPortugese(professional.status)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          className="actions-professionals"
                          onClick={() => {
                            dispatch(
                              scheduleActions.addUserStatus(professional.status)
                            );
                            dispatch(
                              scheduleActions.addUserName(professional.name)
                            );
                          }}
                        >
                          <Link onClick={() => {dispatch(unitsActions.setUnitData(unitInformations));}}
                            to={`/administrador/profissionais/agenda/${professional.employeeId}/${unitInformations.id}`}
                          >
                            <img src={calendar} alt="calendario" />
                          </Link>
                          <i
                            onClick={() => {
                              setOpenDropDownActive(index);

                              setOpenDropDown(!openDrodown);
                            }}
                          >
                            <MoreVertIcon />

                            {openDrodown && dropDownActive === index && (
                              <div className="professionals-admin-dropDown">
                                {professional.status !== "INACTIVE" && (
                                  <div
                                    onClick={() => {
                                      setProviderId(professional.providerId);
                                      setEmployeeId(professional.employeeId);
                                      handleOpenModalEditDoctor();
                                    }}
                                  >
                                    <span>Editar</span>
                                  </div>
                                )}

                                {professional.status !== "PENDING" && (
                                  <div
                                    onClick={() => {
                                      setEmployeeId(professional.employeeId);
                                      handleOpenModalInactiveOrActiveDoctor();
                                      setPropsModalActiveOrInactiveDoctor(
                                        professional
                                      );
                                    }}
                                  >
                                    <span>
                                      {professional.status === "ACTIVE"
                                        ? "Inativar"
                                        : "Ativar"}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </i>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="footer">
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              showMinimumNumber={showMinimumNumber}
              showMaxNumber={showMaxNumber}
              count={count}
              qtyPages={qtyPages}
            />
          </div>

          <Modal
            open={openModalInviteDoctors}
            onClose={handleCloseModalInviteDoctors}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModalInviteDoctors
              handleClose={handleCloseModalInviteDoctors}
              getListProfessionals={getListProfessionals}
            />
          </Modal>

          <Modal
            open={openModalEditDoctor}
            onClose={handleCloseModalEditDoctor}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModalEditDoctor
              handleClose={handleCloseModalEditDoctor}
              page={page}
              limit={limit}
              employeeId={employeeId}
              professional={listProfessionals.find(item => item.providerId === providerId)}
              unitInformations={unitInformations}
            />
          </Modal>

          <Modal
            open={openModalInactiveOrActiveDoctor}
            onClose={handleCloseModalInactiveOrActiveDoctor}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModalInactiveOrActiveDoctor
              handleClose={handleCloseModalInactiveOrActiveDoctor}
              name={propsModalActiveOrInactiveDoctor.name}
              status={propsModalActiveOrInactiveDoctor.status}
              employeeId={employeeId}
              getListProfessionals={getListProfessionals}
            />
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};
