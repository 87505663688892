import React from 'react'

import Button from '../button'

export default () => {
  const handleClick = () => {
    if (process.env.REACT_APP_EXAMS_SPREADSHEET) window.open(process.env.REACT_APP_EXAMS_SPREADSHEET, '_blank')
  }

  return (
    <Button
      backgroundColor="#FFF"
      border="1px solid #CDCED9"
      color="#6B6C7E"
      fontFamily="Montserrat"
      fontSize="1 rem"
      width="100vw"
      maxWidth="190px"
      maxHeight="40px"
      margin="0 5px 0 0"
      onClick={handleClick}
      children="Baixar planilha"
    />
  )
}
