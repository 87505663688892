import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete"
import Pagination from '../../../common/pagination';
import Button from './../../../common/button'
import DialogToCreateNewEstablishmentHasHealthOperator from './dialog-to-create-new-establishment-has-health-operator';
import ConfirmDialog from './../../../common/confirmDialog'
import Alert from '@material-ui/lab/Alert';
import apiGraphql from "../../../services/api";

const useStyles = makeStyles({
  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF'
    }
  },
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
  header:{
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: "1.71rem",
      color: "#272833",
      fontWeight: 600,
    },
    "& h2": {
      
      margin: "11px 0 0 0",
      padding: 0,
      fontSize: "1.14rem",
      color: "#000",
      fontWeight: "normal",
    },
  }
})

const themeCircularProgress = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});


const QUERY_LIST_ESTABLISHMENT_HAS_HEALTH_OPERATORS = `
  query ListEstablishmentHasHealthOperators($establishmentId: ID!, $page: Float, $limit: Float) {
    listEstablishmentHasHealthOperators(establishmentId: $establishmentId, page: $page, limit: $limit) {
      count
      rows {
        id
        establishmentId
        planId
        active
        plans {
          id
          healthOperator {
            id
            fantasyName
            companyName
          } 
        }
      }
    } 
  }
`

const QUERY_TO_GET_HEALTH_OPERATORS = `
  query {
    getAllHealthOperators {
      id
      plans {
        id
        name
      }
      fantasyName
      companyName
    } 
  }
`
 
const MUTATION_CREATE_ESTABLISHMENT_HAS_HEALTH_OPERATOR = `
  mutation CreateEstablishmentHasHealthOperator($planId: ID!) {
    createEstablishmentHasHealthOperator(planId: $planId) 
  }
`

export default () => {
  const classes = useStyles();

  const [_loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const firstItem = (page * itemsPerPage) - (itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [healthOperators, setHealthOperators] = useState([]);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  const [infoMessage, setInfoMessage] = useState({ message: '', severity: '' })
  const establishmentId = localStorage.getItem('Establishment-ID-Vida-Connect')


   useEffect(() => {
     apiGraphql({
       data:{
        query: QUERY_TO_GET_HEALTH_OPERATORS,
       }
     }).then(res => {
      setHealthOperators(res.data.data?.getAllHealthOperators?.map(i => ({ ...i, plans: i.plans[0], planId: i.plans[0].id })) || [])
     })
    
  }, []) 

  useEffect(() => {
    setLoading(true)
    
    apiGraphql({
      data: {
        query: QUERY_LIST_ESTABLISHMENT_HAS_HEALTH_OPERATORS,
        variables: {
          establishmentId: establishmentId,
          page: page,
          limit: itemsPerPage
        },
      },
    })
      .then((res) => {
       if(res.data.data){
        setRows(res.data.data?.listEstablishmentHasHealthOperators?.rows)
        setCount(res.data.data?.listEstablishmentHasHealthOperators?.count)
     
       }
       setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [establishmentId, page, itemsPerPage])


  const showLoading = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <ThemeProvider theme={themeCircularProgress}>
          <CircularProgress color={'primary'} size={50} />
        </ThemeProvider>
      </div>
    )
  }

  const handleClickOpenModal = () => setOpenModal(true)

  
  const handleClickNewHealthOperator = (item) => {
    if (!Boolean(item.planId)) return
    setLoading(true)
    const planId = item.planId
    apiGraphql({
      data: {
        query: MUTATION_CREATE_ESTABLISHMENT_HAS_HEALTH_OPERATOR,
        variables: { planId }
      }
    })
      .then((res) => {
        
        if(res.data.errors){
        setInfoMessage({ message: 'Este estabelecimento já atende este plano.', severity: 'error' })
        setOpenModal(false)
        setLoading(false)
        return
        }
        setCount(rows.length + 1)
        setRows([...rows, { ...item, plans: { ...item.plans, healthOperator: { ...item, plans: undefined } } }])
        setInfoMessage({ message: 'Convênio cadastrado com sucesso', severity: 'success' })
        setOpenModal(false)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const clearAlert = (time = 10000) => setTimeout(() => {setInfoMessage({ message: '', severity: '' })}, time)

  const removeEstablishmentHasPlan = async (planId) => {

    const MUTATION_REMOVE_ESTABLISHMENT_HAS_HEALTH_OPERATOR = `
  mutation RemoveEstablishmentHasHealthOperator($planId: ID!) {
    removeEstablishmentHasHealthOperator(planId: $planId)
  }
`

     return apiGraphql({
        data: {
          query: MUTATION_REMOVE_ESTABLISHMENT_HAS_HEALTH_OPERATOR,
          variables: {
            establishmentId,
            planId
          },
        },
      })
  }

  const handleConfirmDeleteModal = async (item) => {
    if (!Boolean(item?.planId)) return
    
    setLoading(true)

      removeEstablishmentHasPlan(item?.planId).then((res) => {
      
        if(res.data.removeEstablishmentHasHealthOperator){
          setInfoMessage({ message: 'Erro ao deletar convênio', severity: 'error' })
          setOpenConfirmDeleteModal(false)
          setLoading(false)
          return
        }
        const rowsUpdated = rows.filter(i => i.id !== item.id)
        setCount(rows.length - 1)
        setRows(rowsUpdated)
        
        setInfoMessage({ message: 'Convênio deletado com sucesso', severity: 'success' })
        setOpenConfirmDeleteModal(false)
        setLoading(false)
        clearAlert()
      }).catch(err => {
        setInfoMessage({ message: 'Erro ao deletar convênio', severity: 'error' })
        setOpenConfirmDeleteModal(false)
        setLoading(false)
      })
    
    
  }

  const handleClickDeleteIcon = (row) => {
    setOpenConfirmDeleteModal(true)
    setRowClicked(row)
  }

  const handleCancelDelete = () => {
    setOpenConfirmDeleteModal(false)
    setRowClicked({})
  }

  return (
    <div className={classes.screen}>
      <div className={classes.root}>
        <div className={classes.table}>
          
         <div className={classes.header}>
            <h1>Convênios</h1> 
              <Button
                onClick={handleClickOpenModal}
                width="200px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
              >
                Cadastrar convênio
                </Button>
          </div>
          <div className={classes.header}>
          <h2>Convênios que você atende.</h2>  
          </div>

          {
            _loading ? showLoading() :
              <div style={{ marginTop: 10 }}>
                {Boolean(infoMessage.message) && <Alert elevation={2} severity={infoMessage.severity}>{infoMessage.message}</Alert>}
              </div>
          }

          {_loading ? showLoading() :
            <>
            
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome social da operadora</TableCell>
                      <TableCell>Nome fantasia da operadora</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.plans.healthOperator.companyName}
                        </TableCell>
                        <TableCell>
                          {row.plans.healthOperator.fantasyName}
                        </TableCell>
                        <TableCell>
                          {
                            
                              <DeleteIcon
                                onClick={() => handleClickDeleteIcon(row)}
                                color='error'
                                style={{ cursor: 'pointer' }}
                              />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </div>
        <div className={classes.pagination}>
       
          <Pagination 
          page={page}
          setPage={setPage}
          limit={itemsPerPage}
          setLimit={setItemsPerPage}
          showMinimumNumber={firstItem}
          showMaxNumber={lastItem}
          count={count}
          qtyPages={Math.floor(count / itemsPerPage)}
        />
        </div>
        <div>
          <DialogToCreateNewEstablishmentHasHealthOperator
            open={openModal}
            handleClose={() => setOpenModal(false)}
            select={handleClickNewHealthOperator}
            healthOperators={healthOperators}
          />
        </div>
        <div>
          <ConfirmDialog
            open={openConfirmDeleteModal}
            handleCancel={handleCancelDelete}
            handleConfirm={handleConfirmDeleteModal}
            title="Deseja realmente excluir?"
            contentText="Deseja realmente excluir este convênio? Pacientes não irão mais conseguir agendar consultas com você através deste convênio"
            item={rowClicked}
          />
        </div>
      </div>
    </div>
  )
}
