import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "../../assets/pencil.svg";
import EditIconHighlight from "../../assets/pencil_highlight.svg";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : "100%"),
    padding: "0",
    margin: (props) => (props.margin ? props.margin : "auto"),
  },
  item: {
    height: (props) => (props.height ? props.height : "64px"),
    backgroundColor: (props) =>
      props.backgroundColor ? props.backgroundColor : "#FFFFFF",
    padding: (props) => (props.padding ? props.padding : "0 30px"),
    border: (props) => (props.border ? props.border : "1px solid #E7E7ED"),
    fontSize: (props) => (props.fontSize ? props.fontSize : null),
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: "30px",
  },
  selected: {
    backgroundColor: "#FFF8DE !important",
    borderRadius: "17px",
    border: "none !important",
    color: "#DF9000",
    "& > div > button > span ": {
      backgroundImage: `url(${EditIconHighlight})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "auto",
      backgroundColor: "#FFF8DE",
    },
  },
}));

const ListDisplay = (props) => {
  const classes = useStyles(props);

  const editItem = (index) => {
    props.editItem(index);
  };

  return (
    <List className={classes.root}>
      {props.fullList.map((value, index) => {
        const labelId = `checkbox-list-label-${index}-${
          props.parameterToExhibit ? value[props.parameterToExhibit] : value
        }`;
        return (
          <ListItem
            className={classes.item}
            key={labelId}
            role={undefined}
            dense
            button
            onClick={() => {
              props.setSelected(index);
              props.onPick(value[props.firstParam]);
            }}
            selected={props.selected === index}
            classes={{
              selected: classes.selected,
            }}
          >
            <ListItemText
              id={labelId}
              primary={`${format(
                value[props.firstParam],
                "dd/MM/yyyy"
              )} - ${format(value[props.secondParam], "dd/MM/yyyy")}`}
            />
            <div className={classes.icons}>
              <IconButton
                className={classes.icon}
                edge="end"
                aria-label="delete"
                onClick={() => editItem(index)}
              >
                <img src={EditIcon} alt="Editar" />
              </IconButton>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListDisplay;
