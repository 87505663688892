import React, { useState, memo } from "react";

import Grid from "@material-ui/core/Grid";

import Input from "../../../../common/input";
import Topic from "../../../../common/topic";
import Button from "../../../../common/button";
import DeleteIcon from "@material-ui/icons/Delete";

import Autocomplete from "@material-ui/lab/Autocomplete";

import SnackBar from "../../../../common/snackbar";

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const { listHealthOperators, healthInsurances, setHealthInsurances } = props;

  const [firstRender, setFirstRender] = useState(true);

  const [inputHealthInsurances, setInputHealthInsurances] = useState(
    healthInsurances.map((healthInsurance) => {
      return {
        fantasyName: healthInsurance.healthInsurances.fantasyName,
        plan: healthInsurance.plans.name,
      };
    })
  );

  function createHealthInsurance() {
    setHealthInsurances([
      ...healthInsurances,
      {
        healthInsurances: { plans: [], id: "" },
        plans: [{ name: "" }],
      },
    ]);
    setInputHealthInsurances([
      ...inputHealthInsurances,
      { fantasyName: "", plan: "" },
    ]);
  }

  function deleteHealthInsurance(index) {
    setHealthInsurances(healthInsurances.filter((_, i) => i !== index));
    setInputHealthInsurances(
      inputHealthInsurances.filter((_, i) => i !== index)
    );
  }

  /*function verifyPlanRegistered(index, planId) {
    const planAlreadyChoice = healthInsurances.some(
      (healthInsurance, i) => healthInsurance.plans.id === planId && i !== index
    );

    if (planAlreadyChoice) {
      setHealthInsurances(
        [...healthInsurances],
        (healthInsurances[index].plans = { name: "" })
      );

      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Esse plano ja foi escolhido!",
      });
      return true;
    }
    return false;
  }*/

  function verifyHealthInsuranceRegistered(index, healthInsuranceId) {
    const healthInsuranceAlreadyChoice = healthInsurances.some(
      (healthInsurance, i) => {
        return (
          healthInsurance.healthInsurances.id === healthInsuranceId &&
          i !== index
        );
      }
    );

    if (healthInsuranceAlreadyChoice) {
      setHealthInsurances(
        [...healthInsurances],
        (healthInsurances[index].healthInsurances = {
          plans: [],
          id: "",
          fantasyName: "",
        })
      );

      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Esse convenio ja foi escolhido!",
      });
      return true;
    }
    return false;
  }

  return (
    <div className="row">
      <div className="row">
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Convênios"
          subtitle="Informe os convênios aceitos em sua empresa."
        />
      </div>

      {listHealthOperators.length > 0 &&
        healthInsurances.map((healthInsurance, i) => (
          <div key={i}>
            <Grid container spacing={2}>
              <Grid item xs={9} md={3}>
                <Autocomplete
                  id={`combo-box-healthOperator-${i}`}
                  openOnFocus
                  options={listHealthOperators}
                  getOptionLabel={(healthOperator) =>
                    healthOperator.fantasyName
                  }
                  getOptionSelected={(healthOperator) =>
                    healthOperator.fantasyName
                  }
                  value={healthInsurances[i].fantasyName}
                  onChange={(e, newValue) => {
                    setHealthInsurances(
                      [...healthInsurances],
                      (healthInsurances[i].healthInsurances = newValue
                        ? newValue
                        : (healthInsurances[i].healthInsurances = {
                            id: "",
                            plans: [],
                            fantasyName: "",
                          }))
                    );
                  }}
                  clearOnBlur={true}
                  inputValue={inputHealthInsurances[i].fantasyName}
                  onInputChange={(event, value) => {
                    if (!firstRender) {
                      const healthInsuranceAlreadyChoice = inputHealthInsurances.some(
                        (healthInsurance) =>
                          healthInsurance.fantasyName === value
                      );

                      if (!healthInsuranceAlreadyChoice) {
                        setInputHealthInsurances(
                          [...inputHealthInsurances],
                          (inputHealthInsurances[i].fantasyName = value)
                        );
                      } else {
                        setInputHealthInsurances(
                          [...inputHealthInsurances],
                          (inputHealthInsurances[i].fantasyName = "")
                        );
                      }
                    } else {
                      setFirstRender(false);
                    }
                  }}
                  onClose={() => {
                    setHealthInsurances(
                      [...healthInsurances],
                      (healthInsurances[i].plans = [{ name: "" }])
                    );
                    setInputHealthInsurances(
                      [...inputHealthInsurances],
                      (inputHealthInsurances[i].plan = "")
                    );

                    const healthInsuranceId =
                      healthInsurances[i].healthInsurances.id;
                    verifyHealthInsuranceRegistered(i, healthInsuranceId);
                  }}
                  renderInput={(params) => (
                    <Input
                      name={`input-healthInsurance-healthInsurance-${i}`}
                      datatest={`input-healthInsurance-healthInsurance-${i}`}
                      width="100%"
                      InputProps={params.InputProps}
                      inputProps={params.inputProps}
                      label="Convênio"
                      placeholder="Convênio"
                      type="text"
                    />
                  )}
                />
              </Grid>
              {/* 
              <Grid item xs={9} md={3}>
                
                <Autocomplete
                  id={`combo-box-plans-${i}`}
                  openOnFocus
                  options={
                    healthInsurances[i].healthInsurances.plans
                      ? healthInsurances[i].healthInsurances.plans.filter(
                          (plan) =>
                            healthInsurances[i].healthInsurances.id ===
                            plan.healthOperatorId
                        )
                      : []
                  }
                  getOptionLabel={(plan) => (plan.name ? plan.name : "")}
                  getOptionSelected={(plan) => (plan.name ? plan.name : "")}
                  inputValue={inputHealthInsurances[i].plan}
                  onInputChange={(event, value) => {
                    if (!firstRender) {
                      const actualHealthInsuranceName =
                        inputHealthInsurances[i].fantasyName;
                      const planAlreadyChoice = inputHealthInsurances.some(
                        (healthInsurance) =>
                          healthInsurance.plan === value &&
                          healthInsurance.fantasyName ===
                            actualHealthInsuranceName
                      );

                      if (!planAlreadyChoice) {
                        setInputHealthInsurances(
                          [...inputHealthInsurances],
                          (inputHealthInsurances[i].plan = value)
                        );
                      } else {
                        setInputHealthInsurances(
                          [...inputHealthInsurances],
                          (inputHealthInsurances[i].plan = "")
                        );
                      }
                    } else {
                      setFirstRender(false);
                    }
                  }}
                  value={healthInsurances[i].name}
                  onChange={(e, newValue) => {
                    setHealthInsurances(
                      [...healthInsurances],
                      (healthInsurances[i].plans = newValue ? newValue : "")
                    );
                  }}
                  onClose={() => {
                    const planId = healthInsurances[i].plans.id;
                    verifyPlanRegistered(i, planId);
                  }}
                  renderInput={(params) => (
                    <Input
                      name={`input-plan-healthInsurance-${i}`}
                      datatest={`input-plan-healthInsurance-${i}`}
                      width="100%"
                      InputProps={params.InputProps}
                      inputProps={params.inputProps}
                      label="Plano"
                      placeholder="Plano"
                      type="text"
                    />
                  )}
                />
                
              </Grid>
              */}
              <Grid item xs={3}>
                {healthInsurances.length > 1 && (
                  <i
                    onClick={(e) => deleteHealthInsurance(i)}
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteIcon style={{ marginTop: "35px" }}></DeleteIcon>
                  </i>
                )}
              </Grid>
            </Grid>
          </div>
        ))}
      <Grid>
        <Grid item xs={10} md={5}>
          <Button
            name="button-add-healthInsurance"
            datatest="button-add-healthInsurance"
            width="223px"
            margin="32px 0 0 0"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            fontWeight="600"
            border="1px solid #CDCED9"
            className="btn-add-health-insurances space-above-item-less details"
            onClick={(e) => createHealthInsurance()}
          >
            Adicionar Convênio
            <i className="material-icons right black-text">add</i>
          </Button>
        </Grid>
      </Grid>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
});
