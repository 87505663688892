import React from "react";

import AuthRoute from "./config/authRoute";

import Logon from "./pages/logon";
import Company from "./pages/providerRegistration/company";
import Admin from "./pages/admin";
import Schedule from "./pages/admin/professionals/schedule";
import HealthOperatorSolicitation from "./pages/health-operator/solicitation";
import HealthOperatorReports from "./pages/health-operator/reports";
import HealthOperatorAdmin from "./pages/health-operator/admin";
import Requests from './pages/requests'

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Logon} />
      <AuthRoute path="/cadastro/pj" component={Company} />
      <AuthRoute exact path="/administrador/profissionais/agenda/:id/:unitId" component={Schedule} />
      <AuthRoute path="/administrador/:perfil" component={Admin} />
      <AuthRoute path="/operadora-de-saude/solicitacao" component={HealthOperatorSolicitation} />
      <AuthRoute path="/operadora-de-saude/relatorio" component={HealthOperatorReports} />
      <AuthRoute path="/operadora-de-saude/administrador/:perfil" component={HealthOperatorAdmin} />
      <AuthRoute path="/solicitacoes" component={Requests} />
      <Redirect path="*" to="/administrador/profissionais" />
    </Switch>
  </BrowserRouter>
);
