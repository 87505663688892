import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import CalendarIcon from "../../assets/calendar.png";

import "./style.css";

const useStyles = makeStyles({
  root: {
    borderRadius: "10px",
  },
  input: {
    width: "224px",
    marginTop: "5px",
    "& .MuiInput-root": {
      height: "40px",
      border: "1px solid #e7e7ed !important",
      borderRadius: "4px",
      backgroundColor: "#FFF",
    },
  },
});

class LocalizedUtils extends DateFnsUtils {
  getWeekdays() {
    return ["D", "S", "T", "Q", "Q", "S", "S"];
  }
}

function BasicDatePicker(props) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBR}>
      <div className={classes.input}>
        <KeyboardDatePicker
          disableToolbar
          disablePast={props.disablepast}
          autoOk
          variant={"inline"}
          PopoverProps={{ classes: { paper: classes.root } }}
          value={props.value}
          onChange={(date) => props.onChange(date)}
          minDate={props.minDate}
          format="dd/MM/yyyy"
          minDateMessage={props.minDateMessage}
          keyboardIcon={<img src={CalendarIcon} alt="Escolha a data" />}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;
