import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const {
    technicianResponsibleName,
    setTechnicianResponsibleName,
    technicianResponsibleNameError,
    setTechnicianResponsibleNameError,
  } = props;

  const {
    technicianResponsibleDocument,
    setTechnicianResponsibleDocument,
    technicianResponsibleDocumentError,
    setTechnicianResponsibleDocumentError,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Responsável Técnico"
          subtitle="Quem é seu responsavél técnico?"
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9} lg={3}>
          <Input
            name="input-technicianResponsibleName-technicalManager"
            datatest="input-technicianResponsibleName-technicalManager"
            label="Nome do diretor/responsável técnico"
            placeholder="Nome do Responsavél"
            type="text"
            direction="row"
            value={technicianResponsibleName}
            onChange={(e) => setTechnicianResponsibleName(e.target.value)}
            onBlur={() =>
              setTechnicianResponsibleNameError(
                empty(technicianResponsibleName)
              )
            }
            error={technicianResponsibleNameError.typeError}
          />
        </Grid>
        <Grid item xs={8} lg={2}>
          <Input
            name="input-technicianResponsibleDocument-technicalManager"
            datatest="input-technicianResponsibleDocument-technicalManager"
            label="Documento profissional"
            placeholder="Documento"
            direction="row"
            type="text"
            value={technicianResponsibleDocument}
            onChange={(e) => setTechnicianResponsibleDocument(e.target.value)}
            onBlur={() =>
              setTechnicianResponsibleDocumentError(
                empty(technicianResponsibleDocument)
              )
            }
            error={technicianResponsibleDocumentError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
