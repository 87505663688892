import React from "react";

import Input from "../../../../../common/input";
import currencyMask from "../../../../../common/mask/currency";
import { priceToNumber } from '../../../../../utils/price/priceToNumber'

import AddIcon from "@material-ui/icons/Add";
import Button from "../../../../../common/button";
import { SpecialtyInput } from "./specialtyInput";

export default (props) => {
  const { stateSnackBar, setStateSnackBar } = props;
  const { price1, setPrice1, price2, setPrice2, price3, setPrice3, tax } = props;
  const { showSpeciality2, setShowSpeciality2 } = props;
  const { showSpeciality3, setShowSpeciality3 } = props;
  const {specialityError1, setSpecialityError1} = props
  const {specialityError2, setSpecialityError2} = props
  const {specialityError3, setSpecialityError3} = props
  

  const { listSpecialities, specialitiesArray, setSpecialitiesArray } = props;

  const deleteSpeciality1 = () => {
    
    specialitiesArray[0].specialities.active = false
    setSpecialitiesArray(specialitiesArray)
    
    if(showSpeciality3){
      setShowSpeciality3(false);
      
      setPrice1(price2);
      setPrice2(price3);
    }
    else{
      setShowSpeciality2(false);
      setPrice1(price2);
    }
  };

  const deleteSpeciality2 = () => {
    if(filterSpecialitiesAndReturnInIndex(1)?.specialities?.active){
      filterSpecialitiesAndReturnInIndex(1).specialities.active = false
      setSpecialitiesArray(specialitiesArray)
    }
    
    if(showSpeciality3){
      setShowSpeciality3(false);
      setPrice2(price3);
      setPrice3(0);
    }
    else{
      setShowSpeciality2(false);
      setPrice2(0);  
    }
    
  };

  const deleteSpeciality3 = () => {
    if(filterSpecialitiesAndReturnInIndex(2)?.specialities?.active){
      filterSpecialitiesAndReturnInIndex(2).specialities.active = false
      setSpecialitiesArray(specialitiesArray)
    }
    setShowSpeciality3(false);

    setPrice3(0);
  };

  const filterSpecialitiesAndReturnInIndex =(index) => {
    return specialitiesArray.filter(x => x.specialities.active === true)[index] || []
  }
  return (
    <React.Fragment>

      <SpecialtyInput
      index={0}
      listSpecialities={listSpecialities}
      specialityFromDatabase={filterSpecialitiesAndReturnInIndex(0)}
      specialitiesArray={specialitiesArray}
      setSpecialitiesArray={setSpecialitiesArray}
      stateSnackBar={stateSnackBar}
      setStateSnackBar={setStateSnackBar}
      setSpecialityError={setSpecialityError1}
      specialityError={specialityError1}
      />
      

      <Input
        name="input-price1-register-professionals-admin"
        datatest="input-price1-register-professionals-admin"
        width="90%"
        label="Valor em reais (R$) da Consulta"
        placeholder="Valor da consulta"
        type="text"
        value={currencyMask(price1)}
        onChange={(e) => setPrice1(currencyMask(e.target.value))}
      />

      <span className={props.classesModal.span}>
        O valor cobrado na plataforma será de:{" "}
        <strong>
          R${" "}
          {( parseFloat(priceToNumber(price1)) * (tax / 100)).toFixed(2).replace(".", ",")}
        </strong>
      </span>
      {showSpeciality2 && (
        <Button
          name="button-add-healthInsurance"
          datatest="button-add-healthInsurance"
          width="257px"
          margin="32px 0 0 0"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          fontWeight="600"
          border="1px solid #CDCED9"
          onClick={() => deleteSpeciality1()}
        >
          Excluir Especialidade
        </Button>
      )}

      {!showSpeciality2 && (
        <Button
          name="button-add-healthInsurance"
          datatest="button-add-healthInsurance"
          width="257px"
          margin="32px 0 0 0"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          fontWeight="600"
          border="1px solid #CDCED9"
          onClick={() => setShowSpeciality2(true)}
        >
          Adicionar Especialidade
          <AddIcon></AddIcon>
        </Button>
      )}

      {showSpeciality2 && (
        <>
          <SpecialtyInput
      index={1}
      listSpecialities={listSpecialities}
      specialityFromDatabase={filterSpecialitiesAndReturnInIndex(1)}
      specialitiesArray={specialitiesArray}
      setSpecialitiesArray={setSpecialitiesArray}
      stateSnackBar={stateSnackBar}
      setStateSnackBar={setStateSnackBar}
      setSpecialityError={setSpecialityError2}
      specialityError={specialityError2}
      />
          <Input
            name="input-price2-register-professionals-admin"
            datatest="input-price2-register-professionals-admin"
            width="90%"
            label="Valor em reais (R$) da Consulta"
            placeholder="Valor da consulta"
            type="text"
            value={currencyMask(price2)}
            onChange={(e) => setPrice2(currencyMask(e.target.value))}
          />

          <span className={props.classesModal.span}>
            O valor cobrado na plataforma será de:{" "}
            <strong>
              R${" "}
              {(parseFloat(priceToNumber(price2)) * (tax / 100)).toFixed(2).replace(".", ",")}
            </strong>
          </span>

          <Button
            name="button-add-healthInsurance"
            datatest="button-add-healthInsurance"
            width="257px"
            margin="32px 0 0 0"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            fontWeight="600"
            border="1px solid #CDCED9"
            onClick={() => deleteSpeciality2()}
          >
            Excluir Especialidade
          </Button>
        </>
      )}

{!showSpeciality3 && showSpeciality2 && listSpecialities.length > 2 && (
        <Button
          name="button-add-healthInsurance"
          datatest="button-add-healthInsurance"
          width="257px"
          margin="32px 0 0 0"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          fontWeight="600"
          border="1px solid #CDCED9"
          onClick={() => setShowSpeciality3(true)}
        >
          Adicionar Especialidade
          <AddIcon></AddIcon>
        </Button>
      )}
{showSpeciality3 && (
        <>
          <SpecialtyInput
      index={2}
      listSpecialities={listSpecialities}
      specialityFromDatabase={ filterSpecialitiesAndReturnInIndex(2)}
      specialitiesArray={specialitiesArray}
      setSpecialitiesArray={setSpecialitiesArray}
      stateSnackBar={stateSnackBar}
      setStateSnackBar={setStateSnackBar}
      setSpecialityError={setSpecialityError3}
      specialityError={specialityError3}
      />
          <Input
            name="input-price3-register-professionals-admin"
            datatest="input-price3-register-professionals-admin"
            width="90%"
            label="Valor em reais (R$) da Consulta"
            placeholder="Valor da consulta"
            type="text"
            value={currencyMask(price3)}
            onChange={(e) => setPrice3(currencyMask(e.target.value))}
          />

          <span className={props.classesModal.span}>
            O valor cobrado na plataforma será de:{" "}
            <strong>
              R${" "}
              {(parseFloat(priceToNumber(price3)) * (tax / 100)).toFixed(2).replace(".", ",")}
            </strong>
          </span>

          <Button
            name="button-add-healthInsurance"
            datatest="button-add-healthInsurance"
            width="257px"
            margin="32px 0 0 0"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            fontWeight="600"
            border="1px solid #CDCED9"
            onClick={() => deleteSpeciality3()}
          >
            Excluir Especialidade
          </Button>
        </>
      )}
    </React.Fragment>
  );
};
