import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import Box from "@material-ui/core/Box";
import Button from "../../../../common/button";
import Loading from '../../../../common/circular-progress'

import { Consultations } from './consultations'
import { Exams } from './exams'

import * as consultation from "../../../../common/store/actions/registerConsultations";
import * as addressAction from "../../../../common/store/actions/registerAddress";
import * as examsAction from '../../../../common/store/actions/registerExams'

import {
  headerPageRegisterServices,
  contentPageRegisterServices,
} from "../style";

export const Services = () => {
  const classesNameHeaderPage = headerPageRegisterServices()
  const classesNameContentPage = contentPageRegisterServices()
  const dispatch = useDispatch()

  const consultations = useSelector(
    (state) => state.RegisterConsultations.consultations
  );
  const {exams, examsSpreadsheet} = useSelector(
    (state) => state.Exams
  );
  const units = useSelector((state) => state.Address.units)
  const activeAddress = useSelector((state) => state.Address.active)

  const [loading ] = useState(false)
  const [errorPage ] = useState(false)

  const handleCancel = () => {
    dispatch(consultation.deleteAllConsultations());
    dispatch(addressAction.toggleRegisterServices());
    dispatch(examsAction.deleteAllExams())
    dispatch(examsAction.deleteExamsSpreadsheet())
  }

  const handleSave = () => {
    dispatch(
      addressAction.insertServices({
        consultations,
        exams,
        examsSpreadsheet
      })
    )
    dispatch(addressAction.toggleRegisterServices())
    dispatch(consultation.deleteAllConsultations())
    dispatch(examsAction.deleteAllExams())
    dispatch(examsAction.deleteExamsSpreadsheet())
    }

    const showSaveButton = () => {
      
      return consultations.length > 0 || exams.length > 0 || !!examsSpreadsheet?.name
    }

  if(!errorPage){
    return(
      <div>
        {loading && <Loading size={80} />}
        <Box className={classesNameHeaderPage.root}>
          <div className="title-address">
            <h1>{units[activeAddress].address}</h1>
            <h2>Nos informe quais serviços você prestará neste endereço</h2>
          </div>
          <Box className={classesNameHeaderPage.boxButton}>
            {units[activeAddress].specialities.length > 0 && (
              <Button
                name="button-register-services-cancel"
                datatest="button-register-services-cancel"
                margin="0 20px 0 0"
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                width="22vw"
                maxWidth="142px"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancelar
              </Button>
            )}
            {showSaveButton() && (
              <Button
                name="button-register-services-save"
                datatest="button-register-services-save"
                backgroundColor="#FFF"
                border="1px solid #DF9000"
                color="#DF9000"
                width="22vw"
                maxWidth="142px"
                disabled={loading}
                onClick={handleSave}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>

        <Box className={classesNameContentPage.root}>
          <Box className={classesNameContentPage.boxMarginSide}>
            <Box className={classesNameContentPage.boxTitle}>
              <h1 className="service-title">Serviços</h1>
              <h2>Nos informe quais serviços você prestará neste endereço</h2>
            </Box>
          </Box>
        </Box>

        <Consultations/>
  
        <Exams/>
  
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>
          Desculpe, houve um erro ao tentar buscar a lista de consultas, tente
          novamente mais tarde
        </h1>
      </div>
    );
  }
  
}
