import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: (props) => (props.margin ? props.margin : null),
    backgroundColor: (props) =>
      props.backgroundColor ? props.backgroundColor : "#F9B233",
    border: (props) => (props.border ? props.border : null),
    borderLeft: props => props.borderLeft ? props.borderLeft : null ,
    borderRight: props => props.borderRight ? props.borderRight : null ,
    borderTop: props => props.borderTop ? props.borderTop : null ,
    borderBottom: props => props.borderBottom ? props.borderTop : null ,
    color: (props) => (props.color ? props.color : "#000000"),
    fontFamily: (props) => (props.fontFamily ? props.fontFamily : "Roboto"),
    fontSize: (props) => (props.fontSize ? props.fontSize : "16px"),
    textTransform: "none",
    fontWeight: (props) => (props.fontWeight ? props.fontWeight : "normal"),
    width: (props) => props.width,
    maxWidth: (props) => props.maxWidth,
    display: (props) => (props.display ? props.display : null || undefined),
    flexFlow: (props) => props.flexFlow,
    whiteSpace: (props) => props.whiteSpace,

    "&:hover": {
      backgroundColor: "#F9B233",
      borderColor: "transparent",
      color: "#FFFFFF",
    },
  },
}));

const PrimaryButton = (props) => {
  const classes = useStyles(props);

  return (
    <Button
      disableElevation
      variant="contained"
      name={props.name}
      data-test={props.datatest}
      classes={{ root: classes.root }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
