import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../../../common/button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { pendingRegistrationClasses } from "./style";

import stethoscopePendingRegister from "../../../../assets/stethoscope-pending-register.svg";

export default (props) => {
  const classesPendingRegistration = pendingRegistrationClasses();
  const userName = useSelector((state) => state.ScheduleReducer.userName);
  return (
    <div className={classesPendingRegistration.root}>
      <div className="pending-registration">
        <div className="pending-registration-header">
          <Link to="/administrador/profissionais">
            <Button
              startIcon={<NavigateBeforeIcon />}
              width="91px"
              heigth="32px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
            >
              <NavigateBeforeIcon />
              Voltar
            </Button>
          </Link>
          <h2>{userName}</h2>
        </div>
        <div className="pending-registration-content">
          <div>
            <img src={stethoscopePendingRegister} alt="Estetoscópio" />
          </div>
          <div>
            <h3>Cadastro Pendente</h3>
            <span>
              Finalize o cadastro do profissional para liberar a criação de sua
              agenda.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
