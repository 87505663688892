import React, { useEffect, useState } from 'react'

import empty from '../validators/empty'
import SearchBar from '../searchBar/searchBar'
import apiGraphql from "../../services/api";



export default (props) => {
  const [error, setError] = useState("");
  const [loadingListProcedures, setLoadingListProcedures] = useState(false)
  const [autocompleteChangeText, setAutocompleteChangeText] = useState(null)
  const [queryListProceduresVariables, setQueryListProceduresVariables] = useState({})
  const [listProceduresList, setListProceduresList] = useState([]);
  const [surgicalProcedureError, setSurgicalProcedureError] = useState("");
  const [surgicalProcedure, setSurgicalProcedure] = useState({ code: "", name: "", id: "" });


  useEffect(() => {
    setLoadingListProcedures(true)
    apiGraphql({
      data: {
        query: `query($input: ListProceduresInput!) {
          listProcedures(input: $input) {
            id
            description
            code
            category
            importType
          } 
        }`,
        variables: {
          input: queryListProceduresVariables,
        },
      },
    })
      .then((res) => {
        setLoadingListProcedures(false)
        if(res.data.data){
          console.log(res.data.data)
          const listProcedures = res.data.data.listProcedures
          setListProceduresList((listProcedures || []).map(item => ({
            ...item,
            name: item.description,
            id: item.id,
            code: item.code
          })))
          }
      })
      .catch((err) => {
        setLoadingListProcedures(false)
        console.log(err);
        setError('Erro ao listar a lista de procedimentos')
      });
// eslint-disable-next-line
  },[queryListProceduresVariables])

  useEffect(() => {
    setQueryListProceduresVariables({ description: Boolean(autocompleteChangeText) ? autocompleteChangeText : null, category: props.category || undefined })
    // eslint-disable-next-line
  }, [autocompleteChangeText])

  useEffect(() => {
    console.log(surgicalProcedure)
    if (props.onChange) props.onChange(surgicalProcedure)
    // eslint-disable-next-line
  }, [surgicalProcedure, props.onChange])

  useEffect(() => {
    if (loadingListProcedures && props.isLoading) props.isLoading(loadingListProcedures)
    // eslint-disable-next-line
  }, [loadingListProcedures, props.isLoading])

  useEffect(() => {
    if (props.error) props.error(error)
    // eslint-disable-next-line
  }, [error, props.error])

  return (
    <SearchBar
      type={props.type || 'surgicalProcedures'}
      width={props.width || '100%'}
      label={props.label || 'Nome do procedimento'}
      placeholder={props.placeholder || 'Comece a digitar o nome do procedimento'}
      value={props.value || surgicalProcedure.name}
      onChange={setSurgicalProcedure}
      onBlur={() => setSurgicalProcedureError(empty(surgicalProcedure.name).typeError)}
      error={surgicalProcedureError}
      sync={true}
      itemList={listProceduresList}
      disabled={loadingListProcedures}
      onInputChange={setAutocompleteChangeText}
    />
  )
}
