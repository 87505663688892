import { makeStyles } from "@material-ui/core/styles";

const layoutPageAdmin = makeStyles({
  sidebar: {
    border: "1px solid #E7E7ED",
    backgroundColor: "#FFF",
    minHeight: "100vh",
    "& div": {
      paddingLeft: "40px",
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#393A4A",
      display: "flex",
      alignItems: "center",
      height: "50px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&.active": {
        backgroundColor: "#F9B233",
        color: "#272833",
        fontWeight: 500,
      },
    },
  },
  content: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    "& div.menu": {
      margin: "30px",
      cursor: "pointer",
      width: "40px",
    },
    "& div.professionals": {
      margin: "30px",
      marginBottom: 0,
      "& div.header": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& h1": {
          margin: 0,
          padding: 0,
          fontSize: "1.71rem",
          color: "#272833",
          fontWeight: 600,
        },
      },
      "& div.list-Professionals": {
        "& div.MuiTableContainer-root": {
          margin: "0 !important",
          overflowY: "hidden",
          minHeight: "60vh",
        },
        "& th": {
          fontWeight: "normal ",
          color: "#6B6C7E",
          border: 0,
        },
        "& tbody": {
          backgroundColor: "#fff",
          border: "1px solid rgba(224, 224, 224, 1)",
          "& tr.MuiTableRow-root": {
            height: "64px !important",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          },

          "& div.status-proffesional": {
            margin: 0,
            fontSize: "0.71rem",
            width: "91px",
            height: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12.5px",
            textTransform: "uppercase",
            "&.pending": {
              backgroundColor: "#FFF4EC",
              color: "#B95000",
            },
            "&.active": {
              backgroundColor: "#EDF9F0",
              color: "#287D3C",
            },
            "&.inactive": {
              backgroundColor: "#F7F8F9",
              color: "#CDCED9",
            },
          },
          "& div.actions-professionals": {
            margin: 0,
            display: "flex",
            justifyContent: "flex-end",
            color: "#6B6C7E",
            "& img": {
              cursor: "pointer",
              marginRight: "50px",
            },
            "& i": {
              position: "absolute",
            },
            "& svg": {
              cursor: "pointer",
            },
          },
        },
      },
      "& div.professionals-admin-dropDown": {
        position: "absolute",
        top: 28,
        right: 0,
        left: "-85px",
        zIndex: 10,
        boxShadow: "0px 4px 8px rgba(39, 40, 51, 0.12)",
        borderRadius: "4px",
        width: "100px",
        "& div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "20px",
          minWidth: "100px",
          width: "100%",
          padding: "10px 0 20px 0",
          backgroundColor: "#FFF",
          cursor: "pointer",
          color: "#6B6C7E",
          textAlign: "center",
          "&:hover": {
            color: "#272833",
            backgroundColor: "#FFF8DE",
          },
        },
      },
    },
    "& div.consultations": {
      margin: "30px",
      marginBottom: 0,
      "& div.header-choice-units": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        "& h1": {
          margin: 0,
          padding: 0,
          fontSize: "1.71rem",
          color: "#272833",
          fontWeight: 600,
        },
        "& h2": {
          margin: "11px 0 0 0",
          padding: 0,
          fontSize: "1.14rem",
          color: "#000",
          fontWeight: "normal",
        },
      },
      "& div.list-units": {
        "& div.MuiTableContainer-root": {
          marginTop: "30px",
          overflowY: "hidden",
          minHeight: "60vh",
        },
        "& tbody": {
          backgroundColor: "#fff",
          border: "1px solid rgba(224, 224, 224, 1)",
          "& tr.MuiTableRow-root": {
            minHeight: "64px ",
            cursor: "pointer",
            "& :hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
      "& div.header-consultation": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& div > h1": {
          display: "inline",
          margin: "0 0 0 16px",
          padding: 0,
          fontSize: "1.42rem",
          color: "#272833",
          fontWeight: 600,
        },
      },
      "& div.list-consultations": {
        "& div.MuiTableContainer-root": {
          margin: "0 !important",
          overflowY: "hidden",
          minHeight: "60vh",
        },
        "& th": {
          fontWeight: "normal ",
          color: "#6B6C7E",
          border: 0,
        },
        "& tbody": {
          backgroundColor: "#fff",
          border: "1px solid rgba(224, 224, 224, 1)",
          "& tr.MuiTableRow-root": {
            minHeight: "64px ",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          },
        },
        "& div.actions-consultations": {
          margin: 0,
          display: "flex",
          justifyContent: "flex-end",
          color: "#6B6C7E",
          "& img": {
            cursor: "pointer",
            marginLeft: "34px",
          },
          "& svg": {
            cursor: "pointer",
          },
        },
      },
    },
  },
  drawer: {
    width: "300px",
  },
});

export { layoutPageAdmin };
