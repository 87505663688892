import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import apiGraphql from "../../../services/api";

import StepByStep from "../../../common/stepByStep/stepByStep";

import SnackBar from "../../../common/snackbar";

import cnpjValidator from "../../../common/validators/cnpj";
import emailValidator from "../../../common/validators/email";
import telphoneValidator from "../../../common/validators/telephone";
import inputFileValidator from '../../../common/validators/fileInput'
import empty from "../../../common/validators/empty";

import * as personalDatasActions from "../../../common/store/actions/personalDatas";

import Loading from "../../../common/circular-progress";

import StablishmentData from "./form/stablishmentData";
import TechnicalManager from "./form/technicalManager";
import LegalManager from './form/legalManager'
import Logo from "./form/logo";
import BankData from "./form/bankData";
import Attachments from "./form/attachments";
import HealthInsurance from "./form/healthInsurance";
import SupportType from "./form/supportType";
import Address from "./form/address";

import { priceToNumber } from '../../../utils/price/priceToNumber'

export default (props) => {
  const stablishmentDatas = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj.stablishmentDatas
  );

  const type = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pj.typeStablishment
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const [tradingName, setTradingName] = useState(stablishmentDatas.tradingName);
  const [tradingNameError, setTradingNameError] = useState({
    error: false,
    typeError: "",
  });

  const [companyName, setCompanyName] = useState(stablishmentDatas.companyName);
  const [companyNameError, setCompanyNameError] = useState({
    error: false,
    typeError: "",
  });

  const [cnpj, setCnpj] = useState(stablishmentDatas.cnpj);
  const [cnpjError, setCnpjError] = useState({
    error: false,
    typeError: "",
  });

  const [companyEmail, setCompanyEmail] = useState(
    stablishmentDatas.companyEmail
  );
  const [companyEmailError, setCompanyEmailError] = useState({
    error: false,
    typeError: "",
  });

  const [ansRegistry, setAnsRegistry] = useState(stablishmentDatas.ansRegistry);
  const [ansRegistryError, setAnsRegistryError] = useState({
    error: false,
    typeError: "",
  });

  const [technicianResponsibleName, setTechnicianResponsibleName] = useState(
    stablishmentDatas.technicianResponsibleName
  );
  const [
    technicianResponsibleNameError,
    setTechnicianResponsibleNameError,
  ] = useState({
    error: false,
    typeError: "",
  });

  const [
    technicianResponsibleDocument,
    setTechnicianResponsibleDocument,
  ] = useState(stablishmentDatas.technicianResponsibleDocument);
  const [
    technicianResponsibleDocumentError,
    setTechnicianResponsibleDocumentError,
  ] = useState({
    error: false,
    typeError: "",
  });

  const [ legalResponsibleName, setLegalResponsibleName ] = useState(
    stablishmentDatas.legalResponsibleName
  )
  const [ 
    legalResponsibleNameError, 
    setLegalResponsibleNameError 
  ] = useState({
    error: false,
    typeError: "",
  });
  const [ 
    legalResponsibleDocument, 
    setLegalResponsibleDocument 
  ] = useState(stablishmentDatas.legalResponsibleDocument)
  const [ 
    legalResponsibleDocumentError, 
    setLegalResponsibleDocumentError 
  ] = useState({
    error: false,
    typeError: "",
  });

  const [logo, setLogo] = useState(stablishmentDatas.logo);
  const [logoError, setLogoError] = useState({ error: false, typeError: "" });

  const [bank, setBank] = useState(stablishmentDatas.bank);
  const [bankError, setBankError] = useState({
    error: false,
    typeError: "",
  });

  const [agency, setAgency] = useState(stablishmentDatas.agency);
  const [agencyError, setAgencyError] = useState({
    error: false,
    typeError: "",
  });

  const [account, setAccount] = useState(stablishmentDatas.account);
  const [accountError, setAccountError] = useState({
    error: false,
    typeError: "",
  });

  const [numberOfInstallments] = useState(
    stablishmentDatas.numberOfInstallments
  );
  const [minimumInstallmentAmount] = useState(
    stablishmentDatas.minimumInstallmentAmount.toFixed(2)
  );

  const [healthInsurances, setHealthInsurances] = useState(
    stablishmentDatas.healthInsurances
  );

  const [socialContract, setSocialContract] = useState(
    stablishmentDatas.socialContract
  );
  const [socialContractError, setSocialContractError] = useState({
    error: false,
    typeError: "",
  });

  const [cnpjAttachment, setCnpjAttachment] = useState(
    stablishmentDatas.cnpjAttachment
  );
  const [cnpjAttachmentError, setCnpjAttachmentError] = useState({
    error: false,
    typeError: "",
  });

  const [
    companyResponsibleAttachment,
    setCompanyResponsibleAttachment,
  ] = useState(stablishmentDatas.companyResponsibleAttachment);
  const [
    companyResponsibleAttachmentError,
    setCompanyResponsibleAttachmentError,
  ] = useState({
    error: false,
    typeError: "",
  });

  const [
    technicianResponsibleDocumentAttachment,
    setTechnicianResponsibleDocumentAttachment,
  ] = useState(stablishmentDatas.technicianResponsibleDocumentAttachment);
  const [
    technicianResponsibleDocumentAttachmentError,
    setTechnicianResponsibleDocumentAttachmentError,
  ] = useState({
    error: false,
    typeError: "",
  });

  const [proofResidence, setProofResidence] = useState(
    stablishmentDatas.proofResidence
  );
  const [proofResidenceError, setProofResidenceError] = useState({
    error: false,
    typeError: "",
  });

  const [careModality, setCareModality] = useState(
    stablishmentDatas.careModality
  );
  const [careModalityError, setCareModalityError] = useState({
    error: false,
    typeError: "",
  });

  const [country, setCountry] = useState(stablishmentDatas.country);
  const [countryError, setCountryError] = useState({
    error: false,
    typeError: "",
  });

  const [zipCode, setZipCode] = useState(stablishmentDatas.zipCode);
  const [zipCodeError, setZipCodeError] = useState({
    error: false,
    typeError: "",
  });

  const [address, setAddress] = useState(stablishmentDatas.address);

  const [number, setNumber] = useState(stablishmentDatas.number);
  const [numberError, setNumberError] = useState({
    error: false,
    typeError: "",
  });

  const [complement, setComplement] = useState(stablishmentDatas.complement);

  const [neighborhood, setNeighborhood] = useState(
    stablishmentDatas.neighborhood
  );
  const [neighborhoodError, setNeighborhoodError] = useState({
    error: false,
    typeError: "",
  });
  const [district, setDistrict] = useState(stablishmentDatas.district);
  const [city, setCity] = useState(stablishmentDatas.city);

  const [phone, setPhone] = useState(stablishmentDatas.phone);
  const [phoneError, setPhoneError] = useState({
    error: false,
    typeError: "",
  });

  const [whatsapp, setWhatsapp] = useState(stablishmentDatas.whatsapp);
  const [whatsappError, setWhatsappError] = useState({
    error: false,
    typeError: "",
  });

  const handleAdvance = () => {
    if (
      empty(tradingName).error ||
      empty(companyName).error ||
      cnpjValidator(cnpj).error ||
      emailValidator(companyEmail).error ||
      empty(technicianResponsibleName).error ||
      empty(technicianResponsibleDocument).error ||
      empty(legalResponsibleName).error ||
      empty(legalResponsibleDocument).error ||
      inputFileValidator(logo, 20).error ||
      empty(props.type !== 'health-operator' ? bank : 'any').error ||
      empty(props.type !== 'health-operator' ? agency : '000').error ||
      empty(props.type !== 'health-operator' ? account : '000').error ||
      inputFileValidator(socialContract, 25).error ||
      inputFileValidator(cnpjAttachment, 25).error ||
      inputFileValidator(companyResponsibleAttachment, 25).error ||
      inputFileValidator(technicianResponsibleDocumentAttachment, 25).error ||
      inputFileValidator(proofResidence, 25).error ||
      (type !== "health-operator" && empty(careModality).error) ||
      empty(country).error ||
      empty(number).error ||
      empty(neighborhood).error ||
      telphoneValidator(phone).error ||
      telphoneValidator(whatsapp).error
    ) {
      setTradingNameError(empty(tradingName));
      setCompanyNameError(empty(companyName));
      setCnpjError(cnpjValidator(cnpj));
      setCompanyEmailError(emailValidator(companyEmail));
      setTechnicianResponsibleNameError(empty(technicianResponsibleName));
      setTechnicianResponsibleDocumentError(
        empty(technicianResponsibleDocument)
      );
      setLegalResponsibleNameError(empty(legalResponsibleName));
      setLegalResponsibleDocumentError(
        empty(legalResponsibleDocument)
      );
      setLogoError(inputFileValidator(logo, 20));
      setBankError(empty(bank));
      setAgencyError(empty(agency));
      setAccountError(empty(account));
      setSocialContractError(inputFileValidator(socialContract, 25));
      setCnpjAttachmentError(inputFileValidator(cnpjAttachment, 25));
      setCompanyResponsibleAttachmentError(
        inputFileValidator(companyResponsibleAttachment, 25)
      );
      setTechnicianResponsibleDocumentAttachmentError(
        inputFileValidator(technicianResponsibleDocumentAttachment, 25)
      );
      setProofResidenceError(inputFileValidator(proofResidence, 25));

      empty(careModality).error
        ? setCareModalityError({
            error: true,
            typeError: "Por favor, escolha um tipo de modalidade",
          })
        : setCareModalityError({ error: false, typeError: "" });

      setCountryError(empty(country));
      setNumberError(empty(number));
      setNeighborhoodError(empty(neighborhood));
      setPhoneError(telphoneValidator(phone));
      setWhatsappError(telphoneValidator(whatsapp));

      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message:
          "Houve algum erro no preenchimento do formulario, por favor verifique os campos.",
      });
    } else {
      const payload = {
        tradingName,
        companyName,
        cnpj,
        companyEmail,
        ansRegistry,
        technicianResponsibleName,
        technicianResponsibleDocument,
        legalResponsibleName,
        legalResponsibleDocument,
        logo,
        bank,
        agency,
        account,
        numberOfInstallments,
        minimumInstallmentAmount: priceToNumber(minimumInstallmentAmount),
        healthInsurances,
        socialContract,
        cnpjAttachment,
        companyResponsibleAttachment,
        technicianResponsibleDocumentAttachment,
        proofResidence,
        careModality,
        country,
        zipCode,
        address,
        number,
        complement,
        neighborhood,
        district,
        city,
        phone,
        whatsapp,
      };

      dispatch(personalDatasActions.personalDatasPj(payload));
      props.nextStep();
    }
  };

  const [listPlans, setListPlans] = useState([
    { id: "", name: "", healthOperatorId: "" },
  ]);
  const [listHealthOperators, setListHealthOperators] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
        query{
          listHealthOperators(input:{}){
            rows{
              plans{
                id
                name
                healthOperatorId
              }
              id
              fantasyName
            }
            }
          }
         `,
      }
    })
      .then((res) => {
        setListHealthOperators(res.data.data.listHealthOperators.rows);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [setLoading]);

  return (
    <div>
      <StepByStep
        activeStep={props.activeStep}
        handleAdvance={() => handleAdvance()}
        handleBack={props.previousStep}
      />
      {loading && <Loading size={80} />}
      {!loading && (
        <form className="form-layout">
          <div>
            <StablishmentData
              tradingName={tradingName}
              setTradingName={setTradingName}
              tradingNameError={tradingNameError}
              setTradingNameError={setTradingNameError}
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyNameError={companyNameError}
              setCompanyNameError={setCompanyNameError}
              cnpj={cnpj}
              setCnpj={setCnpj}
              cnpjError={cnpjError}
              setCnpjError={setCnpjError}
              companyEmail={companyEmail}
              setCompanyEmail={setCompanyEmail}
              companyEmailError={companyEmailError}
              setCompanyEmailError={setCompanyEmailError}
              ansRegistry={ansRegistry}
              setAnsRegistry={setAnsRegistry}
              ansRegistryError={ansRegistryError}
              setAnsRegistryError={setAnsRegistryError}
            />

            <TechnicalManager
              technicianResponsibleName={technicianResponsibleName}
              setTechnicianResponsibleName={setTechnicianResponsibleName}
              technicianResponsibleNameError={technicianResponsibleNameError}
              setTechnicianResponsibleNameError={
                setTechnicianResponsibleNameError
              }
              technicianResponsibleDocument={technicianResponsibleDocument}
              setTechnicianResponsibleDocument={
                setTechnicianResponsibleDocument
              }
              technicianResponsibleDocumentError={
                technicianResponsibleDocumentError
              }
              setTechnicianResponsibleDocumentError={
                setTechnicianResponsibleDocumentError
              }
            />

            <LegalManager
              legalResponsibleName={legalResponsibleName}
              setLegalResponsibleName={setLegalResponsibleName}
              legalResponsibleNameError={legalResponsibleNameError}
              setLegalResponsibleNameError={
                setLegalResponsibleNameError
              }
              legalResponsibleDocument={legalResponsibleDocument}
              setLegalResponsibleDocument={
                setLegalResponsibleDocument
              }
              legalResponsibleDocumentError={
                legalResponsibleDocumentError
              }
              setLegalResponsibleDocumentError={
                setLegalResponsibleDocumentError
              }
            />

            <Logo
              setStateSnackBar={setStateSnackBar}
              logo={logo}
              setLogo={setLogo}
              logoError={logoError}
              setLogoError={setLogoError}
            />

            {props.type !== 'health-operator' &&
            <BankData
              bank={bank}
              setBank={setBank}
              bankError={bankError}
              setBankError={setBankError}
              agency={agency}
              setAgency={setAgency}
              agencyError={agencyError}
              setAgencyError={setAgencyError}
              account={account}
              setAccount={setAccount}
              accountError={accountError}
              setAccountError={setAccountError}
            />}
            
            {/* {type !== "health-operator" && (
              <InstallmentOptions
                numberOfInstallments={numberOfInstallments}
                setNumberOfInstallments={setNumberOfInstallments}
                minimumInstallmentAmount={minimumInstallmentAmount}
                setMinimumInstallmentAmount={setMinimumInstallmentAmount}
              />
            )} */}

            {type !== "health-operator" && !loading && (
              <HealthInsurance
                healthInsurances={healthInsurances}
                setHealthInsurances={setHealthInsurances}
                listPlans={listPlans}
                setListPlans={setListPlans}
                listHealthOperators={listHealthOperators}
                loading={loading}
              />
            )}
            <Attachments
              setStateSnackBar={setStateSnackBar}
              socialContract={socialContract}
              setSocialContract={setSocialContract}
              socialContractError={socialContractError}
              setSocialContractError={setSocialContractError}
              cnpjAttachment={cnpjAttachment}
              setCnpjAttachment={setCnpjAttachment}
              cnpjAttachmentError={cnpjAttachmentError}
              setCnpjAttachmentError={setCnpjAttachmentError}
              companyResponsibleAttachment={companyResponsibleAttachment}
              setCompanyResponsibleAttachment={setCompanyResponsibleAttachment}
              companyResponsibleAttachmentError={
                companyResponsibleAttachmentError
              }
              setCompanyResponsibleAttachmentError={
                setCompanyResponsibleAttachmentError
              }
              technicianResponsibleDocumentAttachment={
                technicianResponsibleDocumentAttachment
              }
              setTechnicianResponsibleDocumentAttachment={
                setTechnicianResponsibleDocumentAttachment
              }
              technicianResponsibleDocumentAttachmentError={
                technicianResponsibleDocumentAttachmentError
              }
              setTechnicianResponsibleDocumentAttachmentError={
                setTechnicianResponsibleDocumentAttachmentError
              }
              proofResidence={proofResidence}
              setProofResidence={setProofResidence}
              proofResidenceError={proofResidenceError}
              setProofResidenceError={setProofResidenceError}
            />
            {type !== "health-operator" && (
              <SupportType
                careModality={careModality}
                setCareModality={setCareModality}
                careModalityError={careModalityError}
                setCareModalityError={setCareModalityError}
              />
            )}

            <Address
              country={country}
              setCountry={setCountry}
              countryError={countryError}
              setCountryError={setCountryError}
              zipCode={zipCode}
              setZipCode={setZipCode}
              zipCodeError={zipCodeError}
              setZipCodeError={setZipCodeError}
              address={address}
              setAddress={setAddress}
              number={number}
              setNumber={setNumber}
              numberError={numberError}
              setNumberError={setNumberError}
              complement={complement}
              setComplement={setComplement}
              neighborhood={neighborhood}
              setNeighborhood={setNeighborhood}
              neighborhoodError={neighborhoodError}
              setNeighborhoodError={setNeighborhoodError}
              district={district}
              setDistrict={setDistrict}
              city={city}
              setCity={setCity}
              phone={phone}
              setPhone={setPhone}
              phoneError={phoneError}
              setPhoneError={setPhoneError}
              whatsapp={whatsapp}
              setWhatsapp={setWhatsapp}
              whatsappError={whatsappError}
              setWhatsappError={setWhatsappError}
            />
          </div>
        </form>
      )}
      {!loading && (
        <StepByStep
          activeStep={props.activeStep}
          handleAdvance={() => handleAdvance()}
          handleBack={props.previousStep}
        />
      )}
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
