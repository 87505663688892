import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { convertToMinutes } from "../../utils/time/convertToMinutes";
import Day from "../calendarDays";

import {
  getDate,
  parse,
  format,
  isSameDay,
  getMonth,
  getYear,
  startOfWeek,
  getDay,
  addDays,
  addWeeks,
  subWeeks,
  eachDayOfInterval,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import Button from "../button";
// import Slot from '../slot';

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const weekDayFromApiTranslation = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};
const useStyles = makeStyles({
  root: {
    width: "100%",
    maxHeight: "100%",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  monthAndYear: {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    borderBottom: "1px solid #e7e7ed",
    "& > span": {
      fontSize: "14px",
      fontWeight: "600",
    },
  },
  dayWeek: {
    display: 'flex',
    width: '99.6%',
    "& div:last-child": {
        width: '100%'
    },
    justifyContent: 'space-between',
    marginRight: '12px',
    "& div": {
        width: '100%'
    }
  },
  sideBySide: {
    display: "flex",
  },
  preView: {
    display: "flex",
    height: "calc(72vh - 40px)",
    boxSizing: "border-box",
    paddingBottom: '40px'
  },
  view: {
    display: "flex",
    backgroundColor: "#F8F8F8",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "0 0 10px 10px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDCED9",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  row: {
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
});

const Scheduler = ({
  selectedDate,
  schedule,
  selectedSchedule,
  interval,
  unavailabilities,
  ...props
}) => {
  
  const classes = useStyles();
  let scheduleGroupProviderId = "";
  let scheduleGroupEmployeeId = "";
  const weekStart = startOfWeek(selectedDate);
  const actualMonth = months[getMonth(selectedDate)];
  const actualYear = getYear(selectedDate);
  const shownWeek = [
    weekStart,
    addDays(weekStart, 1),
    addDays(weekStart, 2),
    addDays(weekStart, 3),
    addDays(weekStart, 4),
    addDays(weekStart, 5),
    addDays(weekStart, 6),
  ];
  const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
  let eachDayOfSelectedSchedule = [];
  let beginAndEndShiftPerWeekDay = [];
  let eachDayOfSelectedScheduleInfo = [];
  let onlyDaysWithSchedule = null;
  let duration = 0;
  let beginShifts = 0;
  let endShifts = 0;
  let numberOfSlots = 0;
  if (schedule.length > 0) {
    scheduleGroupProviderId =
      schedule[selectedSchedule][0].scheduleGroupProviderId;
    scheduleGroupEmployeeId =
      schedule[selectedSchedule][0].scheduleGroupEmployeeId;

    duration = schedule[selectedSchedule][0].appointmentDuration;

    beginShifts = Math.min(
      ...schedule[selectedSchedule].map((day) => {
        const localShiftBegin = new Date(
          `${day.beginSchedule} ${day.beginShiftUtc} UTC`
        );
        const beginShift = format(localShiftBegin, "HH:mm");
        return convertToMinutes(beginShift);
      })
    );

    endShifts = Math.max(
      ...schedule[selectedSchedule].map((day) => {
        const localShiftEnd = new Date(
          `${day.endSchedule} ${day.endShiftUtc} UTC`
        );
        const endShift = format(localShiftEnd, "HH:mm");
        return convertToMinutes(endShift);
      })
    );

    numberOfSlots = Math.floor((endShifts - beginShifts) / duration) + 1;

    eachDayOfSelectedSchedule = eachDayOfInterval(interval);

    beginAndEndShiftPerWeekDay = schedule[selectedSchedule].reduce(
      (acc, currV) => {
        const localShiftBegin = new Date(
          `${currV.beginSchedule} ${currV.beginShiftUtc} UTC`
        );
        const beginShift = convertToMinutes(format(localShiftBegin, "HH:mm"));
        const localShiftEnd = new Date(
          `${currV.endSchedule} ${currV.endShiftUtc} UTC`
        );
        let endShift = convertToMinutes(format(localShiftEnd, "HH:mm"));
        if (!endShift) endShift = 1440;
        return {
          ...acc,
          [weekDayFromApiTranslation[currV.dayOfWeek]]: {
            beginShift,
            endShift,
            scheduleHasProviderId: currV.id,
            scheduleHasEmployeeId: currV.id,
          },
        };
      },
      {}
    );

    eachDayOfSelectedScheduleInfo = eachDayOfSelectedSchedule.map((value) => {
      const day = value;
      const dayOfWeek = getDay(day);
      return { day, ...beginAndEndShiftPerWeekDay[dayOfWeek] };
    });
    onlyDaysWithSchedule = eachDayOfSelectedScheduleInfo.filter(x => !isNaN(x.beginShift))
  }

  let selectedScheduleUnavailabilities =
    unavailabilities[selectedSchedule] || [];
  const eachDayUnavailabilities = (day) => {
    const filteredByDay = selectedScheduleUnavailabilities.filter(
      (unavailability) => {
        const parsedDate = parse(
          unavailability.date,
          "yyyy-MM-dd",
          new Date(),
          { locale: ptBR }
        );
        const isCanceled = unavailability.status === "CANCELED";
        return isSameDay(parsedDate, day) && !isCanceled;
      }
    );
    return filteredByDay;
  };

  return (
    <div className={classes.root}>
      <div className={classes.monthAndYear}>
        <span>
          {actualMonth} {actualYear}
        </span>
        <div className={classes.sideBySide}>
          <Button
            backgroundColor="transparent"
            border="none"
            onClick={() => props.onPick(subWeeks(selectedDate, 1))}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            backgroundColor="transparent"
            border="none"
            onClick={() => props.onPick(addWeeks(selectedDate, 1))}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      </div>
      <div className={classes.dayWeek}>
          {shownWeek.map((day, index) => (
              <Button
              key={index}
              backgroundColor="#FFFFFF"
              color={isSameDay(day, selectedDate) ? "#EDA014" : null}
              border={ index < 6 ? "1px solid #e7e7ed" : null }
              borderLeft={ index === 6 ? "1px solid #e7e7ed" : null }
              borderTop={ index === 6 ? "1px solid #e7e7ed" : null }
              borderBottom={ index === 6 ? "1px solid #e7e7ed" : null }
              borderRadius="0px"
              width="100%"
              onClick={() => props.onPick(day)}
              >   
                  <span>{getDate(day)} {daysOfWeek[getDay(day)]}</span>
              </Button>
          ))}
      </div>
      <div className={classes.preView}>
        <div className={classes.view}>
          {shownWeek.map((day, index) => {
            return (
              <Day
                key={day}
                day={day}
                duration={duration}
                beginShifts={beginShifts}
                endShifts={endShifts}
                numberOfSlots={numberOfSlots}
                onlyDaysWithSchedule={onlyDaysWithSchedule}
                unavailabilities= {eachDayUnavailabilities(day)}
                scheduleGroupProviderId= {scheduleGroupProviderId}
                scheduleGroupEmployeeId={scheduleGroupEmployeeId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Scheduler;
