import React, { useState, useEffect, forwardRef } from "react"
import Loading from "../../circular-progress";

import { useDispatch } from "react-redux";

import empty from "../../validators/empty";
import Input from "../../input";
import Button from "../../button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SnackBar from "../../snackbar";

import currencyMask from "../../mask/currency";
import { priceToNumber } from '../../../utils/price/priceToNumber'

import { modal } from "../style";

import "../style.css";
import * as consultation from "../../store/actions/registerConsultations"
import * as consultationService from '../../../services/consultation'
import * as specialitieService from '../../../services/specialties'
import * as taxService from '../../../services/taxes'
import calcTax from '../../../utils/calcTax'

export default forwardRef((props, ref) => {
  const { showAlert } = props;
  
  const classesModal = modal({maxWidth: '540px'});

  const dispatch = useDispatch();

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const handleAlert = (show, time = 5000) => {
    showAlert(show);
    setTimeout(() => showAlert(false), time);
  }

  const [loading, setLoading] = useState(false);
  const [specialty, setSpecialty] = useState({ id: "", name: "", group: "" });
  const [specialtyError, setSpecialtyError] = useState({
    error: true,
    typeError: "",
  });

  const [price, setPrice] = useState(currencyMask(0));
  const [taxAppointmentFeeProvider, setTaxAppointmentFeeProvider] = useState(0);
  const [taxAppointmentFeePatient, setTaxAppointmentFeePatient] = useState(0);

  const [ listSpecialities, setListSpecialities ] = useState([])

  const loadTaxes = async() => {
    const taxes = await taxService.getTaxesByTypes()

    if(!taxes.error) {
      const { taxAppointmentFeePatient, taxAppointmentFeeProvider} = taxes
      setTaxAppointmentFeePatient(taxAppointmentFeePatient)
      setTaxAppointmentFeeProvider(taxAppointmentFeeProvider)
    }
  }

  const loadSpecialities = async() => {
    const specialties = await specialitieService.listSpecialties()

    if(!specialties.error) {
      setListSpecialities(specialties)
    }
  }

  const loadAll = async() => {
    setLoading(true)
    await loadTaxes()
    await loadSpecialities()
    setLoading(false)
  }

  useEffect(() => {
    loadAll()
    // eslint-disable-next-line
  }, []);

  

  const saveConsultation = async() => {
    setLoading(true);
    
    const establishmentId = localStorage.getItem(
      "Establishment-ID-Vida-Connect"
    );
    const consultation = {
      price: priceToNumber(price),
      unitId: props.unitId,
      specialityId: specialty.id,
      establishmentId,
    }

    const res = await consultationService.saveConsultation(consultation) 

    if(!res.error) {
      props.setListConsultations([
        ...props.listConsultations,
        {
          id: res.id,
          price: consultation.price,
          specialities: specialty,
          active: true
        }
      ])
      setSpecialty({ id: "", name: "", group: "" });
      setPrice(0);
      setSpecialtyError({ error: true, typeError: "" });
      setStateSnackBar({ ...stateSnackBar, open: true, message: 'Especialidade cadastrada com sucesso!', color: 'success' });
      handleAlert(true);
      props.handleClose();
    } else {
      props.setStateSnackBar({ ...props.stateSnackBar, open: true, message: res.message, color: 'error' });
      handleAlert(false);
    }
    setLoading(false)
  };

  function handleSubmit(e) {
    setSpecialtyError(empty(specialty.name));

    if (!specialtyError.error) {
      const payload = { ...specialty, price: priceToNumber(price) };
      if (props.saveConsultation) {
        saveConsultation();
      } else {
        dispatch(consultation.RegisterConsultation(payload));

        setSpecialty({ id: "", name: "", group: "" });
        setPrice(0);
        setSpecialtyError({ error: true, typeError: "" });
      }
      props.handleClose();
    }
    
  }

  

  return (
    <React.Fragment>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <>
          <div className={classesModal.root}>
            <div
              className={classesModal.title}
              id="modal-register-consultation-title"
            >
              <h1>Cadastrar Especialidade</h1>
              <IconButton
                aria-label="close"
                onClick={() => props.handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className={classesModal.body}
              id="modal-register-consultation-body"
            >
              <Autocomplete
                id="combo-box-specialty-consultation"
                options={listSpecialities}
                getOptionLabel={(specialty) => specialty.name}
                getOptionSelected={(specialty) => specialty.name}
                value={specialty}
                onChange={(e, newValue) =>
                  newValue
                    ? setSpecialty(newValue)
                    : setSpecialty({ id: "", name: "", group: "" })
                }
                renderInput={(params) => (
                  <Input
                    name="input-specialty-register-consultation"
                    datatest="input-specialty-register-consultation"
                    width="90%"
                    InputProps={params.InputProps}
                    inputProps={params.inputProps}
                    label="Especialidade"
                    placeholder="Especialidade"
                    type="text"
                    onBlur={() => setSpecialtyError(empty(specialty.name))}
                    error={specialtyError.typeError}
                  />
                )}
              />

              <Input
                name="input-price-register-consultation"
                datatest="input-price-register-consultation"
                width="90%"
                label="Valor em reais (R$) da Consulta"
                placeholder="Valor da consulta"
                type="text"
                value={currencyMask(price)}
                onChange={(e) => setPrice(currencyMask(e.target.value))}
              />

              <span className={classesModal.span}>
                O valor cobrado do prestador pela plataforma será de :{" "}
                <strong>
                  R${" "}
                  {
                    calcTax({
                      price: priceToNumber(price),
                      tax: taxAppointmentFeeProvider,
                      sum: false
                    })
                  }
                </strong>
              </span>
              <span className={classesModal.span}>
                O valor final que a plataforma irá cobrar do paciente será de:{" "}
                <strong>
                  R${" "}
                  { 
                    calcTax({
                      price: priceToNumber(price),
                      tax: taxAppointmentFeePatient,
                      sum: true
                    })
                  }
                </strong>
              </span>
            </div>
            <div className={classesModal.footer}>
              <Button
                name="button-cancel-register-consultation"
                datatest="button-cancel-register-consultation"
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                width="22vw"
                maxWidth="120px"
                margin="0 20px 0 0 "
                onClick={() => props.handleClose()}
              >
                Cancelar
              </Button>
              <Button
                name="button-register-consultation"
                datatest="button-register-consultation"
                backgroundColor="#FFF"
                border="1px solid #DF9000"
                color="#DF9000"
                width="22vw"
                maxWidth="120px"
                margin="0 10% 0 0"
                onClick={(e) => handleSubmit(e)}
              >
                Salvar
              </Button>
            </div>
            <SnackBar
              stateSnackBar={stateSnackBar}
              setStateSnackBar={setStateSnackBar}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
});
