import { makeStyles } from "@material-ui/core/styles";

const chooseUnitClasses = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    padding: "30px",
    "& div.header-choice-unit": {
      display: "flex",
      "& h1": {
        margin: 0,
        fontSize: "1.71rem",
        color: "#272833",
        fontWeight: 600,
      },
    },
    "& div.content-choice-unit": {
      marginTop: "30px",
      "& div.MuiTableContainer-root": {
        margin: "0 !important",
        overflowY: "hidden",
        minHeight: "60vh",
      },
      "& th": {
        fontWeight: "normal ",
        color: "#6B6C7E",
        border: 0,
      },
      "& tbody": {
        backgroundColor: "#fff",
        border: "1px solid rgba(224, 224, 224, 1)",
        "& tr.MuiTableRow-root": {
          minHeight: "64px ",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          },
        },
      },
    },
  },
});

const scheduleClasses = makeStyles({
  root: {
    width: "95%",
    height: "calc(100vh - 70px - 60px)",
    maxHeight: "calc(100vh - 70px - 60px)",
    boxSizing: "border-box",
    margin: "3vh auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  container: {
    marginTop: "2vh",
    width: "100%",
    height: "59vh",
    maxHeight: "72vh",
    boxSizing: "border-box",
    display: "flex",
  },
  header: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h1": {
      display: "inline",
      fontSize: "1.42rem",
      fontWeight: 600,
      color: "#000000",
      margin: 0,
    },
  },
  leftSide: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "30px",
  },
  scheduleHint: {
    height: "100%",
    overflowY: "auto",
    border: "1px solid #E7E7ED",
    borderRadius: "10px",
    marginTop: "24px",
    "& > div": {
      fontWeight: "600",
      textAlign: "center",
      marginTop: "16px",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDCED9",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  rightSide: {
    height: "100%",
    width: "100%",
    border: "1px solid #E7E7ED",
    borderRadius: "10px",
    backgroundColor: "#F7F8F9",
    "& div.no-published-schedule": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& div.no-published-schedule-content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& h3": {
          margin: "32px 0 8px 0",
          fontSize: "1.42rem",
          fontWeight: "bold",
          color: "#272833",
        },
        "& h4": {
          margin: "0 0 16px 0",
          fontSize: "1.14rem",
          fontWeight: "normal",
          color: "#6B6C7E",
        },
      },
    },
  },
  scheduler: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
    overflow: "hidden",
  },
});

const pendingRegistrationClasses = makeStyles({
  root: {
    "& div.pending-registration": {
      margin: "30px",
      "& div.pending-registration-header": {
        display: "flex",
        "& h2": {
          margin: "5px 0 0 0px",
          fontSize: "1.42rem",
          fontWeight: 600,
        },
      },
      "& div.pending-registration-content": {
        minHeight: "542px",
        marginTop: "30px",
        backgroundColor: "#F7F8F9",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
          marginBottom: "50px",
        },
        "& div": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        },
        "& h3": {
          fontSize: "1.42rem",
          fontWeight: "bold",
          color: "#272833",
        },
        "& span": {
          fontSize: "1.14rem",
          fontWeight: "normal",
          color: "#6B6C7E",
        },
      },
    },
  },
});

const listPatientsClasses = makeStyles({
  root: {
    margin: "50px 0",
    "& div.title": {
      display: "flex",
      width: "100%",
      backgroundColor: "#F7F8F9",
      height: "34px",
      alignItems: "center",
      border: "1px solid #E7E7ED",
      "& span": {
        marginLeft: "33px",
      },
    },
    "& div.patient": {
      display: "flex",
      alignItems: "center",
      height: "74px",
      width: "100%",
      border: "1px solid #E7E7ED",
      "& div.patient-img": {
        margin: "0 16px 0 24px",
        "& .MuiAvatar-colorDefault": {
          color: "#000",
          backgroundColor: "#FFF",
        },
        "& .MuiAvatar-root": {
          width: "32px",
          height: "32px",
          border: "1px solid #E7E7ED",
        },
      },
      "& div.patient-name": {
        fontWeight: 600,
        color: "#272833",
      },
    },
  },
});

export {
  chooseUnitClasses,
  pendingRegistrationClasses,
  listPatientsClasses,
  scheduleClasses,
};
