import { makeStyles } from "@material-ui/core/styles";

const checkbox = makeStyles({
  root: {
    "& label": {
      border: "1px solid #E7E7ED",
      borderRadius: "4px",
      height: "40px",
      width: "100%",
      margin: "0 0 10px 0",
    },
    "& span": {
      fontSize: "1.14rem",
      fontWeight: "normal",
    },
  },
});

const pagination = makeStyles({
  root: {
    margin: "30px 0 0 12px",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    color: "#6B6C7E",
    fontWeight: "normal",
    "& div.qty-items-per-page span:first-child": {
      marginRight: "28px",
      cursor: "pointer",
      "& i": {
        position: "relative",
        cursor: "pointer",
      },
    },
    "& div.pagination": {
      margin: "0 11px 0 0",
      "& li > button": {
        width: "30px",
        "&:focus": {
          color: "#000",
        },
      },
      "& li > div": {
        margin: 0,
      },
    },
    "& div.dropDown": {
      position: "absolute",
      top: 28,
      right: 0,
      left: "-85px",
      zIndex: 10,
      boxShadow: "0px 4px 8px rgba(39, 40, 51, 0.12)",
      borderRadius: "4px",
      width: "100px",
      "& div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20px",
        minWidth: "100px",
        width: "100%",
        padding: "10px 0 20px 0",
        backgroundColor: "#FFF",
        cursor: "pointer",
        color: "#6B6C7E",
        textAlign: "center",
        "&:hover": {
          color: "#272833",
          backgroundColor: "#FFF8DE",
        },
      },
    },
  },
});

export { checkbox, pagination };
