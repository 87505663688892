import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '../../../../common/button'
import { ConsultationModal } from './modals/consultation'

import * as consultation from "../../../../common/store/actions/registerConsultations";

import {
  contentPageRegisterServices,
} from "../style";

export const Consultations = () => {
  const classesNameContentPage = contentPageRegisterServices()
  const dispatch = useDispatch()

  const consultations = useSelector(
    (state) => state.RegisterConsultations.consultations
  );

  const [ 
    openModalRegisterConsultation, 
    setOpenModalRegisterConsultation 
  ] = useState(false)
  const [ 
    openModalDeleteAndUpdateConsultation, 
    setOpenModalDeleteAndUpdateConsultation 
  ] = useState(false)

  return (
    <Box className={classesNameContentPage.root}>
      <Box className={classesNameContentPage.boxMarginSide}>
        <Box className={classesNameContentPage.boxTitleWithButton}>
          <Box component="span" className={classesNameContentPage.boxTitle}>
            <h1>Consultas</h1>
            <h2>Cadastre aqui as consultas realizadas neste endereço</h2>
          </Box>
          <Box
            component="span"
            className={classesNameContentPage.boxButton}
          >
            <Button
              name="button-register-services-add-consultation"
              datatest="button-register-services-add-consultation"
              backgroundColor="#FFF"
              border="1px solid #CDCED9"
              color="#6B6C7E"
              width="22vw"
              maxWidth="168px"
              onClick={() => setOpenModalRegisterConsultation(true)}
              // disabled={loading}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
        <Box className={classesNameContentPage.boxServicesRegistered}>
          {consultations.map((consult, index) => (
            <div
              key={index}
              style={{
                margin: "10px 0",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                name={`button-register-services-update-delete-consultation-${index}`}
                datatest={`button-register-services-update-delete-consultation-${index}`}
                margin="0"
                backgroundColor="#FFF"
                border="1px solid #CDCED9"
                color="#6B6C7E"
                width="90%"
                onClick={() => {
                  dispatch(consultation.alterActive(index));
                  setOpenModalDeleteAndUpdateConsultation(true)
                }}
                // disabled={loading}
              >
                {consult.name}
              </Button>
            </div>
          ))}
        </Box>
      </Box>

      <ConsultationModal 
        openModalRegisterConsultation={openModalRegisterConsultation}
        handleCloseModalRegisterConsultation={() => setOpenModalRegisterConsultation(false)}
        openModalDeleteAndUpdateConsultation={openModalDeleteAndUpdateConsultation}
        handleCloseModalDeleteAndUpdateConsultation={() => setOpenModalDeleteAndUpdateConsultation(false)}
        listConsultations={consultations}
      />

    </Box>
  )
}