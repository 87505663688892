import { makeStyles } from "@material-ui/core/styles";

const laytoutPageHealthOperatorSolicitation = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    "& div.content-solicitation": {
      padding: "30px",
      marginBottom: 0,
      "& div.header": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& h1": {
          margin: 0,
          padding: 0,
          fontSize: "1.71rem",
          color: "#272833",
          fontWeight: 600,
        },
      },
      "& div.list-solicitations": {
        "& div.MuiTableContainer-root": {
          margin: "0 !important",
          overflowY: "hidden",
          minHeight: "60vh",
        },
        "& th": {
          fontWeight: "normal ",
          color: "#6B6C7E",
          border: 0,
        },
        "& tbody": {
          backgroundColor: "#fff",
          border: "1px solid rgba(224, 224, 224, 1)",
          "& tr.MuiTableRow-root": {
            height: "64px !important",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              cursor: "pointer",
            },
          },

          "& div.status-solicitation": {
            margin: 0,
            fontSize: "0.71rem",
            width: "110px",
            height: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12.5px",
            textTransform: "uppercase",
            "&.pending": {
              backgroundColor: "#FFF4EC",
              color: "#FF8F39",
            },
            "&.approved": {
              backgroundColor: "#EDF9F0",
              color: "#287D3C",
            },
            "&.rejected": {
              backgroundColor: "#FEEFEF",
              color: "#DA1414",
            },
            "&.canceled": {
              backgroundColor: "#eeeeee",
              color: "#000",
            },
          },
        },
      },
    },
  },
});

export { laytoutPageHealthOperatorSolicitation };
