import React, { useEffect, useState } from "react";
import apiGraphql from "../../../../../services/api";

import Loading from "../../../../../common/circular-progress";

import Input from "../../../../../common/input";
import Button from "../../../../../common/button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

import emailValidator from "../../../../../common/validators/email";

export default (props) => {
  const { email, setEmail, emailError, setEmailError } = props;
  const { addresses, setAddresses, addressesError, setAddressesError } = props;
  const { setListSpecialities } = props;
  const [loading, setLoading] = useState(false);
  const [listAddresses, setListAddresses] = useState([]);

  const nextStep = () => {
    setAddressesError({ error: false, typeError: "" });
    if (emailValidator(email).error || !addresses.length) {
      setEmailError(emailValidator(email));
      if (!addresses.length) {
        setAddressesError({
          error: true,
          typeError: "Esse Campo é obrigatório!",
        });
      }
    } else {
      props.nextStep();
    }
  };

  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
          query{
            listUnitsOfEstablishment{
              unit{
                id
                address
                unitHasSpecialities{
                  id
                  price
                  specialities{
                    id
                    name
                  }
                }
              }
            } 
          }
        `,
      }
    })
      .then((res) => {
        setListAddresses(res.data.data.listUnitsOfEstablishment.unit);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <div className={props.classesModal.body}>
          <Input
            width="90%"
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => setEmailError(emailValidator(email))}
            error={emailError.typeError}
          />
          <Autocomplete
            multiple
            id="combo-box-invite-doctors"
            value={addresses}
            onChange={(e, newValue) => {
              setAddresses(newValue);

              if (newValue.length) {
                const specialities = newValue.flatMap(
                  (unit) => unit.unitHasSpecialities.map(i => ({ ...i, unitId: unit.id }))
                );

                setListSpecialities(specialities);
              } else {
                setListSpecialities([]);
              }
            }}
            options={listAddresses}
            getOptionLabel={(addresses) => addresses.address}
            getOptionSelected={(addresses, value) =>
              addresses.address === value.address
            }
            openOnFocus
            filterSelectedOptions
            renderTags={(addresses, getTagProps) => {
              return addresses.map((addresses, index) => (
                <Chip label={addresses.address} {...getTagProps({ index })} />
              ));
            }}
            renderInput={(params) => (
              <Input
                width="90%"
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                label="Atende nos endereços:"
                placeholder="endereços de atendimento"
                type="text"
                onBlur={() =>
                  addresses.length === 0
                    ? setAddressesError({
                        error: true,
                        typeError: "Esse Campo é obrigatório!",
                      })
                    : setAddressesError({ error: false, typeError: "" })
                }
                error={addressesError.typeError}
              />
            )}
          />
        </div>
      )}
      <div className={props.classesModal.footer}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          Cancelar
        </Button>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => nextStep()}
        >
          Proximo
        </Button>
      </div>
    </div>
  );
};
