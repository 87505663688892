import { makeStyles } from "@material-ui/core/styles";

const layoutPageAdmin = makeStyles({
  sidebar: {
    border: "1px solid #E7E7ED",
    backgroundColor: "#FFF",
    minHeight: "100vh",
    "& div": {
      paddingLeft: "40px",
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#393A4A",
      display: "flex",
      alignItems: "center",
      height: "50px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&.active": {
        backgroundColor: "#F9B233",
        color: "#272833",
        fontWeight: 500,
      },
    },
  },
  content: {
    minHeight: "100vh",
    backgroundColor: "#F7F8F9",
    padding: 30,
    "& div.header-analysts": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "26px",
      "& h1": {
        margin: 0,
      },
    },
    "& div.list-analysts": {
      "& div.MuiTableContainer-root": {
        margin: "0 !important",
        overflowY: "hidden",
        minHeight: "60vh",
      },
      "& th": {
        fontWeight: "normal ",
        color: "#6B6C7E",
        border: 0,
      },
      "& tbody": {
        backgroundColor: "#fff",
        border: "1px solid rgba(224, 224, 224, 1)",
        "& tr.MuiTableRow-root": {
          height: "64px !important",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
          },
        },
      },
    },
  },
  drawer: {
    width: "300px",
  },
});

export { layoutPageAdmin };
