import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles';
import apiGraphql from "../../../services/api";

import ModalBase from '../modalBaseWithouFlow';

import empty from "../../validators/empty";
import Input from "../../input";
import TextArea from '../../textarea';
import InputCurrency from '../../inputCurrency/InputCurrencyFormat';
import SnackBar from '../../snackbar'
import "../style.css";
import * as exam from "../../store/actions/registerExams";
import setTussCodeMask   from '../../mask/tussCode'
import Select from '../../select';
import * as examsActions from "../../store/actions/registerExams";

const useStyles = makeStyles( {
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  }
})

const ModalUpdateExam = (props) => {
  
  useEffect(() => {
    apiGraphql({
      data: {
        query: `
            query Query($getTaxByType: TaxType!) {
               getTaxByType(type: $getTaxByType)
            }`,
        variables: {
          getTaxByType: "EXAM_FEE_PROVIDER"
        },
      },
    }).then((res) => {
      if(res.data.data.getTaxByType){
      
        const taxExamFee = res.data.data.getTaxByType
        console.log('TAX', taxExamFee)
        setExamFee(taxExamFee)
      }
  
    }).catch((err) => {
      console.log(err);
      
    });
    //eslint-disable-next-line
  },[])
  
  const dispatch = useDispatch();

  const classes = useStyles();

  
const [stateSnackBar, setStateSnackBar] = useState({
  open: false,
  vertical: "top",
  horizontal: "right",
  message: "",
});
const [examFee, setExamFee] = useState(0)
  
  

  const [examId, setExamId] = useState("");

  const [preparation, setPreparation] = useState("");
  const [preparationError, setPreparationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  
  const [examCode, setExamCode] = useState("");
  const [examCodeError, setExamCodeError] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState({
    error: true,
    typeError: "",
  });

  const openModal = useSelector( (state) => state.Exams.openModal);
  const activeExam = useSelector( (state) => state.Exams.active)
  let examData = useSelector((state) => state.Exams.exams);
  

  useEffect( () => {
    if(examData.length > 0 && activeExam <= examData.length - 1) {
      const availableData = examData[activeExam];
      setExamCode(availableData.examCode);
      setName(availableData.name);
      setPreparation(availableData.preparation);
      setPrice(availableData.price);
      setExamId(availableData.serviceProvidedId)
    }
  }, [activeExam, examData])

  function handleSubmit(e) {
    e.preventDefault();

    if(isNaN(price) || empty(price).typeError || empty(examCode).typeError || empty(preparation).typeError)
          {         
            setExamCodeError(empty(examCode).typeError);
            setNameError(empty(name));
            setPriceError(empty('').typeError);
            setPreparationError(empty(preparation).typeError);
            
            return
          }
          console.log('PROPS', props)
          console.log('PROPS UNIT', props.unitId)
      const payload = { examCode, name, preparation, needScheduling: true, price, serviceProvidedId: examId };

        dispatch(exam.updateExam(activeExam, payload));

      dispatch(exam.openModal(false));
      dispatch(exam.alterActive(examData.length + 1));
      
      setExamCode("")
      setName("");
      setPreparation("");
      setPrice("");
      setExamCodeError("")
      setPriceError("")
      setPreparationError("")
      
      
      
      return true;
   
  }
  const handleGetExam = async(code) => {
  
       if(empty(examCode).typeError){
        
        setExamCodeError(empty(examCode).typeError)
        return
        }
        
        apiGraphql({
          data: {
            query: `query Query($input: ID!) {
              getServiceProvidedByCode(code: $input) {
                id
                description
                
              }
            }
                      `,
            variables: {
              input: `${code}`,
            },
          },
        })
          .then((res) => {
       
            if(res.data.data){
              console.log(res.data.data.getServiceProvidedByCode.description)
              setExamId(res.data.data.getServiceProvidedByCode.id)
              setName(res.data.data.getServiceProvidedByCode.description)
              dispatch(exam.GetExam(res.data.data.getServiceProvidedByCode.id))
              setExamCodeError("")
              }
              else{
                setName('')
                setExamCodeError(res.data.errors[0].message);
                setStateSnackBar({
                  ...stateSnackBar,
                  open: true,
                  message: res.data.errors[0].message,
                });
              }
          })
          .catch((err) => {
            console.log(err);
            setStateSnackBar({
              ...stateSnackBar,
              open: true,
              message: "Houve um problema durante a busca do exame, tente novamente.",
            });
          });

    

    
  }
  const handleClose = () => {
    
    setExamCode("")
    setName("");
    setPreparation("");
    setPrice("");
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")
    dispatch(examsActions.openModal(false));
  };

  return (
    <ModalBase title="Atualizar Exame" buttonWidth={props.buttonWidth} buttonText={props.buttonText} handleSubmit={handleSubmit} open={openModal} onClose={handleClose}>
      <Select
                        genericSelect={true}
                        label="Tabela"
                        InputProps={{readOnly: true}}                            
                        value={'TUSS'}
                        itemList={[
                          {value: "TUSS", exhibit: "TUSS" },
                        ]}
                      />
    
 <Input
                width="100%"
                label="Nome do exame"
                value={name}
                InputProps={{readOnly: true}} 
                backgroundColor="#f7f8f9"
                onChange={(e) => setName(e.target.value)}
                onBlur={() => setNameError(empty(name).typeError)}
                error={nameError.typeError}
              />
    <Input
                width="100%"
                label="Código do exame"
                InputProps={{readOnly: true}} 
                value={examCode}
                onChange={(e) =>  setExamCode(setTussCodeMask(e.target.value)) }
                onBlur={() => handleGetExam(examCode)}
                error={examCodeError}
                
              />
         
     
     
       <InputCurrency
                width="100%"
                label="Valor em reais (R$)"
                value={price}
                onChange={setPrice}
                error={priceError}
                styles={{fontSize: '12px',
                marginTop: '3px',
                color: "#DA1414"}}
                dataTest="modal-cadastrar-consulta-input-preco"
              />
               <DialogContentText className={classes.lettering}>
                O valor cobrado pela plataforma será de: R$ {((examFee/100)*price).toFixed(2)}
              </DialogContentText>
          
              <TextArea
                width="93%"
                label="Preparo para o exame"
                placeholder="Instruções para o preparo"       
                rows={8}
                value={preparation}
                onChange={(e) => setPreparation(e.target.value)}
                onBlur={() => setPreparationError(empty(preparation).typeError)}
                error={preparationError}
                max={250}
              />
     
     <SnackBar
                stateSnackBar={stateSnackBar}
                setStateSnackBar={setStateSnackBar}
      />      
          
        
     
    </ModalBase>
  );
};

export default ModalUpdateExam;
