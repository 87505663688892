import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import Professionals from "./professionals";
import Consultations from "./consultations";
import Exams from './exams'
import HealthOperator from "./healthOperator";

import { layoutPageAdmin } from "./style";

export default (props) => {
  const history = useHistory();
  const perfil = props.computedMatch.params.perfil;
  const classesLayoutPageAdmin = layoutPageAdmin();

  const [ typeEstablishment, setTypeEstablishment ] = useState('')
  
  useEffect(() => {
   const type = localStorage.getItem("Type-Vida-Connect");
    const status = localStorage.getItem("Status-Vida-Connect");
    setTypeEstablishment(type)
    if (
      (
        type !== "COMPANY_CLINIC" &&
        type !== "COMPANY_HOSPITAL" &&
        type !== "COMPANY_HEALTH_OPERATOR" &&
        type !== "COMPANY_LABORATORY"
      ) ||
      status === "PENDING_APPROVAL"
    ) {
      history.push("/cadastro/pj");
    } else if (type === "COMPANY_HEALTH_OPERATOR") {
      history.push("/operadora-de-saude/solicitacao");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const isNotHealthOperator = () => {
    return (typeEstablishment === "COMPANY_CLINIC" ||
          typeEstablishment === "COMPANY_HOSPITAL" ||
          typeEstablishment === "COMPANY_LABORATORY")
  }
  const linksMenu = () => {
    return (
      <Grid className={classesLayoutPageAdmin.sidebar} item md={2}>
        <Link to="/administrador/profissionais">
          <div
            className={perfil === "profissionais" ? "active" : ""}
            onClick={() => handleDrawerToggle()}
          >
            Profissionais
          </div>
        </Link>

        <Link to="/administrador/consultas">
          <div
            className={perfil === "consultas" ? "active" : ""}
            onClick={() => handleDrawerToggle()}
          >
            Especialidades
          </div>
        </Link>

        { (typeEstablishment === "COMPANY_CLINIC" ||
          typeEstablishment === "COMPANY_HOSPITAL" ||
          typeEstablishment === "COMPANY_LABORATORY") &&
          <Link to="/administrador/exames">
            <div
              className={perfil === "exames" ? "active" : ""}
              onClick={() => handleDrawerToggle()}
            >
              Exames
            </div>
          </Link>
        
        }

        { isNotHealthOperator() &&
          <Link to="/administrador/convenios">
            <div
              className={perfil === "convenios" ? "active" : ""}
              onClick={() => handleDrawerToggle()}
            >
              Convênios
            </div>
          </Link>
        
        }
        
      </Grid>
    );
  };

  return (
    <div>
      <Grid container>
        <Hidden smDown>{linksMenu()}</Hidden>
        <Hidden mdUp>
          <Drawer
            classes={{ paper: classesLayoutPageAdmin.drawer }}
            variant="temporary"
            open={openDrawer}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {linksMenu()}
          </Drawer>
        </Hidden>
        <Grid className={classesLayoutPageAdmin.content} item xs={12} md={10}>
          <>
            <Hidden mdUp>
              <div className="menu" onClick={() => handleDrawerToggle()}>
                <MenuOpenIcon />
              </div>
            </Hidden>
            {perfil === "profissionais" && <Professionals />}
            {perfil === "consultas" && <Consultations />}
            {perfil === "exames" &&  <Exams/> }
            {perfil === "convenios" &&  <HealthOperator/> }
          </>
        </Grid>
      </Grid>
    </div>
  );
};
