import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import apiGraphql from "../../../../services/api";
import { parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import PendingRegistration from "./pendingRegistration";
import ListPatients from "./listPatients";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import Button from "../../../../common/button";
import Calendar from "../../../../common/calendar";
import Scheduler from "../../../../common/scheduler";
import List from "../../../../common/itemList/minimalList";

import ScheduleImg from "../../../../assets/schedule.svg";

import Loading from "../../../../common/circular-progress";

import { scheduleClasses } from "./style";

const Schedule = (props) => {
  const classes = scheduleClasses();
  const history = useHistory();
  const { employeeId, doctorName, doctorStatus, unitId } = props;
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [publishedSchedules, setPublishedSchedules] = useState([]);
  const [unavailabilities, setUnavailabilities] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(0);
  const [hasPublishedSchedule, setHasPublishedSchedule] = useState(false);
  const [beginAndEnd, setBeginAndEnd] = useState([]);
  

  const getSchedules = useCallback(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
        query($employeeId: String!, $unitId: String!) {
          getEmployeeSchedules(employeeId: $employeeId, unitId:$unitId) {
              schedule{
                  id
                  scheduleGroupEmployeeId
                  beginSchedule
                  endSchedule
                  appointmentDuration
                  dayOfWeek
                  beginShift
                  beginShiftUtc
                  endShift
                  endShiftUtc
                  employeeId
                  unitId
              }
              unavailabilities {
                  id
                  scheduleGroupEmployeeId
                  beginUnavailability
                  dateTimeBeginUnavailabilityUtc
                  endUnavailability
                  dateTimeEndUnavailabilityUtc
                  date
                  active
                  status
                  medicalAppointment {
                      id
                      patient {
                          socialName
                          user {name}
                      }
                  }
              }
          }
      }
        `,
        variables: {
          employeeId,
          unitId,
        },
      }
    })
      .then((res) => {
        if (res.data.errors) {
          const error = res.data.errors[0].message;
          if (error === "O id informado não possui nenhuma agenda associada") {
            setHasPublishedSchedule(false);
          } else {
            console.log(res);
          }
          setLoading(false);
        } else {
          const schedules = res.data.data.getEmployeeSchedules.map(
            (schedule) => schedule.schedule
          );
          setPublishedSchedules(schedules);

          const unavailabilitiesSchedule = res.data.data.getEmployeeSchedules.map(
            (schedule) => schedule.unavailabilities
          );

          setUnavailabilities(unavailabilitiesSchedule);

          setHasPublishedSchedule(true);

          setBeginAndEnd(
            schedules.map((schedule) => {
              return {
                start: parse(
                  schedule[0].beginSchedule.slice(0, 10),
                  "yyyy-MM-dd",
                  new Date(),
                  { locale: ptBR }
                ),
                end: parse(
                  schedule[0].endSchedule.slice(0, 10),
                  "yyyy-MM-dd",
                  new Date(),
                  { locale: ptBR }
                ),
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [employeeId, unitId]);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  if (doctorStatus === "PENDING") {
    return <PendingRegistration />;
  }

  return (
    <React.Fragment>
      {loading && <Loading size={80} />}
      {!loading && (
        <div className={classes.root}>
          <div className={classes.header}>
            <div>
              <Button
                width="91px"
                heigth="32px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
                fontSize="14px"
                fontWeight="400"
                padding="6px 12px"
                onClick={() => history.push({pathname: "/administrador/profissionais", state: { alreadyChooseUnit: true}})}
              >
                <NavigateBeforeIcon />
                Voltar
              </Button>

              <h1>{doctorName}</h1>
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.leftSide}>
              <div className={classes.calendar}>
                <Calendar
                  onPick={setSelectedDate}
                  selectedDate={selectedDate}
                  interval={
                    beginAndEnd[selectedSchedule] || { start: null, end: null }
                  }
                />
              </div>
              <div className={classes.scheduleHint}>
                <div>
                  <span>Agendas Publicadas</span>
                  <List
                    fullList={beginAndEnd}
                    firstParam="start"
                    secondParam="end"
                    editItem={() => {}}
                    selected={selectedSchedule}
                    setSelected={setSelectedSchedule}
                    onPick={setSelectedDate}
                    height="30px"
                    width="95%"
                    fontSize="12px"
                    margin="16px auto"
                    border="none"
                  />
                </div>
              </div>
            </div>

            <div className={classes.rightSide}>
              {hasPublishedSchedule && (
                <div className={classes.scheduler}>
                  <Scheduler
                    onPick={setSelectedDate}
                    selectedDate={selectedDate}
                    schedule={publishedSchedules}
                    selectedSchedule={selectedSchedule}
                    interval={beginAndEnd[selectedSchedule]}
                    unavailabilities={unavailabilities}
                  />
                </div>
              )}
              {!hasPublishedSchedule && (
                <div className="no-published-schedule">
                  <div className="no-published-schedule-img">
                    <img src={ScheduleImg} alt="Agenda não criada" />
                  </div>
                  <div className="no-published-schedule-content">
                    <h3>Agenda ainda não foi criada</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          { <ListPatients 
          employeeId={employeeId}
          unitId={unitId}
           />}
        </div>
      )}
    </React.Fragment>
  );
};

export default Schedule;
