import graphqlApi from './api'

export const listBudgets = async ({page, limit}) => {
  try{
    const res = await graphqlApi({
      data: {
        query: `
          query listBudgetsByHospital($input:ListBudgetsByHospitalInput){
            listBudgetsByHospital(input: $input){
              count,
              rows {
                id,
                hospital_has_opme_or_aditional_materials
                hospital_opme
                hospital_aditional_materials
                hospital_special_equipment
                hospital_daily_quantities
                hospital_has_hospitalization
                hospital_hospitalization_quantities
                hospital_has_special_medications
                hospital_special_medications
                hospital_has_blood_products
                hospital_blood_products
                anesthetist
                procedureRequestId,
                procedureRequest {
                  id
                  status
                  proposedDate1
                  proposedDate2
                  proposedDate3
                  dateTime
                  medicalAppointment {
                    patient {
                      socialName
                      user{name}
                    }
                  }
                  serviceProvided{
                    description
                  }
                }
                provider {
                  doctor {
                    crm
                  }
                  user {
                    name
                    phone
                  }
                  professionalHasProvider {
                    professionalDocument
                    professionalCouncil {
                      name
                    }
                  }
                }
                status
                createdAt
              }
            }
          }
        `,
        variables: {
          input: { page, limit }
        },
      }
    })
    
    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return  res.data.data.listBudgetsByHospital
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar salvar a consulta'}
  }
}

export const acceptDate = async (budgetId, accept) => {
  try{
    const res = await graphqlApi({
      data: {
        query: `
          mutation {
            budgetAcceptDate (input: {
              budgetId: "${budgetId}"
              accept: ${accept}
            })
          }
        `,
      }
    })
    
    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return  res.data.data.budgetAcceptDate
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar salvar a consulta'}
  }
}
