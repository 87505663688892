import React, { useState, useEffect, forwardRef } from "react";
import apiGraphql from "../../../../../services/api";

import Loading from "../../../../../common/circular-progress";

import Specialities from "./specialities";

import Input from "../../../../../common/input";
import Button from "../../../../../common/button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import currencyMask from "../../../../../common/mask/currency";
import { priceToNumber } from '../../../../../utils/price/priceToNumber'

import SnackBar from "../../../../../common/snackbar";
import { modal } from "../style";

export default forwardRef((props, ref) => {
  const classesModal = modal();

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const { page, limit, employeeId, professional,unitInformations } = props;

  const [email, setEmail] = useState("exemplo@vidaconnect.com");

  const [specialitiesArray, setSpecialitiesArray] = useState([]);

  const [price1, setPrice1] = useState(currencyMask(0));
  const [price2, setPrice2] = useState(currencyMask(0));
  const [price3, setPrice3] = useState(currencyMask(0));
  const [tax, setTax] = useState(0);
  const [showSpeciality2, setShowSpeciality2] = useState(false);
  const [showSpeciality3, setShowSpeciality3] = useState(false);

  const [loading, setLoading] = useState(false);
  const [listSpecialities, setListSpecialities] = useState([]);


  const [specialityError1, setSpecialityError1] = useState({
    error: false,
    type: "",
  })
  const [specialityError2, setSpecialityError2] = useState({
    error: false,
    type: "",
  })
  const [specialityError3, setSpecialityError3] = useState({
    error: false,
    type: "",
  })
  let listUnits = [];
  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
          query{
            getTaxByType(type:APPOINTMENT_FEE_PROVIDER)
          }
        `,
      }
    })
      .then((res) => {
        setLoading(false);
        setTax(res.data.data.getTaxByType);
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          message: "Houve um erro ao tentar buscar o valor da taxa ",
        }));
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    
    
      listUnitsOfEstablishment()
      getEmployeeForUpdate()
      //eslint-disable-next-line
  }, [employeeId, unitInformations.id])

  const listUnitsOfEstablishment = () => {
    
    apiGraphql({
      data: {
        query: `
            query{
              listUnitsOfEstablishment{
                unit{
                  id
                  address
                  unitHasSpecialities{
                    id
                    price
                    specialities{
                      id
                      name
                    }

                  }
                }
              } 
            }
          `,
      }
    })
      .then((res) => {
        listUnits = res.data.data.listUnitsOfEstablishment.unit;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  const getEmployeeForUpdate = () => {
    apiGraphql({
      data: {
        query: `
            mutation{
              getEmployeeForUpdate(employeeId:"${employeeId}", unitId:"${unitInformations.id}"){
                email
                currentSpecialities{
                  id
                  name
                  appointmentValue
                  unitId
                  address
                  unitHasSpecialitiesId
                }
              
              }
            }
          `,
      }
    })
      .then((res) => {
    
        const employeeForUpdate = res.data.data.getEmployeeForUpdate
    
        setEmail(employeeForUpdate.email);
        
        const unitSpecialities = listUnits.find(x => x.id === unitInformations.id ).unitHasSpecialities
        setListSpecialities(unitSpecialities)
        
        const arrayForSetSpecialities = employeeForUpdate.currentSpecialities
        const specialitiesArray = fillSpecialitiesArray(arrayForSetSpecialities)   
        setSpecialitiesArray(specialitiesArray)

        setPrice1(currencyMask(arrayForSetSpecialities[0]?.appointmentValue?.toFixed(2) || 0));
        setSpeciality2PriceAndVisibility(employeeForUpdate, arrayForSetSpecialities)
        setSpeciality3PriceAndVisibility(employeeForUpdate, arrayForSetSpecialities)        
        
        setLoading(false);

      })
      .catch((err) => console.log(err));
  }
  const fillSpecialitiesArray =(_listSpecialities) => {
    
    
    let specialitiesArray = []
    for(const speciality of _listSpecialities){
      
      if(speciality?.unitHasSpecialitiesId){
        specialitiesArray = [...specialitiesArray, {
          id: speciality?.id || '',
          specialities: {
            id: speciality?.id || '',
            name: speciality?.name || '',
            active: true,
            currentSpeciality: true
          },
        }]
      
    }
    
  }
  return specialitiesArray
  }

  const setSpeciality2PriceAndVisibility = (employeeForUpdate, _listSpecialities) => {
    if (employeeForUpdate.currentSpecialities.length >= 2) {
       
      setPrice2(
        currencyMask (
          _listSpecialities[1]?.appointmentValue?.toFixed(2) || 0
        )
      );
      setShowSpeciality2(true);
    }
  }

  const setSpeciality3PriceAndVisibility = (employeeForUpdate, _listSpecialities) => {
    if (employeeForUpdate.currentSpecialities.length >= 3) {
       
      setPrice3(
        currencyMask (
          _listSpecialities[2]?.appointmentValue?.toFixed(2) || 0
        )
      );
      setShowSpeciality3(true);
    }
  }

  const handleSubmitEditDoctor = () => {
    setLoading(true);

    if(!formIsValid()){
      setStateSnackBar({
        ...stateSnackBar,
        open: true,
        message: "Por favor, preencha todos os campos!",
      })
      return
    }

   const employeeHasUnits = fillEmployeeHasUnits()    

    const inputEditDoctor = {
      page,
      limit,
      employeeId,
      email,
      employeeHasUnits:employeeHasUnits
    };

    editDoctor(inputEditDoctor)

   
  };

  const formIsValid = () => {
    if (specialityError1.error) {   
      setLoading(false);
      return false;
    }
    if (specialityError2.error) {
      setLoading(false);
      return false;
    }
    if (specialityError3.error) {
      setLoading(false);
      return false;
    }

    return true
  }
  const fillEmployeeHasUnits = () => {
    const prices =[price1, price2, price3]
    
    let i = 0
    let employeeHasUnits = []
    for(const speciality of specialitiesArray)
    {
    const employeeUnitSpeciality = {
      appointmentValue: priceToNumber(prices[i]),
      active: speciality.specialities.active,
      unitId: unitInformations.id,
      specialityId: speciality.specialities.id
      
    }
    employeeHasUnits = [...employeeHasUnits, employeeUnitSpeciality]
    
      if(employeeUnitSpeciality.active)
      i++
    }

    return employeeHasUnits
  }

  const editDoctor =(inputEditDoctor) => {
    apiGraphql({
      data: {
        query: `
          mutation updateEmployee($input: UpdateEmployeeInput!){
            updateEmployee(input: $input){
              data{
                userId
                name
                status
                doctorId
              }
              totalCount
            }
          }
        `,
        variables: {
          input: inputEditDoctor,
        },
      }
    })
      .then((res) => {
        
        setLoading(false);
        props.handleClose();
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message: "Erro ao salvar alterações, por favor, tente novamente.",
        })
        console.log(err);
      });
  }

  return (
    <div className={classesModal.root}>
      <div
        className={classesModal.title}
        id="modal-register-consultation-title"
      >
        <h1>Editar Profissional</h1>
        <IconButton aria-label="close" onClick={() => props.handleClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classesModal.body}>
        {loading && (
          <Loading size={80} position="absolut" height="40vh" width="30vw" />
        )}
        {!loading && (
          <>
            <Input
              disabled
              width="90%"
              label="Email"
              placeholder="Email"
              type="email"
              value={email}
            />
         
            <Specialities
              specialityError1={specialityError1}
              setSpecialityError1={setSpecialityError1}
              price1={price1}
              setPrice1={setPrice1}
              price2={price2}
              setPrice2={setPrice2}
              tax={tax}
              showSpeciality2={showSpeciality2}
              setShowSpeciality2={setShowSpeciality2}
              stateSnackBar={stateSnackBar}
              setStateSnackBar={setStateSnackBar}
              listSpecialities={listSpecialities.filter(el => professional.providerHasSpecialities.some(i => i.specialityId === el.specialities.id))}
              specialitiesArray={specialitiesArray}
              setSpecialitiesArray={setSpecialitiesArray}
              classesModal={classesModal}
              price3={price3}
              setPrice3={setPrice3}
              showSpeciality3={showSpeciality3}
              setShowSpeciality3={setShowSpeciality3}
              specialityError2={specialityError2}
              setSpecialityError2={setSpecialityError2}
              specialityError3={specialityError3}
              setSpecialityError3={setSpecialityError3}
            />
          </>
        )}
      </div>
      <div className={classesModal.footer}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.handleClose()}
        >
          cancelar
        </Button>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => handleSubmitEditDoctor()}
        >
          Confirmar
        </Button>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
});
