import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grow } from '@material-ui/core';

import Input from '../../../../../common/input';
import Button from '../../../../../common/button';
import InputCurrency from '../../../../../common/inputCurrency/InputCurrencyFormat';
import TextArea from '../../../../../common/textarea';
import SnackBar from '../../../../../common/snackbar';
import Loading from '../../../../../common/circular-progress';
import empty from '../../../../../common/validators/empty';
import apiGraphql from "../../../../../services/api";
import RadioButtons from '../../../../../common/radioButton/radioButton'


import * as examsActions from "../../../../../common/store/actions/registerExams";


const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '12px',
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
})

const ModalEditExam = ({data, index, examFee, ...props}) => {
  
  const classes = useStyles();
  const dispatch = useDispatch();

  const openModal = useSelector((state) => state.Exams.openEditModal);
  
  const [examActive, setExamActive] = useState(data.active ? "Sim": "Não");
  const [open, setOpen] = useState(false);

  const description = useState(data.serviceProvided.description);
  const [price, setPrice] = useState(data.price);
  const [priceError, setPriceError] = useState("");
  
  
  const [preparation, setPreparation] = useState(data.preparation);
  const [preparationError, setPreparationError] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  useEffect( () => {
    if(openModal) {
      setOpen(openModal);
    }
  },[openModal]);

  const handleClose = () => {
    props.setOpenEditModal(false);
    dispatch(examsActions.openEditModal(false));
  };

  const updateProviderExam = async ({preparation, needScheduling, price, active, id}) => {
    
      apiGraphql({
        data: {
          query:
          `mutation UpdateEstablishmentServiceProvidedMutation($updateEstablishmentServiceProvidedInput: EstablishmentServicesProvidedInput!) {
            updateEstablishmentServiceProvided(input: $updateEstablishmentServiceProvidedInput) {
              id
              serviceProvided {
                id
                description
                code
                category
              }
              price
              preparation
              needScheduling
              active
            }
          }
          `,
          variables: {
            updateEstablishmentServiceProvidedInput: {
              preparation,
              needScheduling,
              active,
              id,
              price:parseFloat(price)
            }
          }
        }
      })
        .then((res) => {
     
         if(res.data.data){
          let establishmentServiceProvided = res.data.data.updateEstablishmentServiceProvided
          establishmentServiceProvided.serviceProvided = data.serviceProvided
          
          dispatch(examsActions.updateProviderExams(index,  establishmentServiceProvided));
          setSuccess(true);
         }
         else{
          
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: "Houve um problema durante a atualização do exame.",
          }); 
         
        }
         
        })
        .catch((err) => {
          console.log(err);
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: "Houve um problema durante a atualização do exame.",
          });
        });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
      try {

        if(empty(preparation).typeError){
          setLoading(false);
          return
        }
        if(isNaN(price) )
        {         
          
          setPriceError(empty('').typeError)
          setLoading(false);
          return
        }
        const active = examActive === 'Sim' ? true : false
        
        
       await updateProviderExam({preparation, needScheduling: true, price, active, id: data.id})
      
 
        setLoading(false);
      } catch(error) {
        setLoading(false);
      }
  }

  return (
    <div>
      <Dialog 
        open={open}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{enter: 200, exit: 0}}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {success ?
              <div><span>Exame editado com sucesso</span></div>
              :
              <span>Editar Exame</span>
            }
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form onSubmit={props.handleSubmit}>
            <div className="modal-body">
              <Input
                label="Exame"
                placeholder="Exame"
                value={description[0]}
                InputProps={{readOnly: true}}                            
                border= "none"
                backgroundColor="#f7f8f9"
                dataTest="modal-editar-exame-input-descricao"
              />
               <InputCurrency
                width="100%"
                label="Valor em reais (R$)"
                value={price}
                onChange={setPrice}
                error={priceError}
                styles={{fontSize: '12px',
                marginTop: '3px',
                color: "#DA1414"}}
                dataTest="modal-editar-exame-input-preco"
              />

              <DialogContentText className={classes.lettering}>
                O valor cobrado do médico pela plataforma será de: R$ {((examFee/100)*price).toFixed(2)}
              </DialogContentText>
              
              <RadioButtons
                label="Manter exame ativo?"
                width="113px"
                value={examActive}
                onChange={(e) => setExamActive(e.target.value)}
                
              />
               <TextArea
               width="93%"
               label="Preparo para o exame"
               placeholder="Instruções para o preparo"       
               rows={8}
               value={preparation}
               onChange={(e) => setPreparation(e.target.value)}
               onBlur={() => setPreparationError(empty(preparation).typeError)}
               error={preparationError}
               max={250}
             />

            <SnackBar
                  stateSnackBar={stateSnackBar}
                  setStateSnackBar={setStateSnackBar}
                />
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? 
                <Loading width="80px"/>
                :
                success ?
                  (<Button 
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  >
                    Ok
                  </Button>)
                  :
                  (
                    <>
                      <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                      >
                        Cancelar
                      </Button>
                      <Button 
                        onClick={handleSubmit}
                        width="120px"
                        border="1px solid #DF9000"
                        backgroundColor="#FFFFFF"
                        color="#DF9000"
                      >
                        Salvar
                      </Button>
                    </>
                  )
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalEditExam;
