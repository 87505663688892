import React from "react";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const boxCircularProgress = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: (props) => (props.position ? props.position : "fixed"),
    height: (props) => (props.height ? props.height : "100vh"),
    width: (props) => (props.width ? props.width : "100vw"),
    zIndex: 10,
  },
});
export default (props) => {
  const classesBoxCircularProgress = boxCircularProgress(props);

  const themeCircularProgress = createMuiTheme({
    palette: {
      primary: { main: "#DF9000" },
    },
  });
  return (
    <Box className={classesBoxCircularProgress.root}>
      <ThemeProvider theme={themeCircularProgress}>
        <CircularProgress color={props.color} size={props.size} />
      </ThemeProvider>
    </Box>
  );
};
