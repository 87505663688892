import graphqlApi from './api'

export const saveConsultation = async (input) => {
  try{
    const res = await graphqlApi({
      data: {
        query: `
          mutation createUnitHasSpecialities($input:UnitHasSpecialitiesInput!){
            createUnitHasSpecialities(input: $input){
              id
            }
          }
        `,
        variables: {
          input,
        },
      }
    })
    
    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return { id: res.data.data.createUnitHasSpecialities.id }
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar salvar a consulta'}
  }
}

export const inactiveConsultation = async (unitHasSpecialitiesId) => {
  const res = await graphqlApi({
    data: {
      query: `
        mutation { 
          alternateUnitHasSpecialitiesActive(unitHasSpecialitiesId: "${unitHasSpecialitiesId}") {
            id
          }
      }
      `,
    }
  })

  if (res.data.errors) {
    return { error: true, message: res.data.errors[0].message }
  } else {
    return { error: false }
  }
}