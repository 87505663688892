import React, { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core'

import RadioButtons from '../../../common/radioButton/radioButton'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 30,
  },
  date: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10
  },
  providerData: {
    marginTop: 30,
    marginBottom: 20
  },
  borders: {
    marginTop: 20,
    border: '1px solid #E7E7ED',
    borderRadius: '4px',
    margin: '0',
    marginRight: '20px',
    width: '200px'
  },
  labelBorder: {
      borderLeft: '1px solid #E7E7ED',
      height: '45px',
      lineHeight: '45px',
      textAlign: 'center'
  },
}))
export const DateApproval = (props) => {
  const classes = useStyles()

  console.log(props)
  const [ response, setResponse ] = useState()

  const {
    proposedDate1, 
    proposedDate2, 
    proposedDate3,
    dateResponse,
    setDateResponse,
    providerName, 
    providerPhone, 
    setDisableSend, 
    success
  } = props
  
  const proposed1 = proposedDate1 ? format(parseISO(proposedDate1), 'dd/MM/yyyy - HH:mm') : null
  const proposed2 = proposedDate2 ? format(parseISO(proposedDate2), 'dd/MM/yyyy - HH:mm') : null
  const proposed3 = proposedDate3 ? format(parseISO(proposedDate3), 'dd/MM/yyyy - HH:mm') : null

  useEffect(() => {
    if(response === 'Não') {
      setDateResponse(null)
      setDisableSend(false) 
    }
    else if (dateResponse) {
      setDisableSend(false)
    }
    // eslint-disable-next-line
  }, [response, dateResponse])

  useEffect(() => {
    setDisableSend(true)
    // eslint-disable-next-line
  }, [])
  
  return (
    <>
      <div className={classes.title}>Data(s) solicitada(s) pelo profissional responsável, para realização do procedimento:</div>

      <RadioGroup 
          aria-label="scheduling" 
          name="examScheduling"
          className={classes.sideBySide}
          value={dateResponse} 
          onChange={(e) => {setDateResponse(e.target.value)}}
      >
        <FormControlLabel 
            value={proposedDate1}
            control={<Radio />} 
            label={proposed1}
            classes={ {
                root: classes.borders,
                label: classes.labelBorder
            }}
        />
        
        { proposed2 &&
          <FormControlLabel 
              value={proposedDate2}
              control={<Radio />} 
              label={proposed2}
              classes={ {
                  root: classes.borders,
                  label: classes.labelBorder
              }}
          />
        }
        
        { proposed3 &&
          <FormControlLabel 
              value={proposedDate3}
              control={<Radio />} 
              label={proposed3}
              classes={ {
                  root: classes.borders,
                  label: classes.labelBorder
              }}
          />
        }

      </RadioGroup>

      <div className={classes.title}>Confirmar horário solicitado pelo médico?</div>
      <RadioButtons
        width="113px"
        value={response}
        disabled={success}
        onChange={(e) => {
          setResponse(e.target.value)
        }}
      />

      <div className={classes.providerData}>
        Entre em contato diretamento com o profissional para definir a melhor a data e horário da realização do procedimento.
        <div><b>Profissional responsável: { providerName }</b></div>
        <div><b>Telefone: { providerPhone }</b></div>
      </div>
    </>
  )
}