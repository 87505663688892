import React, { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core'

import Pagination from '../../../common/pagination'
import * as budgetService from '../../../services/budget'
import { ProcedureRequestModal } from '../modals'
import { BudgetStatusEnum, ProcedureRequestStatusEnum } from '../../../common/enums/'
import { handlePatientName } from '../../../common/handlePatientName'

const useStyles = makeStyles(() => ({

  tableRow: {
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  stage: {
    width: '200px'
  },
  chipBudget: {
    width: '230px',
    backgroundColor: '#EEF2FA',
    color: '#2E5AAC',
  },
  chipSchedule: {
    width: '230px',
    backgroundColor: '#FFF4EC',
    color: '#B95000',
  },
  chipPayment: {
    width: '230px',
    backgroundColor: '#FEEFEF',
    color: '#DA1414',
  },
  chipCanleled: {
    width: '230px',
    backgroundColor: '#F1F2F5',
    color: '#6B6C7E'
  },
  chipConfirm: {
    width: '230px',
    backgroundColor: '#F1F2F5',
    color: '#6B6C7E'
  },
  chipOk: {
    width: '230px',
    backgroundColor: '#EDF9F0',
    color: '#287D3C'
  },
}))

const RenderChip = ({classes, budgetStatus, procedureRequestStatus}) => {
  
  let chipClass
  let chipLabel

  switch (budgetStatus) {
    case BudgetStatusEnum.BUDGET_SENDED:
      chipClass = classes.chipBudget
      chipLabel = 'ORÇAMENTO'
      break

    case BudgetStatusEnum.BUDGET_ANSWERED: 
      chipClass = classes.chipBudget
      chipLabel = 'RESPONDIDO'
      break

    case BudgetStatusEnum.BUDGET_CANCELED:
      chipClass = classes.chipCanleled
      chipLabel = 'CANCELADO'
      break

    case BudgetStatusEnum.BUDGET_APPROVED:
      if(procedureRequestStatus === ProcedureRequestStatusEnum.AWAITING_DATE) {
        chipClass = classes.chipSchedule
        chipLabel = 'AGENDAMENTO'
      } else if(procedureRequestStatus === ProcedureRequestStatusEnum.CONFIRM_SCHEDULE){
        chipClass = classes.chipConfirm
        chipLabel = 'CONFIRMAR AGENDAMENTO'
      } else if (procedureRequestStatus === ProcedureRequestStatusEnum.PENDING_PAYMENT) {
        chipClass = classes.chipPayment
        chipLabel = 'PAGAMENTO'
      } else if (procedureRequestStatus === ProcedureRequestStatusEnum.FINISHED) {
        chipClass = classes.chipOk
        chipLabel = 'REALIZADO'
      } else {
        chipClass = classes.chipOk
        chipLabel = 'PAGAMENTO REALIZADO'
      }
      break
    
    default:
      break
  }
  return (
    <Chip 
      className={chipClass}
      label={chipLabel}
    />
  )
}

export default (props) => {
  const classes = useStyles()

  const [ listBudgets, setListBudgets ] = useState([])

  const [ page, setPage ] = useState(1)
  const [ limit, setLimit ] = useState(10)
  const [ qtPages ] = useState()
  const [ count, setCount ] = useState()

  const [ openModal, setOpenModal ] = useState(false)
  const [ selectedBudget, setSelectedBudget ] = useState()

  const loadBudgets = async () => {
    const budgets = await budgetService.listBudgets(page, limit)
    if(!budgets?.error) {
      setCount(budgets.count)
      setListBudgets(budgets.rows)
    } else {

    }
  }

  const handleBudgetClick = (budget) => {
    setSelectedBudget(budget)
    setOpenModal(true)
  }

  useEffect (() => {
    loadBudgets()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Paciente</TableCell>
              <TableCell>Procedimento</TableCell>
              <TableCell>Solicitante</TableCell>
              <TableCell className={classes.price}>Solicitado Em</TableCell>
              <TableCell className={classes.stage}>Etapa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            { listBudgets.map((budget, index) => {
              const creationDate = format(parseISO(budget.createdAt), 'dd/MM/yyyy')
              return (
                <TableRow key={index} className={classes.tableRow} hover onClick={() => handleBudgetClick(budget)}>
                  <TableCell>{handlePatientName(budget.procedureRequest.medicalAppointment.patient)}</TableCell>
                  <TableCell>{budget.procedureRequest.serviceProvided.description}</TableCell>
                  <TableCell>{budget.provider.user.name}</TableCell>
                  <TableCell>{creationDate}</TableCell>
                  <TableCell>
                    <RenderChip 
                      classes={classes}
                      budgetStatus={budget.status}
                      procedureRequestStatus={budget.procedureRequest.status}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="footer">
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          count={count}
          qtyPages={qtPages}
        />
      </div>
      <ProcedureRequestModal 
        open={openModal}
        setOpen={setOpenModal}
        budget={selectedBudget}
        reload={loadBudgets}
      />
    </>
  )
}
