import React, { useState, useEffect } from "react";
import apiGraphql from "../../../../../services/api";

import Loading from "../../../../../common/circular-progress";

import Input from "../../../../../common/input";
import Button from "../../../../../common/button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";

import empty from "../../../../../common/validators/empty";

import currencyMask from "../../../../../common/mask/currency";
import { priceToNumber } from '../../../../../utils/price/priceToNumber'

import SnackBar from "../../../../../common/snackbar";

export default (props) => {
  const { listSpecialities, email, getListProfessionals, handleClose } = props;

  let specialities = {}
  listSpecialities.forEach(item => {
    if (!specialities[item.specialities.id]) {
      specialities[item.specialities.id] = {
        ...item.specialities,
        units: [{ ...item, specialities: undefined }]
      }
    } else {
      specialities[item.specialities.id].units.push({ ...item, specialities: undefined })
    }
  })

  specialities = Object.keys(specialities).map(i => specialities[i])

  const [loading, setLoading] = useState(false);

  const [speciality1, setSpeciality1] = useState({
    specialities: { id: "", name: "", units: [] },
  });
  const [specialityError1, setSpecialityError1] = useState({
    error: false,
    type: "",
  });
  const [speciality2, setSpeciality2] = useState({
    specialities: { id: "", name: "", units: [] },
  });

  const [price1, setPrice1] = useState(currencyMask(0));
  const [price2, setPrice2] = useState(currencyMask(0));
  const [tax, setTax] = useState(0);
  const [showSpeciality2, setShowSpeciality2] = useState(false);

  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  useEffect(() => {
    setLoading(true);
    apiGraphql({
      data: {
        query: `
          query{
            getTaxByType(type:APPOINTMENT_FEE_PROVIDER)
          }
        `,
      }
    })
      .then((res) => {
        setLoading(false);
        setTax(res.data.data.getTaxByType);
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          message: "Houve um erro ao tentar buscar o valor da taxa ",
        }));
        console.log(err);
      });
  }, []);

  const deleteSpeciality2 = () => {
    setSpeciality2({ specialities: { id: "", name: "", units: [] } });
    setPrice2(0);
    setShowSpeciality2(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (empty(speciality1.name).error) {
      return setSpecialityError1(empty(speciality1.name));
    }
    const inputInviteDoctor = {
      email,
      employeeHasUnits: [],
    };

    inputInviteDoctor.employeeHasUnits = speciality1.units.map(item => ({
      unitHasSpecialitiesId: item.id,
      specialityId: speciality1.id,
      appointmentValue: priceToNumber(price1),
      unitId: item.unitId,
      active: true,
    }))

    if (speciality2.name) {
      speciality2.units.forEach(item => inputInviteDoctor.employeeHasUnits.push({
        unitHasSpecialitiesId: item.id,
        specialityId: speciality2.id,
        appointmentValue: priceToNumber(price2),
        unitId: item.unitId,
        active: true,
      }))
    }

    apiGraphql({
      data: {
        query: `
          mutation createEmployeeInvite($input:CreateEmployeeInviteInput!){
            createEmployeeInvite(input:$input)
          }
        `,
        variables: {
          input: inputInviteDoctor,
        },
      }
    })
      .then((res) => {
        if (res.data.errors) {
          let message
          res.data.errors.forEach(err => {
            const status = err?.extensions?.exception?.status || 500
            console.log(err.extensions)
            const errors = {
              '400': 'Este médico não tem as especialidades informadas',
              '404': 'Desculpe, o email informado não se encontra cadastrado na plataforma',
              '409': 'Este médico já está vinculado. Se este médico ainda não aceitou o convite, enviamos novamente para ele o email de convite',
              '500': 'Desculpe, houve um erro ao processar os dados. Por favor, tente novamente mais tarde'
            }

            message = errors[status]
          })
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: message,
          });
          setLoading(false);
        } else {
          getListProfessionals();
          setLoading(false);
          handleClose();
        }
      })
      .catch((err) => {
        setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message:
            "Desculpe, houve algum erro ao tentar se conectar com o servidor.",
        });
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {loading && (
        <Loading size={80} position="absolut" height="40vh" width="30vw" />
      )}
      {!loading && (
        <div className={props.classesModal.body}>
          <Autocomplete
            id="combo-box-specialty1-admin"
            options={specialities}
            getOptionLabel={(specialty) => specialty.name || ''}
            getOptionSelected={(specialty) => specialty.name}
            value={speciality1}
            onChange={(e, newValue) => {
              if (newValue && newValue.id !== speciality2.id) {
                setSpeciality1(newValue);
              } else {
                setSpeciality1({
                  specialities: { id: "", name: "", units: [] },
                });
                if (newValue) {
                  setStateSnackBar({
                    ...stateSnackBar,
                    open: true,
                    message: "Essa especialidade já foi escolhida!",
                  });
                }
              }
            }}
            openOnFocus
            renderInput={(params) => (
              <Input
                name="input-specialty1-register-professionals-admin"
                datatest="input-specialty1-register-professionals-admin"
                width="90%"
                InputProps={params.InputProps}
                inputProps={params.inputProps}
                label="Especialidade"
                placeholder="Especialidade"
                type="text"
                onBlur={() =>
                  setSpecialityError1(empty(speciality1.name))
                }
                error={specialityError1.typeError}
              />
            )}
          />

          <Input
            name="input-price1-register-professionals-admin"
            datatest="input-price1-register-professionals-admin"
            width="90%"
            label="Valor em reais (R$) da Consulta"
            placeholder="Valor da consulta"
            type="text"
            value={currencyMask(price1)}
            onChange={(e) => setPrice1(currencyMask(e.target.value))}
          />

          <span className={props.classesModal.span}>
            O valor cobrado na plataforma será de:{" "}
            <strong>
              R${" "}
              {
                (parseFloat(priceToNumber(price1)) * (tax / 100)).toFixed(2).replace(".", ",")
              }
            </strong>
          </span>
          {!showSpeciality2 && (
            <Button
              name="button-add-healthInsurance"
              datatest="button-add-healthInsurance"
              width="257px"
              margin="32px 0 0 0"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              fontWeight="600"
              border="1px solid #CDCED9"
              onClick={() => setShowSpeciality2(true)}
            >
              Adicionar Especialidade
              <AddIcon></AddIcon>
            </Button>
          )}

          {showSpeciality2 && (
            <>
              <Autocomplete
                id="combo-box-specialty2-admin"
                options={specialities}
                getOptionLabel={(specialty) => specialty.name || ''}
                getOptionSelected={(specialty) => specialty.name}
                value={speciality2}
                onChange={(e, newValue) => {
                  if (newValue && newValue.id !== speciality1.id) {
                    setSpeciality2(newValue);
                  } else {
                    setSpeciality2({
                      specialities: { id: "", name: "", units: [] },
                    });
                    if (newValue) {
                      setStateSnackBar({
                        ...stateSnackBar,
                        open: true,
                        message: "Essa especialidade já foi escolhida!",
                      });
                    }
                  }
                }}
                openOnFocus
                renderInput={(params) => (
                  <Input
                    name="input-specialty2-register-professionals-admin"
                    datatest="input-specialty2-register-professionals-admin"
                    width="90%"
                    InputProps={params.InputProps}
                    inputProps={params.inputProps}
                    label="Especialidade 2"
                    placeholder="Especialidade"
                    type="text"
                  />
                )}
              />
              <Input
                name="input-price2-register-professionals-admin"
                datatest="input-price2-register-professionals-admin"
                width="90%"
                label="Valor em reais (R$) da Consulta"
                placeholder="Valor da consulta"
                type="text"
                value={currencyMask(price2)}
                onChange={(e) => setPrice2(currencyMask(e.target.value))}
              />

              <span className={props.classesModal.span}>
                O valor cobrado na plataforma será de:{" "}
                <strong>
                  R${" "}
                  {
                   (parseFloat(priceToNumber(price2)) *  (tax / 100)).toFixed(2).replace(".", ",")
                  }
                </strong>
              </span>

              {showSpeciality2 && (
                <Button
                  name="button-add-healthInsurance"
                  datatest="button-add-healthInsurance"
                  width="257px"
                  margin="32px 0 0 0"
                  backgroundColor="#FFFFFF"
                  color="#6B6C7E"
                  fontWeight="600"
                  border="1px solid #CDCED9"
                  onClick={() => deleteSpeciality2()}
                >
                  Excluir Especialidade 2
                </Button>
              )}
            </>
          )}
        </div>
      )}
      <div className={props.classesModal.footer}>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #CDCED9"
          color="#6B6C7E"
          width="22vw"
          maxWidth="120px"
          margin="0 20px 0 0 "
          onClick={() => props.previousStep()}
        >
          Voltar
        </Button>
        <Button
          disabled={loading}
          backgroundColor="#FFF"
          border="1px solid #DF9000"
          color="#DF9000"
          width="22vw"
          maxWidth="120px"
          margin="0 10% 0 0"
          onClick={() => handleSubmit()}
        >
          Confirmar
        </Button>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
};
  
