import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";

import Topic from "../../../../common/topic";
import Select from "../../../../common/select";

import empty from "../../../../common/validators/empty";

import { formTopic } from "./style";

export default memo((props) => {
  const formTopicClasses = formTopic();

  const {
    careModality,
    setCareModality,
    careModalityError,
    setCareModalityError,
  } = props;

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol={formTopicClasses.root}
          title="Modalidade de Atendimento"
          subtitle="Informe se prestara atendimento de consulta online e/ou presencial."
        />
      </div>
      <Grid style={{ marginBottom: "50px" }}>
        <Grid item xs={9} md={2}>
          <Select
            name="input-careModality-supportType"
            datatest="input-careModality-supportType"
            width="100%%"
            label="Modalidade"
            value={careModality}
            onChange={(e) => setCareModality(e.target.value)}
            onBlur={() =>
              empty(careModality).error
                ? setCareModalityError({
                    error: true,
                    typeError: "Por favor, escolha um tipo de modalidade",
                  })
                : setCareModalityError({ error: false, typeError: "" })
            }
            error={careModalityError.typeError}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
