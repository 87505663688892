import axios from 'axios';

const restApi = axios.create({
    method:'post',
    baseURL: `${process.env.REACT_APP_API_URL}`
})

restApi.interceptors.request.use( (config) => {
    if(config.method === "post") {
      const token = window.localStorage.getItem("Token-Vida-Connect");
      if(token){
          config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  }, (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default restApi;